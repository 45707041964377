.btn-inverted:hover {
    margin-right: -20px !important;
    transition: all .4s ease-in;
    /* transition: all .3s ease-out;*/
}


.renderCloseArrow{
    position: absolute;
    right: 0;
    text-align: right;
    top:0;
    z-index:9;
    margin-right:31%
}

 .btn-inverted{
    background: #232931;
    /* margin-right: -100px; */
    width: 50px;
    /* position: absolute; */
    font-size: 14px;
    color: #fff;
    border-radius: 5px;
    height: 35px;
    border: none;
    display:block
 }

.lessonShowContent > div{
    width:100%;
    position: relative;
}

.react-pdf__Document .react-pdf__message--loading{
    text-align:center;
    margin-top:30%
}

.test-disabled{
    background-color: grey;
    border: none;
    color: white;
}

.cross2{
    height: 31px;
    width: 31px;
    cursor: pointer;
    margin-right: 5px;
    top: -34px;
    position: relative;
}
.duration_text{
   font-size:12px
}




.pdf-arrows {
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    display: flex;
    background-color: white;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    padding: 5px 10px;
    /* position: absolute; */
    text-align: center;
    /* width: 68%; */
    bottom: 0px;
    /* opacity: 0.1; */
    border: 1px solid black;
    margin: 0px 10px;
    transition: 0.2s all ease-in-out;

}

@media(max-width:768px){
    .pdf-arrows{
        position:static !important;
        width:100% !important
    }
}

.pdf-arrows:hover{
    opacity:1;
    transition: 0.2s all ease-in-out;
}

.pdf-arrows i{
    font-size:40px
}

.pdf-arrows i:first-child{
    padding-right:10px
}
.pdf-arrows i:nth-child(2){
    padding-left:10px
}



.lessonTopics-topics-container{
    height: 100%;
    overflow-y: scroll;
    border-bottom: 1px solid black;
    border-bottom-style: dotted;
}


.inside_collapse{
    padding:2% 4%;
    flex-direction: column !important;
    align-items: flex-start !important;
    cursor: pointer;
}


.productMainFirstText{
    cursor:pointer
}


@media only screen and (min-width: 320px)   {

    .btn-inverted{
        display:none
    }
    .react-pdf__Document .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
    }
    .logoHeight{
        margin-right: 0px;
    }

    .react-pdf__Document{
        overflow-y: hidden;
        height: auto;
    }

    .CollapseInside_lesson {
        border-bottom: 1px solid #d2d2d2;
    }
    .arrowLeft{
        position: absolute;
        font-size: 70px;
        left: 2%;
        top: 50%;
         color: white; 
         width: 20px;
         height: 20px;
        z-index:999
        }
    .arrowRight{
        position: absolute;
        font-size: 70px;
        right: 2%;
        top: 50%;
        width: 20px;
        height: 20px;
         color: white;
         z-index:999
    }
    .ppt-iframe {
        height: 40vh !important;
        width: 80vw !important;
    }
    iframe{
height: 500px !important;
width: 100% !important;
    }

    .video-lecture{
height: 40vh !important;
    }
    .renderImage{
        display: block;
        margin:0 auto;
        height: 40vh;
        width:auto
    }
    /* .react-pdf__Page__canvas{
        height: 40vh !important;
        width: 80vw !important;
    } */

    #lesson-header .header{
        display:none;
    }


    .new-header-logo{
        width:60px
    }

    .lesson-details-container{
        display: none;
    }

    #lessonTopics{
        width: 100%;
        padding:10px;
        min-height:50vh

    }
    .lessonShowContent{
        position: relative;
        background-color: white;
        height: fit-content;
        display:flex;
        align-items: center;
        width:100%;
        margin-top:0px;
        justify-content:center;
    } 

    .lesson_type_icon{
        height: 18px;
        width: 18px;
        margin-right:5px;
    }

    #lessonTopics .cross2{
        display:none;
    }

}
.courseContentIcon{
    margin-right: 10px;
}


@media only screen and (min-width: 900px)   {

  .btn-inverted{
    display:block
  }

    .react-pdf__Document .react-pdf__Page__canvas{
        width:90% !important;
        height:auto !important;
    }
    
    #lessonTopics .cross2{
        display:unset;
    }

    .react-pdf__Document{
        /* overflow-y: scroll; */
        height: 100%;
    }

    .lessonShowContent{
        position: relative;
        background-color: white;
        display: unset;
        align-items: unset;
        height: 40vh;
        width:70%;
        margin-top:0px;
        justify-content:center;
    } 


    .quiz-modal-container .start-quiz-button{
        margin-left:auto
       }
    
    .arrowLeft{
        position: absolute;
        font-size: 70px;
        left: 2%;
        top: 50%;
         color: white; 
         width: 40px;
         height: 40px;
        z-index:999
        }
    .arrowRight{
        position: absolute;
        font-size: 70px;
        right: 2%;
        top: 50%;
        width: 40px;
        height: 40px;
         color: white;
         z-index:999
    }
    .ppt-iframe {
        height: 500px !important;
        width: 100% !important;
    }
    .video-lecture{
        height: 500px !important;
            }
    .renderImage{
        display: block;
        margin:0 auto;
        height: 500px;
        width:auto
    }
    /* .react-pdf__Page__canvas{
        height: 500px !important;
        width: 720px !important;
    } */

    #lesson-header .header{
        display:block
    }

    #lessonTopics{
        padding-left:0px
    }

    .lesson-details-container{
        display: block;
        margin-top:5px
    }

    #lessonTopics{
        width: 30%;
        min-height: fit-content;
        height:100%
    }

   

    .lesson_type_icon{
        height: 18px;
        width: 18px;
        margin-right:5px;
    }
    
    .slide-next{
        background-image:url(../../Assets/images/next.png);
        right: 0;
        height:100px ;
        width: 20px;
        
    }
    
    .slide-prev{
        background-image: url(../../Assets/images/previous.png);
        left:0;
        height:100px ;
        width: 20px;
    
    }

        
}
 
@media only screen and (min-width: 1100px)   {
    /* .react-pdf__Page__canvas{
        height: 500px !important;
        width: 720px !important;
    } */
 
    .lesson_type_icon{
        height: 18px;
        width: 18px;
        margin-right:5px;
    }
 
  
}




@media only screen and (min-width: 900px)  and (min-height:525px){  
    /* .react-pdf__Page__canvas{
        height: 500px !important;
        width: 720px !important;
    } 
   */
    .lesson_type_icon{
        height: 18px;
        width: 18px;
        margin-right:5px;
    }

.lessonMain{

    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 0px;
}

.lessonContent{

    width: 70%;
    display: flex;
    flex-direction: column;
 
}




.lessonTopics .lessonTopicsHeading{



    color:#4A4A4A;
    font-size:18px;
    font-weight:bold;
    font-family: Ubuntu-r;
    letter-spacing: 1;
}

.lessonTopicsHeadingDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    
    padding: 6% 5% 6% 5%;
    justify-content: space-between;
}

.CourseIntroduction{

    
    color:#4A4A4A;
    font-size:14px;
    font-weight:bold;
    font-family: Ubuntu-r;
    letter-spacing: 1;

}

.CourseLectures{

    color:#4A4A4A;
    font-size:12px;
    font-weight:500;
    font-family: Ubuntu-r;
    letter-spacing: 1;

}
.CollapseInside_lesson{
    
    border-bottom: 1px solid #d2d2d2;
}


.react-pdf__Document{
    background-color: white !important;
}





.CollapseBtnCourse{
margin: 0% !important;
}

.overView{
    margin-left: 5%;
    margin-right: 5%;
    text-align: justify;
    height: 50px;
    padding-top: 1%;
    width: fit-content;

}

.overViewText{

    color:#4A4A4A;
    font-size:16px;
    font-weight:500;
    font-family: Ubuntu-r;
    letter-spacing: 1;
}

.aboutCourse{

    text-align: justify;
    width:100%
  
}

.aboutCourseHeading{

    color:#4A4A4A;
    font-size:25px;
    font-weight:500;
    font-family: Ubuntu-b;
    letter-spacing: 1;
    padding-top: 25px;
}
.aboutCourseParagraph{
    text-align: justify;
}


.Features{
    width: 88%;
    display: flex;
    flex-direction: row;
    margin:3% 0% 3% 0%;

    color:#4A4A4A;
    font-size:16px;
    font-weight:500;
    font-family: Ubuntu-r;
    letter-spacing: 1;
 
}
.FeaturesSpan{
    color:#4A4A4A;
    font-size:16px;
    font-weight:500;
    font-family: Ubuntu-b;
    letter-spacing: 1;
}
.Featurestext{
    width: 25%;
}
.FeaturesAvailable{
    width: 30%;
}
.FeaturesCertifcate{
    width: 30%; 
}

.description{
    width: 88%;
    display: flex;
    flex-direction: row;
    margin:3% 0% 3% 0%;

    color:#4A4A4A;
    font-size:16px;
    font-weight:500;
    font-family: Ubuntu-r;
    letter-spacing: 1;
    text-align: justify;
 
}

.descriptiontext{
    width: 25%;
}
.descriptionPara{
    width: 75%;
}

.testSeries{
    width: 88%;
    display: flex;
    flex-direction: row;
    margin:3% 0% 3% 0%;

    color:#4A4A4A;
    font-size:16px;
    font-weight:500;
    font-family: Ubuntu-r;
    letter-spacing: 1;
    text-align: justify;
 
}

.testSeriestext{
    width: 75%;
    color:#4A4A4A;
    font-size:18px;
    font-weight:500;
    font-family: Ubuntu-b;
    letter-spacing: 1;
    display: flex;
    justify-content: space-between;
}
.testSeriesTextBtn{
    width: 75%;
}
.ButtonTest{
    margin-top: 2%;
}

.instructor{

    width: 88%;
    /* display: flex; */
    flex-direction: row;
    margin:3% 0% 3% 0%;

    color:#4A4A4A;
    font-size:16px;
    font-weight:500;
    font-family: Ubuntu-r;
    cursor:pointer;
    letter-spacing: 1;
    /* text-align: justify; */
}
.instructorName{

    width: 25%;
}
.instructorInfo{

    width: 75%;
}

.instructorInfo_pic_sub{
    width: 100%;
    display: flex;
    flex-direction: row;
    
}
.instructorInfo_sub{
    margin-left: 2%;
}

.instructorInfo{ 
    color:#4A4A4A;
    font-size:16px;
    font-weight:500;
    font-family: Ubuntu-b;
    letter-spacing: 1;
    text-align: justify;

}

.instructorSub{

    color:#4A4A4A;
    font-size:14px;
    font-weight:500;
    font-family: Ubuntu-r;
    letter-spacing: 1;
    text-align: justify;
}

.instructorSubDescription{
    color:#4A4A4A;
    font-size:16px;
    font-weight:500;
    font-family: Ubuntu-r;
    letter-spacing: 1;
    text-align: justify;

}

.descriptionTextHeading{
    color:#4A4A4A;
    font-size:16px;
    font-weight:500;
    font-family: Ubuntu-b;
    letter-spacing: 1;
    text-align: justify;

}

.descriptionTextHeadingDivMargin{
    margin-top: 5%;
}




}


@media only screen and (min-width: 1300px)  and (min-height:668px){
    .logoHeight{
        margin-right: 35px;
    } 
    /* .react-pdf__Page__canvas{
        height: 500px !important;
        width: 720px !important;
    }    */
 
    .lesson_type_icon{
        height: 18px;
        width: 18px;
        margin-right:5px;

    }  

  }
.renderopenArrow{
    position: absolute;
    right: 0;
    top: -75%;
    text-align: right;
    
}
#lessonContent .react-pdf__Page__canvas{
    margin:0 auto;
}

/* Large screens ----------- */
.lessonCloseBtn{
    background:#232931;
    /* margin-right: -100px; */
    width: 50px;
    /* position: absolute; */
    font-size: 14px;
    color: #fff;
    border-radius:  5px ;
    height: 35px;
    border: none;
    top:15%;
    right:20px;
    position:fixed
}


.lessonCloseBtn:hover {
   margin-right: 10px;
    transition: all .4s ease-in;
    /* transition: all .3s ease-out; */
  }

 








/* .arrowContainer{
    background-color: black;
} */



#lessonTopics{
    
    border-left: 1px solid #d2d2d2;
}
.subjectName{
    font-family: Ubuntu-B !important;
    font-size: 22px !important;
    padding-left: 0;
}


.lesson-details-container{
    padding-left:90px
}



