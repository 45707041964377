@media only screen and (min-width: 1100px){

    .answer_output{
        width: 250px;
        height: 250px;
        text-align: center;
        margin: 20px auto;
        background-color: #4A4A4A;
        padding-top: 20px;
        color: #fff;
        box-shadow: 10px 10px 5px #888888;
    }
    .quiz_answer_Result_font{
        font-size: 40px;
        font-family: Ubuntu-b;
        margin-top: 40px;
    }

    .quiz_answer_font{
        font-size: 36px;
        font-family: Ubuntu-b;
        margin-top: 40px;
    }

.marginLeftFeedMainDiv{
    margin: 0 0 0 11%;
}
.feedbackFlex{
    display: flex;
}
.feedEmojiDiv{
    width: 25%;
}
.feedEmojiFont{
    display: flex;
    justify-content: space-evenly;
    font-size: 30px;
}
.feedbackHeadingW{
    width: 30%;
    font-size: 18px;
    font-family: Ubuntu-r;
}
.feedbackSubmitBtnDiv{
    display: flex;
    justify-content: center;
    margin: 2% 0;
}
.feedBtnMainDiv{
    display: flex;
    justify-content: space-between;
    width: 30%
}
}
@media only screen and (min-width: 1300px) and (min-height:525px){
    .marginLeftFeedMainDiv{
    margin: 0 0 0 10%;
}
.feedbackFlex{
    display: flex;
}
.feedEmojiDiv{
    width: 25%;
}
.feedEmojiFont{
    display: flex;
    justify-content: space-evenly;
    font-size: 30px;
}
.feedbackHeadingW{
    width: 30%;
    font-size: 18px;
    font-family: Ubuntu-r;
}
.feedbackSubmitBtnDiv{
    display: flex;
    justify-content: center;
    margin: 2% 0;
}
.feedBtnMainDiv{
    display: flex;
    justify-content: space-between;
    width: 30%
}
}
@media only screen and (min-width: 1300px) and (min-height:668px){
    .marginLeftFeedMainDiv{
    margin: 0 0 0 10%;
}
.feedbackFlex{
    display: flex;
}
.feedEmojiDiv{
    width: 25%;
}
.feedEmojiFont{
    display: flex;
    justify-content: space-evenly;
    font-size: 30px;
}
.feedbackHeadingW{
    width: 30%;
    font-size: 18px;
    font-family: Ubuntu-r;
}
.feedbackSubmitBtnDiv{
    display: flex;
    justify-content: center;
    margin: 2% 0;
}
.feedBtnMainDiv{
    display: flex;
    justify-content: space-between;
    width: 30%
}
}
@media only screen and (min-width: 1800px){
    .marginLeftFeedMainDiv{
    margin: 0 0 0 10%;
}
.feedbackFlex{
    display: flex;
}
.feedEmojiDiv{
    width: 25%;
}
.feedEmojiFont{
    display: flex;
    justify-content: space-evenly;
    font-size: 30px;
}
.feedbackHeadingW{
    width:  30%;
    font-size: 18px;
    font-family: Ubuntu-r;
}
.feedbackSubmitBtnDiv{
    display: flex;
    justify-content: center;
    margin: 2% 0;
}
.feedBtnMainDiv{
    display: flex;
    justify-content: space-between;
    width: 30%
}
}


.test-questions-with-answers-container{
    background-color:#f9f9f9;
}


.test-questions-with-answers-container .bg-green{
    background-color: #6cf16c;
    border-radius: 5px;
}

.test-questions-with-answers-container .bg-red{
    background-color: #ff1212bd;
    border-radius: 5px;
}