
    .top-header{
        height:60px;
    }
    .Login-alert-form .swal-text{
        font-family: "Ubuntu-M";
        color:black;
        font-size:18px
    }

  .header-options{
    text-decoration: none;
    font-size: 16px;
    color: #585757;
    font-weight: 300;
    z-index: 999;
    border: 1px solid #999999;
    position: relative;
    border-radius: 5px;
  }

  .submenu-subject-list-container {
    max-height: 400px;
    margin-left: 0 !important;
    width: 80% !important;
    overflow-y: scroll;
}

    .submenu-subject-list-container a {
    color:black
    }




@media(min-width:992px) and (max-width:1300px){
    .headerToggleMenu{
        border: none;
        border-radius: 0;
        border-bottom-left-radius: 2px;
        border-top: 1px solid #e8e9eb;
        box-shadow: 0 4px 16px rgba(20,23,28,.25);
        color: #505763;
        font-size: 16px;
        margin: 14px;
        height:fit-content;
        padding-right: 45px;
        z-index: 99999;
        background-color: white;
        min-width: 250px;
        right: 0%;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        font-weight: 400;
        margin-top: 15px;
    }

    #homepage .headerToggleMenu {
        right: 35px !important;
    }

    .headerToggleMenu p{
        padding-left:10px
    }

    .headerToggleMenu hr{
        display:none
    }

    .headerToggleMenu:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
      }
      .headerToggleMenu:before {
        right: 19px;
        border: 11px solid transparent;
        border-bottom-color: #fff;
      }
      .headerToggleMenu:after {
        left: 20px;
        border: 10px solid transparent;
        border-bottom-color: #fff;
      }
}

  @media only screen and (min-width: 1300px)  and (min-height:525px){
    .headerToggleMenu{
        border: none;
        border-radius: 0;
        border-bottom-left-radius: 2px;
        border-top: 1px solid #e8e9eb;
        box-shadow: 0 4px 16px rgba(20,23,28,.25);
        color: #505763;
        font-size: 16px;
        margin: 14px;
        min-height: 30vh;
        z-index: 99999;
        background-color: white;
        min-width: 250px;
        right: 0%;
        display: flex;
        flex-direction: column;
       
        padding-top: 20px;
        font-weight: 400;
        margin-top: 15px;
    }
    .headerToggleMenu:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
      }
      .headerToggleMenu:before {
        right: 19px;
        border: 11px solid transparent;
        border-bottom-color: #fff;
      }
      .headerToggleMenu:after {
        left: 20px;
        border: 10px solid transparent;
        border-bottom-color: #fff;
      }

      .avatar{
        width: 40px !important;
        height: 40px !important;
    border: 1px solid #d2d2d2;
    /* border-radius: 50%; */
    }
    .profile-menu-list{
        margin-left: 10%;
    }
    hr {
        display: block;
        height: 2px;
        border: 0;
        border-top: 1px solid grey !important;
        margin: 1em 0;
        padding: 0;
        margin: 20px;
    }


}

  .header-options:not(:last-child){
    margin-right:10px;
    padding: 10px 15px;
  }

  .header-options:last-child{
    width:40px;
    height:40px;
    background-color: transparent;
    transition: all 0.1s ease-in;
  }

  .header-options:last-child:hover{
    background-color: white;
    box-shadow: 0px 1px 6px 1px #8a7c7c;
    transition: all 0.1s ease-out;
  }

  .rowDefault{
    display: flex;
    flex-direction: row;
  }

  .colDefault{
    display: flex;
    flex-direction: column;
      }

  .header-options:hover{
      text-decoration: none;
      background-color: #dbaa34;
      color: white !important;
      border-color:transparent;
  }
  
  
  .bg-grey{
      background-color: #f9f9f9;
  }
  
  .bg-menu{
      background-image:url("../../Assets/images/headertopbg.png")
  }
  
  #homepage .login-column{
      /*clip-path: polygon(15% 0, 100% 0, 100% 100%, 19% 100%);*/
      background-image:url(../../Assets/images/login-background.png);
      background-repeat: no-repeat;
      background-position: right;
      background-size: 89% 100%;
  }
  
  .menu-header{
    height: 170px;
    margin-top: -45px;
  }


  #homepage .padding-left-80{
      padding-left:80px !important;
  }

  #homepage .padding-right-80{
      padding-right:80px !important;
  }

  #homepage .menu-header > .row{
    padding: 16px 0px;
  }
  
@media(max-width:576px){


    .header-options{
            font-size: 13px;
            color: #585757;
            font-weight: 300;
            border: 1px solid #999999;
            border-radius: 5px;
            padding: 7px 5px;
            margin-right: 10px;
    }
}
  
  /* @media(max-width:768px){
      .top-icons-container-home{
          display: flex;
          width:95%;
          justify-content: space-between;
          align-items:center;
      }
     .menu-header{
         margin-top:-40px;
     }
     .logo{
         width: 100px;
     }
  }
   */
  
  
  .menu-header > .row{
  /* padding-top: 20px; */
  }
  
  .tagline{
      font-size: 14.3px;
      font-style: italic;
      font-weight: 300;
      color: #5d5a5a;
  }
  
  .left-menu-options, .right-menu-options{
    display: flex;
    justify-content: space-around;
    align-self: center;
    /* padding-bottom: 25px; */
    text-align: center;
  }
  
  @media(max-width:1026px){
      .left-menu-options, .right-menu-options{
         /* padding-bottom: 50px; */
      }
      
  }
  
  .left-menu-options a, .right-menu-options a {
    font-weight: 300;
    color: #333333;
    font-size: 15px;
    font-family: Ubuntu-B;
    text-transform: uppercase;
  }
  
  @media(max-width:916px){
      
      .left-menu-options a, .right-menu-options a {
        margin: 0px 6px;
      }
  }
  
  .menu-header .dropdown-menu {
      min-width: 300px;
      left:-100px !important;
  }


  .menu-header  .dropdown-menu.columns-2 {
      min-width: 400px;
  }
  .menu-header   .dropdown-menu.columns-3 {
      min-width: 600px;
  }
  .menu-header .dropdown-menu li a {
      padding: 5px 15px;
      font-weight: 300;
  }
  .menu-header .multi-column-dropdown {
      list-style: none;
    margin: 0px;
    padding: 0px;
  }
  .menu-header .multi-column-dropdown li a {
      display: block;
      clear: both;
      line-height: 1.428571429;
      color: #333;
      white-space: normal;
  }
  .menu-header .multi-column-dropdown li a:hover {
      text-decoration: none;
      color: #262626;
      background-color: #999;
  }
   
  @media (max-width: 767px) {
      
    .menu-header .dropdown-menu.multi-column {
          min-width: 240px !important;
          overflow-x: hidden;
      }
  }
  
  /* .hamburger-menu{
  float:right
  } */
  
  
  
  
  /* The side navigation menu */
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    background-color: #f9f9f9;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;
 
    }
    
    /* The navigation menu links */
    .sidenav a {
      padding: 8px 8px 8px 32px;
      text-decoration: none;
      font-size: 25px;
      color: #818181;
      display: block;
      transition: 0.3s;
    }
    
    /* When you mouse over the navigation links, change their color */
    .sidenav a:hover {
        color: #000000;
        }
    
    /* Position and style the close button (top right corner) */
    .sidenav .closebtn {
      position: absolute;
      top: 0;
      right: 25px;
      font-size: 36px;
      margin-left: 50px;
    }
    
    /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
    #main {
      transition: margin-left .5s;
      padding: 20px;
    }
    
    /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
    @media screen and (max-height: 450px) {
      .sidenav {padding-top: 15px;}
      .sidenav a {font-size: 18px;}
    }
  
  
  
    .exams-dropdown{
        left:0px !important;
    }
  
  
    .column-count{
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
  }
  
  



.mobile-menu-button-toolbar{
    display:flex;
    flex-direction: column;
}

.mobile-menu-button-toolbar button{
    background-color: transparent;
    color: #656161;
    border-bottom: 1px solid #c7c5c5;
    padding: 10px 15px;
    width: 100%;
    font-weight: 300;
    box-shadow: none;
    color: black;
    font-family: Ubuntu-L;
    margin: 0px 2px 0px 0px;

}



@media only screen and (min-width: 1300px)  and (min-height:668px){
    .email-text-profile-menu{
        font-size: 12px;
    }
    .profile-menu-list{
        background-color: #fff;
        padding-left: 20px;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0px;
        transition: background-color 0.6s;
    }
    .profile-menu-list:hover{
background-color: #8080801f;
padding-left: 20px;
cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        transition: background-color 0.6s;
    }
    hr {
        display: block;
        height: 2px;
        border: 0;
        border-top: 1px solid grey !important;
        margin: 1em 0;
        padding: 0;
        margin: 20px;
    }
    .headerToggleMenu{
        border: none;
        border-radius: 0;
        border-bottom-left-radius: 2px;
        border-top: 1px solid #e8e9eb;
        box-shadow: 0 4px 16px rgba(20,23,28,.25);
        color: #505763;
        font-size: 16px;
        margin: 0;
        min-height: 30vh;
        z-index: 999999;
        background-color: white;
        min-width: 250px;
        right: 0%;
        display: flex;
        flex-direction: column;
       
        padding-top: 20px;
        font-weight: 400;
        margin-top: 15px;
    }
    .headerToggleMenu:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
      }
      .headerToggleMenu:before {
        right: 19px;
        border: 11px solid transparent;
        border-bottom-color: #fff;
      }
      .headerToggleMenu:after {
        left: 20px;
        border: 10px solid transparent;
        border-bottom-color: #fff;
      }
    .top-icons-container-home{
        display: flex;
        width:95.2%;
        justify-content: space-between;
        align-items:center;
    }
    .icon-div-home2{
        width: 250px;
    }
    .icon-div-home2{
        width: 200px;
    }
    .avatar{
        width: 40px;
    border: 1px solid #d2d2d2;
    border-radius: 50px;
    }
}


.exam-submenu-container{
    width: 100%;
    height: 90vh;
    position: absolute;
    background-color: #fafafafc;
    z-index: 9999;
    padding: 20px 10px;
    top: 78px;
    left:0;
    /* border-radius: 10px; */
    border: 0px solid #b9b9b9;
    /* margin: 0px 10%;*/
}
.exam-submenu{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 100px;
    height: 100%;
    overflow-y: scroll;
    align-items: flex-start;
    padding: 20px 7%;
    border-radius: 5px;
}


.exam-submenu ul{
    list-style: none;
}

.exam-submenu ul li{
    cursor:pointer;
}

.exam-submenu ul li i{
    padding:2px 8px
}


#homepage .menu-header .logo{
    width: 170px;
}

#homepage .exam-submenu-container{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #fafafafc;
    z-index: 9999;
    padding: 20px 30px;
    top: 0px;
    /* border-radius: 10px; */
    border: 0px solid #b9b9b9;
    /* margin: 0px 10%; */

}
.exams_menu_logo{
width:120px;
}
.cross_div{
    display: flex;
    justify-content: flex-end;
    width: 10%;
    padding-top:6px;
    flex-direction: row;
    align-items: flex-start;
}
.exam-submenu-container{
    animation-duration: 300ms !important;
    box-shadow: 1px 1px 1px 1px #d2d2d226;
}
.row_reverse{
    display: flex;
    flex-direction: row-reverse;
}
.cross{ 
       
    width: 40px;
    height: 40px;
    cursor: pointer
   
}
.exam_menu_logo{
    width: 1085px;
    display: flex;
    justify-content: center;
    margin-top: 9px;
}
@media(max-width:1200px){
    #homepage .menu-header .logo {
        width: 110px;
        padding-bottom: 0px; 
    }


    .menu-header{
            height: auto !important;
            margin-top: -10px;
            padding-top:0px !important;
    }

    
    .menu-header > .row{

        padding-top:0px !important;
    }

    .menu-header .left-menu-options, .menu-header .right-menu-options{
        align-self:flex-end; 
    }


    .exam-submenu-container{
        width: 100%;
        height: 86%;
        position: absolute;
        background-color: #fafafafc;
        z-index: 9999;
        padding: 20px 30px;
        top: 145px;
        /* border-radius: 10px; */
        border: 0px solid #b9b9b9;
        /* margin: 0px 10%;*/
    }


    .hamburger-menu{
        flex-direction: row;
    align-items: flex-end;
    }

}


@media(max-width:992px){
  nu-header{
        height: 165px;
    }
    #homepage .menu-header .logo {
        width: 100px;
        padding-top: 0px;
    }
}

@media(min-width:320px){
 .vsMobView-home{
     display: block;
 }
 .windowView-home{
     display: none;
 }
}
@media(min-width:600px){
    .vsMobView-home{
   

        display: block;
    }
    .windowView-home{
        display: none;
    }

  
   
   }


   @media(min-width:900px){

    .windowView-home{
        display: block;
    }

    .vsMobView-home{
   

        display: none;
    }
   }


 