.dashCardW1 {
  width: 275px;
}
.view_all:hover {
  color: #dbaa34;
}

.dashboard-rating-container {
  display: flex;
}

.dashboard-rating-container p {
  position: unset;
  left: unset;
  right: unset;
  bottom: unset;
}

.dashboard-rating-container .star-ratings {
  position: unset;
  left: unset;
  right: unset;
  bottom: unset;
}

.arrow-image {
  width: 10px;
  height: auto;
}

.slider-divider {
  margin-top: 80px;
  height: 10px;
}

.exams-page-subject-item.cardMainDiv {
  height: 43vh !important;
}
.exams-page-subject-item .licCardBodyH {
  height: 13vh;
}

@media(max-width:1400px) and (max-height:1100px){
    .exams-page-subject-item.cardMainDiv {
        height: 36vh !important;
    }
}

@media(max-width:1200px){
    .exams-page-subject-item.cardMainDiv {
        height: 43vh !important;
      }
      .exams-page-subject-item .licCardBodyH {
        height: 14vh;
      }  
}

@media(max-width:770px){
    .exams-page-subject-item.cardMainDiv {
        height: 35vh !important;
    }
}
@media screen and (min-width: 700px) {
  .windowView {
    display: none;
  }

  .mobView {
    display: block;
  }
  .dashboard-main-body {
    margin-top: 65px;
    min-height: 100vh;
  }

  .dashboard-banner-text-container {
    width: 50%;
    position: absolute;
    height: 100%;
    z-index: 15;
    padding: 3%;
    color: white;
    padding-left: 1em;
    line-height: 1.3;
  }

  .dashboard-banner-image {
    width: 100%;
    height: 250px !important;
    z-index: 10;
    /* margin-left: -300px; */
  }

  .courseHeadMainDiv {
    margin: 2% 5%;
  }

  .courseHeadMainDiv .vsCollapseMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    width: 100%;
  }
  .btn-primary:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #dbaa34 !important;
  }
  .courseHeadMainDiv .solutionCollapseDiv {
    margin-bottom: unset;
    width: 100%;
    height: 60px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    background-color: #dbaa34 !important;
    font-size: 18px;
    color: white !important;
    font-weight: 500;
    border: none !important;
    height: 10vh;
    text-transform: initial;
    margin: 5px;
    border: none !important;
    box-shadow: none !important;
  }

  .rotateIcon {
    display: flex !important;
    align-items: center !important;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .rotateIcon2 {
    display: flex !important;
    align-items: center !important;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
  }
  .dashCardW {
    width: 250px;
    height: 42vh;
    border-radius: 0;
  }
  .dashCardTitleFont {
    margin-bottom: 0.1rem;
    font-size: 16px;
  }
  .dashTextFont {
    font-size: 13px;
  }
  .licCardTopM {
    margin: 3vw 0 0;
  }
  .licCardFlex-end {
    display: flex;
    justify-content: flex-end;
  }
  .displayCenter {
    display: flex;
    justify-content: center;
  }
  .slick-slide {
    display: flex !important;
    justify-content: center;
    width: 310px;
  }
  .MarginLeft {
    margin: 0 0 0 10%;
  }
  .MarginRight {
    margin: 0 8% 0 0%;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0 !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 33px !important;
    line-height: 0 !important;
    opacity: 3 !important;
    position: absolute;
    color: rgb(150, 149, 149) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 20px;
  }
  .slick-prev,
  .slick-next {
    transform: translate(0, -100%) !important;
    z-index: 1;
  }

  .slick-track {
    width: 100%;
  }
  .toggleDivColor {
    background-color: transparent;
    padding: 2% 2% 1.2%;
  }
  .fullprice {
    text-decoration: line-through;
  }
  .reviewFont {
  }
  .priceFont {
  }
  .image-overlay {
    position: absolute;
    left: 28px;
    opacity: 0.7;
    width: 53%;
    height: 304px;
    /* top: 123px; */
    display: block;
    z-index: 2;
    background-color: #0093d9;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }
  .bannerTextPosition {
    position: absolute;
    z-index: 3;
    left: 8%;
    display: flex;
    align-items: center;
    width: 90%;
    height: 40%;
    justify-content: space-between;
  }
  .headDiv {
    color: white;
    width: 38%;
    height: 26vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .headTextW {
    font-family: "Ubuntu-L";
    font-size: 30px;
    color: rgba(255, 255, 255, 255);
    width: 80%;
  }
  .bannerParaFont {
    font-size: 16px;
  }
  .contactBtnDiv {
    margin-right: -46px;
    transform: rotate(90deg);
  }
  .contactBtn {
    background-color: #0093d9 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    padding: 5px;
    display: flex;
    width: 121px;
    height: 30px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
}
@media screen and (min-width: 900px) {
  .windowView {
    display: flex;
  }

  .mobView {
    display: none;
  }

  .dashboard-main-body {
    margin-top: 0px;
  }

  .courseHeadMainDiv .vsCollapseMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    width: 100%;
  }
  .btn-primary:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #dbaa34 !important;
  }
  .courseHeadMainDiv .solutionCollapseDiv {
    margin-bottom: unset;
    width: 100%;
    height: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    background-color: #dbaa34 !important;
    font-size: 18px;
    color: white !important;
    font-weight: 500;
    border: none !important;
    height: 10vh;
    text-transform: initial;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
  }
  /* .btn-primary {
        background-color: #dbaa34 !important;
        color: #fff;
    } */
  .rotateIcon {
    display: flex !important;
    align-items: center !important;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .rotateIcon2 {
    display: flex !important;
    align-items: center !important;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
  }
  .dashCardW {
    width: 19vw;
    height: 42vh;
    border-radius: 0;
  }
  .dashCardTitleFont {
    margin-bottom: 0.1rem;
    font-size: 16px;
  }
  .dashTextFont {
    font-size: 13px;
  }
  .licCardTopM {
    margin: 3vw 0 0;
  }
  .licCardFlex-end {
    display: flex;
    justify-content: flex-end;
  }
  .displayCenter {
    display: flex;
    justify-content: center;
  }
  .slick-slide {
    display: flex !important;
    justify-content: center;
    width: 310px;
  }
  .MarginLeft {
    margin: 0 0 0 8%;
  }
  .MarginRight {
    margin: 0 8% 0 0%;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0 !important;
    right: 0;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 33px !important;
    line-height: 0 !important;
    opacity: 3 !important;
    position: absolute;
    color: rgb(150, 149, 149) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev,
  .slick-next {
    right: 12px;
    transform: translate(0, -100%) !important;
    z-index: 1;
  }

  .toggleDivColor {
    background-color: transparent;
    padding: 2% 2% 1.2%;
  }
  .fullprice {
    text-decoration: line-through;
  }
  .reviewFont {
  }
  .priceFont {
  }
  .image-overlay {
    position: absolute;
    left: 28px;
    opacity: 0.7;
    width: 53%;
    height: 304px;
    /* top: 123px; */
    display: block;
    z-index: 2;
    background-color: #0093d9;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }
  .bannerTextPosition {
    position: absolute;
    z-index: 3;
    left: 8%;
    display: flex;
    align-items: center;
    width: 90%;
    height: 40%;
    justify-content: space-between;
  }
  .headDiv {
    color: white;
    width: 38%;
    height: 26vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .headTextW {
    font-family: "Ubuntu-L";
    font-size: 30px;
    color: rgba(255, 255, 255, 255);
    width: 80%;
  }
  .bannerParaFont {
    font-size: 16px;
  }
  .contactBtnDiv {
    margin-right: -46px;
    transform: rotate(90deg);
  }
  .contactBtn {
    background-color: #0093d9 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    padding: 5px;
    display: flex;
    width: 121px;
    height: 30px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
}
@media only screen and (min-width: 1100px) {
  .reviewFont {
    position: absolute;
    bottom: 65px;
  }

  .dashboard-banner-image {
    width: 100%;
    height: auto !important;
    z-index: 10;
  }

  .star-ratings {
    bottom: 75px;
    left: 30px;
  }

  .dashboard-banner-text-container {
    width: 50%;
    position: absolute;
    height: 100%;
    z-index: 15;
    padding: 3% 5%;
    color: white;
    padding-left: 9em;
    line-height: 1.4;
  }
  .oldPriceFont {
    position: absolute;
    bottom: 5px;
    right: 80px;
  }
  .newPriceFont {
    position: absolute;
    bottom: 7px;
    right: 10x;
  }
  .windowView {
    display: flex;
  }
  .mobView {
    display: none;
  }
  .CardMainTitleDiv {
    height: 14vh;
  }
  .pageHeadFont {
    font-family: "Ubuntu-L";
    font-size: 14px;
    padding: 5px 0 5px 125px;
  }
  .bannerImgW {
    width: 1225px;
    position: relative;
    height: 302px;
  }
  .insuranceHeadFont {
    font-family: "Ubuntu-B";
    font-size: 30px;
  }
  .courseHeadMainDiv {
    margin: 2% 7%;
  }
  .dashCollapseHeading {
    margin-bottom: 0px;
    font-family: "Ubuntu-R";
    font-size: 25px;
  }
  .courseHeadMainDiv .vsCollapseMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    width: 100%;
  }
  .btn-primary:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #dbaa34 !important;
  }
  .courseHeadMainDiv .solutionCollapseDiv {
    margin-bottom: unset;
    width: 100%;
    height: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    background-color: #dbaa34 !important;
    font-size: 18px;
    color: white !important;
    font-weight: 500;
    border: none !important;
    height: 10vh;
    text-transform: initial;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
  }
  /* .btn-primary {
            background-color: #dbaa34 !important;
            color: #fff;
        } */
  .rotateIcon {
    display: flex !important;
    align-items: center !important;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .rotateIcon2 {
    display: flex !important;
    align-items: center !important;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
  }
  .dashCardW {
    width: 254px;
    height: 338px;
    border-radius: 0;
  }
  .cardMainDiv .courseCardImg {
    width: 280px;
    height: 175px;
    border-radius: 0;
  }
  .licCardBodyH {
    height: 18vh;
    padding: 1rem;
  }
  .dashCardTitleFont {
    margin-bottom: 0.1rem;
    font-size: 20px;
    font-family: "Ubuntu-R";
    color: #333333;
    text-align: center;
  }
  .dashTextFont {
    font-family: "Ubuntu-LI";
    font-size: 12px;
    color: #333333;
  }
  .licCardTopM {
    margin: 3vw 0 0;
  }
  .licCardFlex-end {
    display: flex;
    justify-content: flex-end;
  }
  .displayCenter {
    display: flex;
    justify-content: center;
  }
  .slick-slide {
    display: flex !important;
    justify-content: center;
    width: 310px;
  }
  .MarginLeft {
    margin: 0 0 0 7%;
  }
  .MarginRight {
    margin: 0 7% 0 0%;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0 !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 33px !important;
    line-height: 0 !important;
    opacity: 3 !important;
    position: absolute;
    color: rgb(150, 149, 149) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev,
  .slick-next {
    right: 12px;
    transform: translate(0, -100%) !important;
    z-index: 1;
  }

  .toggleDivColor {
    background-color: transparent;
    padding: 2% 2% 1.2%;
  }
  .fullprice {
    text-decoration: line-through;
  }
  .reviewFont {
    font-family: "Ubuntu-L";
    font-size: 14px;
    color: #333333;
  }
  .oldPriceFont {
    font-family: "Ubuntu-L";
    font-size: 16px;
    color: #999999;
  }
  .newPriceFont {
    font-family: "Ubuntu-B";
    font-size: 18px;
    color: #333333;
  }
  .image-overlay {
    position: absolute;
    left: 28px;
    opacity: 0.7;
    width: 53%;
    height: 302px;
    /* top: 123px; */
    display: block;
    z-index: 2;
    background-color: #0093d9;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }
  .bannerTextPosition {
    position: absolute;
    z-index: 3;
    left: 8%;
    display: flex;
    align-items: center;
    width: 90%;
    height: 42%;
    justify-content: space-between;
  }
  .headDiv {
    color: white;
    width: 38%;
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .headTextW {
    font-family: "Ubuntu-L";
    font-size: 30px;
    color: rgba(255, 255, 255, 255);
    width: 80%;
  }
  .bannerParaFont {
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
  .contactBtnDiv {
    margin-right: -45px;
    transform: rotate(90deg);
  }
  .contactBtn {
    background-color: #0093d9 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    padding: 5px;
    display: flex;
    width: 121px;
    height: 29px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: "Ubuntu-L";
  }

  .courseHeadMainDiv .btn-primary,
  .courseHeadMainDiv .btn-primary:focus,
  .courseHeadMainDiv .btn-primary:active {
    background-color: #dbaa34 !important;
  }
}

@media only screen and (min-width: 1300px) and (min-height: 525px) {
  .windowView {
    display: flex;
  }
  .mobView {
    display: none;
  }
  .CardMainTitleDiv {
    height: 14vh;
  }
  .pageHeadFont {
    font-family: "Ubuntu-L";
    font-size: 14px;
    padding: 5px 0 5px 125px;
  }
  .bannerImgW {
    width: 1310px;
    position: relative;
    height: 302px;
  }
  .insuranceHeadFont {
    font-family: "Ubuntu-B";
    font-size: 30px;
  }
  .courseHeadMainDiv {
    margin: 2% 5%;
  }
  .dashCollapseHeading {
    margin-bottom: 0px;
    font-family: "Ubuntu-R";
    font-size: 25px;
  }
  .courseHeadMainDiv .vsCollapseMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    width: 100%;
  }
  .btn-primary:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #dbaa34 !important;
  }
  .courseHeadMainDiv .solutionCollapseDiv {
    margin-bottom: unset;
    width: 100%;
    height: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    background-color: #dbaa34 !important;
    font-size: 18px;
    color: white !important;
    font-weight: 500;
    border: none !important;
    height: 10vh;
    text-transform: initial;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
  }

  .courseHeadMainDiv .solutionCollapseDiv .btn-primary:focus,
  .courseHeadMainDiv .solutionCollapseDiv .btn-primary:active,
  .courseHeadMainDiv .solutionCollapseDiv .btn-primary.active {
    background-color: #dbaa34 !important;
  }
  /* .btn-primary {
        background-color: #dbaa34 !important;
        color: #fff;
    } */
  .rotateIcon {
    display: flex !important;
    align-items: center !important;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .rotateIcon2 {
    display: flex !important;
    align-items: center !important;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
  }
  .cardMainDiv {
    height: 48vh !important;
  }
  .dashCardW {
    width: 265px;
    height: 44vh;
    border-radius: 0;
  }
  .cardMainDiv .courseCardImg {
    width: 280px;
    height: 175px;
    border-radius: 0;
  }
  .licCardBodyH {
    height: 18vh;
    padding: 1rem;
  }
  .dashCardTitleFont {
    margin-bottom: 0.1rem;
    margin-bottom: 0.1rem;
    font-size: 20px;
    font-family: "Ubuntu-R";
    color: #333333;
    text-align: center;
  }
  .dashTextFont {
    font-family: "Ubuntu-LI";
    font-size: 12px;
    color: #333333;
  }
  .licCardTopM {
    margin: 1vw 0 0;
  }
  .licCardFlex-end {
    display: flex;
    justify-content: flex-end;
  }
  .displayCenter {
    display: flex;
    justify-content: center;
  }
  .slick-slide {
    display: flex !important;
    justify-content: center;
    width: 310px;
  }
  .MarginLeft {
    margin: 0 0 0 8%;
  }
  .MarginRight {
    margin: 0 8% 0 0%;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0 !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 33px !important;
    line-height: 0 !important;
    opacity: 3 !important;
    position: absolute;
    color: rgb(150, 149, 149) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev,
  .slick-next {
    right: 12px;
    transform: translate(0, -100%) !important;
    z-index: 1;
  }

  .toggleDivColor {
    background-color: transparent;
    padding: 2%;
  }
  .fullprice {
    text-decoration: line-through;
  }
  .reviewFont {
    font-family: "Ubuntu-L";
    font-size: 14px;
    color: #333333;
  }
  .oldPriceFont {
    font-family: "Ubuntu-L";
    font-size: 16px;
    color: #999999;
  }
  .newPriceFont {
    font-family: "Ubuntu-B";
    font-size: 18px;
    color: #333333;
  }
  .image-overlay {
    position: absolute;
    left: 28px;
    opacity: 0.7;
    width: 53%;
    height: 302px;
    /* top: 123px; */
    display: block;
    z-index: 2;
    background-color: #0093d9;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }
  .bannerTextPosition {
    position: absolute;
    z-index: 3;
    left: 8%;
    display: flex;
    align-items: center;
    width: 90%;
    height: 40%;
    justify-content: space-between;
  }
  .headDiv {
    color: white;
    width: 38%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .headTextW {
    font-family: "Ubuntu-L";
    font-size: 30px;
    color: rgba(255, 255, 255, 255);
    width: 80%;
  }
  .bannerParaFont {
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
  .contactBtnDiv {
    margin-right: -45px;
    transform: rotate(90deg);
  }
  .contactBtn {
    background-color: #0093d9 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    padding: 5px;
    display: flex;
    width: 121px;
    height: 29px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
}
@media only screen and (min-width: 1300px) and (min-height: 538px) {
  .licCardBodyH {
    position: relative;
  }
  .reviewFont {
    position: absolute;
    bottom: 80px;
  }
  .star-ratings {
    bottom: 100px;
    left: 52px;
  }
  .oldPriceFont {
    position: absolute;
    bottom: 5px;
    right: 83px;
  }
  .newPriceFont {
    position: absolute;
    bottom: 5px;
  }
  .windowView {
    display: flex;
  }
  .mobView {
    display: none;
  }
  .CardMainTitleDiv {
    height: 8vh;
  }
  .pageHeadFont {
    font-family: "Ubuntu-L";
    font-size: 14px;
    padding: 5px 0 5px 125px;
  }
  .bannerImgW {
    width: 1310px;
    position: relative;
    height: 302px;
  }
  .insuranceHeadFont {
    font-family: "Ubuntu-B";
    font-size: 30px;
  }
  .courseHeadMainDiv {
    margin: 2% 5%;
  }
  .dashCollapseHeading {
    margin-bottom: 0px;
    font-family: "Ubuntu-R";
    font-size: 25px;
  }
  .courseHeadMainDiv .vsCollapseMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    width: 100%;
  }
  .btn-primary:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #dbaa34 !important;
  }
  .courseHeadMainDiv .solutionCollapseDiv {
    margin-bottom: unset;
    width: 100%;
    height: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    background-color: #dbaa34 !important;
    font-size: 18px;
    color: white !important;
    font-weight: 500;
    border: none !important;
    height: 10vh;
    text-transform: initial;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
  }

  .courseHeadMainDiv .solutionCollapseDiv .btn-primary:focus,
  .courseHeadMainDiv .solutionCollapseDiv .btn-primary:active,
  .courseHeadMainDiv .solutionCollapseDiv .btn-primary.active {
    background-color: #dbaa34 !important;
  }
  /* .btn-primary {
        background-color: #dbaa34 !important;
        color: #fff;
    } */
  .rotateIcon {
    display: flex !important;
    align-items: center !important;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .rotateIcon2 {
    display: flex !important;
    align-items: center !important;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
  }
  .cardMainDiv {
    height: 48vh !important;
  }
  .dashCardW {
    width: 265px;
    height: 44vh;
    border-radius: 0;
  }
  .cardMainDiv .courseCardImg {
    width: 280px;
    height: 175px;
    border-radius: 0;
  }
  .licCardBodyH {
    height: 18vh;
    padding: 1rem;
  }
  .dashCardTitleFont {
    margin-bottom: 0.1rem;
    font-size: 20px;
    font-family: "Ubuntu-R";
    color: #333333;
    text-align: center;
  }
  .dashTextFont {
    font-family: "Ubuntu-LI";
    font-size: 12px;
    color: #333333;
  }
  .licCardTopM {
    margin: 1vw 0 0;
  }
  .licCardFlex-end {
    display: flex;
    justify-content: flex-end;
  }
  .displayCenter {
    display: flex;
    justify-content: center;
  }
  .slick-slide {
    display: flex !important;
    justify-content: center;
    width: 310px;
  }
  .MarginLeft {
    margin: 0 0 0 8%;
  }
  .MarginRight {
    margin: 0 8% 0 0%;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0 !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 33px !important;
    line-height: 0 !important;
    opacity: 3 !important;
    position: absolute;
    color: rgb(150, 149, 149) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev,
  .slick-next {
    right: 12px;
    transform: translate(0, -100%) !important;
    z-index: 1;
  }

  .toggleDivColor {
    background-color: transparent;
    padding: 2%;
  }
  .fullprice {
    text-decoration: line-through;
  }
  .reviewFont {
    font-family: "Ubuntu-L";
    font-size: 14px;
    color: #333333;
  }
  .oldPriceFont {
    font-family: "Ubuntu-L";
    font-size: 16px;
    color: #999999;
  }
  .newPriceFont {
    font-family: "Ubuntu-B";
    font-size: 18px;
    color: #333333;
  }
  .image-overlay {
    position: absolute;
    left: 28px;
    opacity: 0.7;
    width: 53%;
    height: 302px;
    /* top: 123px; */
    display: block;
    z-index: 2;
    background-color: #0093d9;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }
  .bannerTextPosition {
    position: absolute;
    z-index: 3;
    left: 8%;
    display: flex;
    align-items: center;
    width: 90%;
    height: 40%;
    justify-content: space-between;
  }
  .headDiv {
    color: white;
    width: 38%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .headTextW {
    font-family: "Ubuntu-L";
    font-size: 30px;
    color: rgba(255, 255, 255, 255);
    width: 80%;
  }
  .bannerParaFont {
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
  .contactBtnDiv {
    margin-right: -45px;
    transform: rotate(90deg);
  }
  .contactBtn {
    background-color: #0093d9 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    padding: 5px;
    display: flex;
    width: 121px;
    height: 29px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
}

@media only screen and (min-width: 1300px) and (min-height: 668px) {
  .licCardBodyH {
    position: relative;
  }
  .reviewFont {
    position: absolute;
    bottom: 96px;
  }
  .star-ratings {
    bottom: 32px;
    left: 22px;
  }
  .oldPriceFont {
    position: absolute;
    bottom: 5px;
    right: 83px;
  }
  .newPriceFont {
    position: absolute;
    bottom: 5px;
  }

  .image-overlay {
    position: absolute;
    left: 0px;
    opacity: 0.7;
    width: 53%;
    height: 44.7%;
    display: block;
    z-index: 2;
    background-color: #0093d9;
    -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  }
  .windowView {
    display: flex;
  }
  .mobView {
    display: none;
  }
  .CardMainTitleDiv {
    height: 10vh;
  }
  .pageHeadFont {
    font-family: "Ubuntu-L";
    font-size: 14px;
    padding: 5px 0 5px 125px;
  }
  .bannerImgW {
    width: 1360px;
    position: relative;
    height: 302px;
  }
  .insuranceHeadFont {
    font-family: "Ubuntu-B";
    font-size: 30px;
  }
  .courseHeadMainDiv {
    margin: 2% 5%;
  }
  .dashCollapseHeading {
    margin-bottom: 0px;
    font-family: "Ubuntu-R";
    font-size: 25px;
  }
  .courseHeadMainDiv .vsCollapseMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    width: 100%;
  }
  .btn-primary:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #dbaa34 !important;
  }
  .courseHeadMainDiv .solutionCollapseDiv {
    margin-bottom: unset;
    width: 100%;
    height: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    background-color: #dbaa34 !important;
    font-size: 18px;
    color: white !important;
    font-weight: 500;
    border: none !important;
    height: 10vh;
    min-height: 10vh;
    text-transform: initial;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
  }
  /* .btn-primary {
            background-color: #dbaa34 !important;
            color: #fff;
        } */
  .rotateIcon {
    display: flex !important;
    align-items: center !important;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .rotateIcon2 {
    display: flex !important;
    align-items: center !important;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
    justify-content: center;
  }
  .cardMainDiv {
    height: 48vh !important;
  }
  .dashCardW {
    width: 275px;
    height: 45vh;
    border-radius: 0;
    cursor: pointer;
  }
  .cardMainDiv .courseCardImg {
    width: 280px;
    height: 175px;
    border-radius: 0;
  }
  .licCardBodyH {
    height: 18vh;
    padding: 1rem;
  }
  .dashCardTitleFont {
    margin-bottom: 0.1rem;
    font-size: 20px;
    font-family: "Ubuntu-R";
    color: #333333;
    text-align: center;
  }
  .dashTextFont {
    font-family: "Ubuntu-L";
    font-size: 12px;
    color: #333333;
  }
  .licCardTopM {
    margin: 1vw 0 0;
  }
  .licCardFlex-end {
    display: flex;
    justify-content: flex-end;
  }
  .displayCenter {
    display: flex;
    justify-content: center;
  }
  .slick-slide {
    display: flex !important;
    justify-content: center;
    width: 310px;
  }
  .slick-next {
    right: -1% !important;
  }
  .slick-prev {
    left: -3% !important;
  }
  .MarginLeft {
    margin: 0 0 0 5%;
  }
  .MarginRight {
    margin: 0 8% 0 0%;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0 !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 33px !important;
    line-height: 0 !important;
    opacity: 3 !important;
    position: absolute;
    color: rgb(150, 149, 149) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev,
  .slick-next {
    right: 12px;
    transform: translate(0, -100%) !important;
    z-index: 1;
  }

  .toggleDivColor {
    background-color: transparent;
    padding: 2% 2% 1.2%;
  }
  .fullprice {
    text-decoration: line-through;
  }
  .star-container {
    vertical-align: super !important;
  }
  .star-ratings {
    padding-right: 4px;
    margin-top: 0.5px;
  }
  .reviewFont {
    font-family: "Ubuntu-L";
    font-size: 12px;
    color: #333333;
    margin-bottom: 0px !important;
  }
  .oldPriceFont {
    font-family: "Ubuntu-L";
    font-size: 16px;
    color: #999999;
  }
  .newPriceFont {
    font-family: "Ubuntu-B";
    font-size: 18px;
    color: #333333;
  }
  .image-overlay {
    position: absolute;
    left: 31px;
    opacity: 0.7;
    width: 53%;
    height: 302px;
    /* top: 123px; */
    display: block;
    z-index: 2;
    background-color: #0093d9;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }
  .bannerTextPosition {
    position: absolute;
    z-index: 3;
    left: 8%;
    display: flex;
    align-items: center;
    width: 90%;
    height: 40%;
    justify-content: space-between;
  }
  .headDiv {
    color: white;
    width: 38%;
    height: 26vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .headTextW {
    font-family: "Ubuntu-L";
    font-size: 30px;
    color: rgba(255, 255, 255, 255);
    width: 80%;
  }
  .bannerParaFont {
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
  .contactBtnDiv {
    margin-right: -43px;
    transform: rotate(90deg);
  }
  .contactBtn {
    background-color: #0093d9 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    padding: 5px;
    display: flex;
    width: 121px;
    height: 29px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
}

@media only screen and (min-width: 1800px) {
  .licCardBodyH {
    position: relative;
  }
  .reviewFont {
    position: absolute;
    bottom: 100px;
  }
  .star-ratings {
    position: absolute;
    bottom: 87px;
    left: 40px;
  }
  .oldPriceFont {
    position: absolute;
    bottom: 5px;
    right: 110px;
  }
  .newPriceFont {
    position: absolute;
    bottom: 5px;
  }

  .windowView {
    display: flex;
  }
  .mobView {
    display: none;
  }
  .CardMainTitleDiv {
    height: 14vh;
  }
  .pageHeadFont {
    font-family: "Ubuntu-L";
    font-size: 24px;
    padding: 5px 0 5px 150px;
  }
  .bannerImgW {
    width: 95%;
    position: relative;
    height: auto;
  }
  .insuranceHeadFont {
    font-family: "Ubuntu-B";
    font-size: 45px;
  }
  .courseHeadMainDiv {
    margin: 2% 5%;
  }
  .dashCollapseHeading {
    margin-bottom: 0px;
    font-family: "Ubuntu-R";
    font-size: 35px;
    padding: 0 20px;
  }
  .courseHeadMainDiv .vsCollapseMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    width: 100%;
  }
  .btn-primary:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #dbaa34 !important;
  }
  .courseHeadMainDiv .solutionCollapseDiv {
    margin-bottom: unset;
    width: 100%;
    height: 4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    background-color: #dbaa34 !important;
    font-size: 18px;
    color: white !important;
    font-weight: 500;
    border: none !important;
    height: 10vh;
    text-transform: initial;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
  }
  /* .btn-primary {
                background-color: #dbaa34 !important;
                color: #fff;
            } */
  .rotateIcon {
    display: flex !important;
    align-items: center !important;
    font-size: 35px;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .rotateIcon2 {
    display: flex !important;
    align-items: center !important;
    font-size: 35px;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
  }
  .cardMainDiv {
    height: 510px !important;
  }
  .dashCardW {
    width: 23rem;
    height: 495px;
    border-radius: 0;
  }
  .cardMainDiv .courseCardImg {
    width: 280px;
    height: 175px;
    border-radius: 0;
  }
  .licCardBodyH {
    height: 18vh;
    padding: 1rem;
  }
  .dashCardTitleFont {
    margin-bottom: 0.1rem;
    font-size: 24px;
    font-family: "Ubuntu-R";
    color: #333333;
  }
  .dashTextFont {
    font-family: "Ubuntu-L";
    font-size: 22px;
    color: #333333;
  }
  .licCardTopM {
    margin: 3vw 0 0;
  }
  .licCardFlex-end {
    display: flex;
    justify-content: flex-end;
  }
  .displayCenter {
    display: flex;
    justify-content: center;
  }
  .slick-slide {
    display: flex !important;
    align-items: center !important;
    font-size: 35px;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
  }

  .dashCardW {
    width: 23rem;
    height: 495px;
    border-radius: 0;
  }
  .cardMainDiv .courseCardImg {
    width: 280px;
    height: 175px;
    border-radius: 0;
  }
  .licCardBodyH {
    height: 18vh;
    padding: 1rem;
  }
  .dashCardTitleFont {
    margin-bottom: 0.1rem;
    font-size: 22px;
    font-family: "Ubuntu-R";
    color: #333333;
  }
  .dashTextFont {
    font-family: "Ubuntu-L";
    font-size: 22px;
    color: #333333;
  }
  .licCardTopM {
    margin: 3vw 0 0;
  }
  .licCardFlex-end {
    display: flex;
    justify-content: flex-end;
  }
  .displayCenter {
    display: flex;
    justify-content: center;
    width: 310px;
  }
  .MarginLeft {
    margin: 0 0 0 8%;
  }
  .MarginRight {
    margin: 0 8% 0 0%;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0 !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 33px !important;
    line-height: 0 !important;
    opacity: 3 !important;
    position: absolute;
    color: rgb(150, 149, 149) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev,
  .slick-next {
    right: 12px;
    transform: translate(0, -100%) !important;
    z-index: 1;
  }

  .toggleDivColor {
    background-color: transparent;
    padding: 2% 2% 1.2%;
  }
  .fullprice {
    text-decoration: line-through;
  }
  .reviewFont {
    font-family: "Ubuntu-L";
    font-size: 24px;
    color: #333333;
  }
  .oldPriceFont {
    font-family: "Ubuntu-L";
    font-size: 28px;
    color: #999999;
  }
  .newPriceFont {
    font-family: "Ubuntu-B";
    font-size: 28px;
    color: #333333;
  }
  .image-overlay {
    position: absolute;
    left: 47px;
    opacity: 0.7;
    width: 53%;
    height: 405px;
    /* top: 123px; */
    display: block;
    z-index: 2;
    background-color: #0093d9;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }
  .bannerTextPosition {
    position: absolute;
    z-index: 3;
    left: 8%;
    display: flex;
    align-items: center;
    width: 90%;
    height: 38%;
    justify-content: space-between;
  }
  .headDiv {
    color: white;
    width: 38%;
    height: 26vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .headTextW {
    font-family: "Ubuntu-L";
    font-size: 45px;
    color: rgba(255, 255, 255, 255);
    width: 80%;
  }
  .bannerParaFont {
    font-size: 26px;
    font-family: "Ubuntu-L";
  }
  .contactBtnDiv {
    margin-right: -57px;
    transform: rotate(90deg);
  }
  .contactBtn {
    background-color: #0093d9 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    padding: 5px;
    display: flex;
    width: 185px;
    height: 50px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 26px;
    font-family: "Ubuntu-L";
  }
}

.licentia-next {
  background-image: url(../../Assets/images/next-dashboard.png);
  background-size: cover;
  height: 25px;
  width: 25px;
}

.courseHeadMainDiv .slick-slider .slick-arrow {
  opacity: 1 !important;
  opacity: 1 !important;
  background: white;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-left: 17px;
  border-radius: 50%;
  width: 40px;
  /* left: 96%; */
  height: 40px;
  box-shadow: 1px 1px 1px 1px #d2d2d2;
}

.courseHeadMainDiv .slick-slider .slick-arrow::before {
  opacity: 0 !important;
}

.dashboard-image-row {
  position: relative;
}

.dashboard-banner-image {
  width: 100%;
  height: auto;
  z-index: 10;
}

.blue-overlay {
  background-color: rgba(25, 141, 199, 0.7);
  z-index: 12;
  position: absolute;
  width: 56%;
  height: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 45% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 74% 100%, 0% 100%);
}

.dashboard-banner-text-container .dashboard-banner-text-one {
  font-family: Ubuntu-L;
  font-size: 30px;
  margin-bottom: 0px;
}

.dashboard-banner-text-container .dashboard-banner-text-two {
  font-family: Ubuntu-B;
  color: #ff9900;
  font-size: 30px;
  margin-bottom: 0px;
}

.dashboard-banner-text-container .dashboard-banner-text-three {
  text-align: justify;
  font-family: Ubuntu-L;
  font-size: 16px;
  margin-bottom: 0px;
  padding-right: 173px;
}

@media (max-width: 1200px) {
  .dashboard-banner-text-container .dashboard-banner-text-one {
    font-family: Ubuntu-L;
    font-size: 24px;
    margin-bottom: 0px;
  }

  .dashboard-banner-text-container .dashboard-banner-text-two {
    font-family: Ubuntu-B;
    color: #ff9900;
    font-size: 24px;
    margin-bottom: 0px;
  }

  .dashboard-banner-text-container .dashboard-banner-text-three {
    text-align: justify;
    font-family: Ubuntu-L;
    font-size: 14px;
    margin-bottom: 0px;
    padding-right: 70px;
  }
}

@media (max-width: 992px) {
  .mobView {
    display: block;
  }
}

@media (max-width: 576px) {
  .slick-arrow {
    margin-top: 0px !important;
  }
  .arrow-image {
    width: 5px;
    height: auto;
  }
  .dashCardW1 {
    min-height: 250px !important;
    width: 265px;
    margin: 5px 0px;
  }

  .dashboard-banner-text-container {
    width: 75%;
    position: absolute;
    height: 100%;
    z-index: 15;
    padding: 13% 3% 7%;
    color: white;
    padding-left: 1em;
    line-height: 1.3;
  }

  .dashboard-banner-text-container .dashboard-banner-text-one {
    font-family: Ubuntu-L;
    font-size: 18px;
    margin-bottom: 0px;
  }

  .dashboard-banner-text-container .dashboard-banner-text-two {
    font-family: Ubuntu-B;
    color: #ff9900;
    font-size: 18px;
    margin-bottom: 0px;
  }

  .dashboard-banner-text-container .dashboard-banner-text-three {
    text-align: justify;
    font-family: Ubuntu-L;
    font-size: 12px;
    margin-bottom: 0px;
    padding-right: 70px;
  }

  .dashboard-banner-image {
    width: 100%;
    height: 200px;
    z-index: 10;
    /* margin-left: -300px;*/
  }

  .blue-overlay {
    background-color: rgba(25, 141, 199, 0.7);
    z-index: 12;
    position: absolute;
    width: 75%;
    height: 100%;
    -webkit-clip-path: polygon(0 0, 100% 0, 45% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 74% 100%, 0% 100%);
  }

  .reviewFont {
    position: absolute;
    bottom: 70px;
  }
  .dashboard-main-body {
    margin-top: 70px;
    min-height: 82vh;
  }
  .star-ratings {
    position: absolute;
    bottom: -3px;
    left: 30px;
  }
  .oldPriceFont {
    position: absolute;
    bottom: 5px;
    right: 70px;
  }
  .newPriceFont {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
  .windowView {
    display: none;
  }

  .pageHeadFont {
    font-family: "Ubuntu-L";
    font-size: 14px;
    padding: 5px 0 5px 125px;
  }
  .bannerImgW {
    width: 1310px;
    position: relative;
    height: 302px;
  }
  .insuranceHeadFont {
    font-family: "Ubuntu-B";
    font-size: 18px;
  }
  .courseHeadMainDiv {
    margin: 2% 8%;
  }
  .dashCollapseHeading {
    margin-bottom: 0px;
    font-family: "Ubuntu-R";
    font-size: 14px;
  }
  .courseHeadMainDiv .vsCollapseMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    width: 100%;
  }
  .btn-primary:not([disabled]):not(.disabled):active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #dbaa34 !important;
  }
  .courseHeadMainDiv .solutionCollapseDiv {
    margin-bottom: unset;
    width: 100%;
    height: 50px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    background-color: #dbaa34 !important;
    font-size: 18px;
    color: white !important;
    font-weight: 500;
    border: none !important;

    text-transform: initial;
    margin: 0;
    border: none !important;
    box-shadow: none !important;
  }

  .rotateIcon {
    display: flex !important;
    align-items: center !important;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .rotateIcon2 {
    display: flex !important;
    align-items: center;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
  }
  .dashCardW {
    width: 265px;
    height: 338px;
    border-radius: 0;
  }
  .cardMainDiv .courseCardImg {
    width: 280px;
    height: 175px;
    border-radius: 0;
  }
  .licCardBodyH {
    height: 18vh;
  }
  .dashCardTitleFont {
    margin-bottom: 0.1rem;
    font-size: 16px;
    font-family: "Ubuntu-R";
    color: #333333;
  }
  .dashTextFont {
    font-family: "Ubuntu-LI";
    font-size: 12px;
    color: #333333;
  }
  .licCardTopM {
    margin: 3vw 0 0;
  }
  .licCardFlex-end {
    display: flex;
    justify-content: flex-end;
  }
  .displayCenter {
    display: flex;
    justify-content: center;
  }
  .slick-slide {
    display: flex !important;
    justify-content: center;
    width: 310px;
  }
  .MarginLeft {
    margin: 6% 8%;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0 !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 33px !important;
    line-height: 0 !important;
    opacity: 3 !important;
    position: absolute;
    color: rgb(150, 149, 149) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 20px;
  }
  .slick-prev,
  .slick-next {
    font-size: 22px !important;
    line-height: 1 !important;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: black !important;
    border: none;
    outline: none;
    background: transparent;
  }

  .toggleDivColor {
    background-color: transparent;
    padding: 2% 2% 1.2%;
  }
  .fullprice {
    text-decoration: line-through;
  }
  .reviewFont {
    font-family: "Ubuntu-L";
    font-size: 14px;
    color: #333333;
  }
  .oldPriceFont {
    font-family: "Ubuntu-L";
    font-size: 16px;
    color: #999999;
  }
  .newPriceFont {
    font-family: "Ubuntu-B";
    font-size: 18px;
    color: #333333;
  }
  .image-overlay {
    position: absolute;
    left: 28px;
    opacity: 0.7;
    width: 53%;
    height: 302px;
    /* top: 123px; */
    display: block;
    z-index: 2;
    background-color: #0093d9;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  }
  .bannerTextPosition {
    position: absolute;
    z-index: 3;
    left: 8%;
    display: flex;
    align-items: center;
    width: 90%;
    height: 40%;
    justify-content: space-between;
  }
  .headDiv {
    color: white;
    width: 38%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .headTextW {
    font-family: "Ubuntu-L";
    font-size: 30px;
    color: rgba(255, 255, 255, 255);
    width: 80%;
  }
  .bannerParaFont {
    font-size: 16px;
    font-family: "Ubuntu-L";
  }
  .contactBtnDiv {
    margin-right: -45px;
    transform: rotate(90deg);
  }
  .contactBtn {
    background-color: #0093d9 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    padding: 5px;
    display: flex;
    width: 121px;
    height: 29px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: "Ubuntu-L";
  }

  .courseHeadMainDiv .slick-slider .slick-arrow {
    opacity: 1 !important;
    opacity: 1 !important;
    background: white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-left: -2px;
    border-radius: 50%;
    width: 30px;
    /* left: 96%; */
    height: 30px;
    box-shadow: 1px 1px 1px 1px #d2d2d2;
  }

  .courseHeadMainDiv .slick-slider .slick-arrow1::before {
    opacity: 0 !important;
  }

  mobView {
    display: block;
  }
}
