


.floating-icon-default{
    display:none
}

.floating-media-button .menuNavbar{
    display:none
}

.floating-media-button .floating-icon-default{
    position: fixed;
    width: 80px;
    height: 80px;
    display: flex;
    right: 10px;
    background: white;
    z-index: 9;
    border-radius: 50%;
    bottom: 10%;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    border: 4px solid black;
    cursor: pointer;
    animation: bounce 3s 2;
}

@keyframes bounce{
    0% {bottom:10%}
    25% {bottom:30%}
    50% {bottom:10%}
    75% {bottom:-25%}
    100% {bottom:10%}
}
      
.adminLogoHeight{
    height: 60px;
    margin-left:55px
}