html{
   height:100%;
   overflow-x:hidden ;
   overflow-y:scroll
}
.textFontSize{
font-family: Ubuntu-R;
}
.modal{
   background-color: white !important;
   display:block !important;
}

.margin-top-mobile{
   margin-top: 0px;
}

.dashboard-image-row{
   z-index: -1;
}
.btn{
   text-transform: unset !important;
}

#homepage .insurance-industry-container .slick-arrow{
   opacity:1
}



.svg-component{
   text-align: center;
   padding: 50px;
   /* color: yellow; */
   border-radius: 25px;
   border: 1px solid;
   position: relative;
   background-color: white;
   border-color: #e4e4e4;
   margin: 20px 10px;
   height: 30vh;
   display: flex;
   justify-content: center;
   align-items: center;
}

@media(max-width:768px){
    .margin-top-mobile{
        margin-top: 90px;
     }
}
.opacity-0{
   opacity: 0 !important;
}


.admin-button-style{
   background: #0085ba;
    border-color: #006a95 #00648c #00648c;
    box-shadow: inset 0 -1px 0 #00648c;
    color: #fff;
    text-decoration: none;
    text-shadow: 0 -1px 1px #005d82, 1px 0 1px #005d82, 0 1px 1px #005d82, -1px 0 1px #005d82;
    border-radius: 3px;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
}


#add-subject-page label{
   margin-right: 0%;
   width: 26%;
   font-weight: bold;
}

#add-subject-page input{
   width:60%;
   transition: all .3s;
    outline: none;
    box-shadow: none;
    border: 1px solid grey;
    border-radius: 4px;
    background-color: transparent;
    box-sizing: border-box;
    background-color: white;
}

.ml-30px{
   margin-left:30px
}


#subject-page-list table th {
   font-size: 18px;
   font-weight: bolder;
}



#subject-page-list table tr {
   height:30px
   }



  #subject-page-list table{
   width: 97.5%;
   margin: 10px auto;
   box-shadow: 0px 1px 2px 1px #a29f9f;
  }


  
  #subject-page-list .pages_table tr:first-child th {
   padding: 5px 10px;
   font-size: 16px;
   font-family: Ubuntu-R;
}

.course-status-icon{
   width:30px;
}

.subsubsub li span{
   font-family:Ubuntu-L
}


.name_div_table > span{
   font-size:15px;
}




.cross_icon{
   cursor:pointer
}


.min-height-unset{
   min-height:unset !important;
}


.fit-content{
   height:fit-content
}



.exam-submenu-container, #homepage .exam-submenu-container{
   overflow: hidden;
}



.default-footer .buttons .today{
   visibility: hidden !important
}

.default-footer .hint {
 display: none !important;
}


.wishlist-img{
   cursor:default;
   opacity:0
}



input.feedback_phone::placeholder, textarea.feedback_message::placeholder{
   color:lightgray
}
