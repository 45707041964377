

  #dropdown {
    position: relative;

  }
  
  .dropdown-content {
    display: none;
    position: absolute;

    right: -162px;
    top:0;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 6px;
    text-decoration: none;
    display: block;
    background-color: #fff;
    text-align: justify;
    padding-left: 20px;

    font-size: 14px;
  }
  
  #dropdown a:hover{   color: #fff;
background-color: gray;

}
  
  #dropdown:hover .dropdown-content {display: block;}
  


    @media screen and (min-width: 320px){
        /* .dropdown-menu.show {
            background-color: white !important
        } */
    }  

@media screen and (min-width: 900px){
    .sidebar-home-btn{

        width: 100%;
        padding: 0%;
        display: flex;
        padding-left: 20px;
        align-items: center;
        height: 40px;
        background-color: rgb(48,51,54) !important;
        color: #fff;
        border: none;

    }


    .sidebar-home-btn-span{

       
        padding-left: 13px;
        font-size: 15px;

    }
     

    .sidebar-home-btn:hover{
 
        background-color: rgb(52, 98, 119) !important;
    }

  

  
    .sidebar_dropdown{
        display:flex;
   
        justify-content: space-between;
    
        position: relative !important;
        display: block !important;
        /* width: 100%; */
        padding: 0% !important;
        
        
        align-items: center !important;
    }



    .sidebar_dropdown_name_icon_main{
       
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
    }
    
    
    
    .sidebar_dropdown_icon{
       
      
        width:  25%
       
    }
    .sidebar_dropdown_name{
       text-align: justify;
      
        width:  75%;
  
       
    }

    .sidebar_dropdown_btn{

      
        padding: 5px !important;
        margin: 1px !important;
        width:98% !important;
        background-color: rgb(48,51,54) !important;
        border: none !important;
        height: 5vh!important;
  
            box-shadow: none !important;
    }


    
    .sidebar_dropdown_btn:focus{
 

        background-color: #0093D9 !important;
    }
    .sidebar_dropdown_btn:hover{
 
        background-color: rgb(52, 98, 119) !important;
    }

    .sidebar_dropdown_btn:active{
 
        background-color: #0093D9 !important;
    }
/* 
    .sidebar_dropdown_Menu_Item{
        padding: 5%;
    }
   */
    
    .sidebar_dropdown_Item:focus{

        background-color: rgba(130, 172, 192, 0.835)!important;
        color:#fff !important;
     


    }
    
    .sidebar_dropdown_Item:hover{

        background-color: rgb(200, 210, 214)!important;
        color:#fff !important;
     


    }
    
    .sidebar_dropdown_Item:active{

       
        background-color: rgba(130, 172, 192, 0.835)!important;
        color:#fff !important;


    }
    .sidebar_dropdown_Item{


        font-size: 14px;
        font-family:Ubuntu-r;
        /* padding: 10px 0px 10px 0px !important; */
    }
    .sidebar_dropdown_Menu_Item{
           
      
        padding: 0% !important;
    }

    }
@media screen and (min-width: 1100px){
  

    }

   
@media only screen and (min-width: 1300px)  and (min-height:525px){}  





@media only screen and (min-width: 1300px)  and (min-height:668px){}



    @media screen and (min-width: 1800px){}




    .dropdown:hover>.dropdown-menu {
        display: block;
      }
      
      .dropdown>.dropdown-toggle:active {
        /*Without this, clicking will make it sticky*/
          pointer-events: none;
      }
      