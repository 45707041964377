#AddPages .BannerImageDiv{

    position: relative;
}


#AddPages .BannerImage {
  
    height: 200px;
    width: 100%;
    margin-bottom: 20px;
    
}


#AddPages .BannerText {
  
    font-family: Ubuntu-L;
    font-size: 40px;
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
    width: 100%;
}


