.my-account-title{
    font-family: Ubuntu-M;
    font-size: 25px;
}

.my-account-subtitle{
    font-family: Ubuntu-LI;
    font-size: 14px;
}

.account-details-container td{
    border:0;
}

.account-details-container tr td:first-child{
   color: black;
   font-size:16px;
   border: none;
   font-family: Ubuntu-L;
}

.account-details-container tr td:nth-child(2){
    color: #7b7b7b;
    font-size:16px;
    border: none;
    font-family: Ubuntu-L;
 }
 

 .reset-button, .edit-button{
    width: 150px;
    font-family: Ubuntu-L;
    float: right;
    padding: 7px 10px;
    margin: 5px;
    color: white;
    font-size: 16px;
 }


 .reset-button{
    background-color:#ff6633;
 }

 .edit-button{
    background-color:#0099cc;
 }


 @media only screen and (max-width: 1064px)   {
  
   .course-thumb {
      width: 124px;
  }
  #my-account .account-details-container td{
   font-size: 20px !important;
  }
  table tbody tr td {
   font-size: 24px !important;
 }
  .user-image{
     width: 40px !important;
  }

}


 @media(max-width:1024px){
    .rowDefault{
margin-top: 0 !important; 
}
.search-icon-header {
   padding-right: 0px;
}

 }
 @media(max-width:768px){
    .account-components-border{
       margin-top: 70px;
       min-height: 100vh;
    }
    .container-c{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
   }
   
}
 @media(max-width:576px){
    .min-account{
      min-height: 80vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
   .account-components-border{
      min-height: 58 !important;
   }
    .navigation-options-container .navigation-button {
        background-color: transparent;
        border: 0px;
        font-size: 14px;
        justify-content: space-between;
        display: flex;
        text-align: left;
        color: white;
        align-items: center;
        padding: 15px 8px;
        cursor: pointer;
        font-family: Ubuntu-L;
    }
    .edit-button{
       margin: 20px 4px;
       width: 100px;
    }
    .container-c{
       padding-top: 0 !important;
    }
/*       */

.container-my-mobile-ipad-div{

   /* padding: 60px 55px 10px 55px; */

}

.my-account-title{
   font-family: Ubuntu-M;
   font-size: 20px;
}
#my-account .account-details-container td {
   font-size: 14px !important;
}

 }



.right-arrow-nav{
   margin-top: 10px;
}
.right-arrow-nav img{
   width:25px;
}

.academic-title, .professional-title{
   color: #000000;
   font-family:Ubuntu-L;
}

.academic-title span, .professional-title span{
   color:#7b7b7b;
   font-family:Ubuntu-LI;
}
.reset{
   background-color: #dbaa34;
}



     
.RegistrationInputType:focus {
 
   border: 1px solid #EEEEEE !important;
   
   
  
 }

 .RegistrationInputType{
   width: 400% ;
   height: 30px !important ;
   border-radius: 5px;
   transition: all 0.30s ease-in-out !important;
   -moz-transition: all 0.30s ease-in-out !important;
   -ms-transition: all 0.30s ease-in-out !important;
   -o-transition: all 0.30s ease-in-out !important ;
   outline: none;
   padding: 3px 0px 3px 3px;
   margin: 5px 1px 3px 0px;
   border: 1px solid #E5E5E5;
   font-family:'Ubuntu-L'
   /* margin-top: -30px !important; */
 }


 select{
    width: 170px;
    height: 31px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1.5px solid rgb(228, 228, 228);
 }


 .editDateInput{
   height: 33px !important;
   border-radius: 5px !important;
   border: 1.2px solid rgb(228, 228, 228) !important;
   background-color: rgb(255, 255, 255) !important;
 }


 .edit-picture-input{
    display:block !important;
 }



 .edit-profile-pic-image{
    margin-top:20px;
    margin-bottom:20px;
    width:100px;
    height:auto;
 }


 @media(max-width:525px){
    .my-acc{
       min-height: 100vh;
    }
   .navigation-options-container .navigation-button {
       background-color: transparent;
       border: 0px;
       font-size: 14px;
       justify-content: space-between;
       display: flex;
       text-align: left;
       color: white;
       align-items: center;
       padding: 15px 8px;
       cursor: pointer;
       font-family: Ubuntu-L;
   }


}

