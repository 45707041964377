.startTestDecrpition{
    margin: 50px 50px 50px 100px;
    width: 85%;
}

.startTestList{
    margin-top: 10px;
}

.button_and_text{
    margin: -220px 2px 2px 75px;
    position: absolute;
}


.bg_img_div{
    width: 100%;
    height: 400px;
}

.bg_img{
    width: 100%;
    height: 390px;
}

.text_on_test_BGimg{
    font-size: 25px;
    font-family: Ubuntu-b;
    color:#fff;
    width: 80%;
}

.buttons_flex{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.Button_NotNow{
    width: 160px;
    height: 35px;
    border-radius: 5px;
    background-color: #dbaa34;
    border: 1px solid #dbaa34;
    text-align: center;
    color: #FFF0E9;

}

.Button_NotNow:hover {

  background-color:  rgb(185, 109, 28);
}
.Button_startTest{
    width: 160px;
    height: 35px;
    border-radius: 5px;
    background-color: #0093D9;
    border: 1px solid #0093D9;
    text-align: center;
    color: #FFF0E9;
}

.Button_startTest:hover {
  background-color:  rgb(43, 127, 167);
}


.start-test-page .header{
    width:100%
}