.my-orders-title{
    font-family: Ubuntu-M;
    font-size: 25px;
}

.my-orders-subtitle{
    font-family: Ubuntu-LI;
    font-size: 14px;
}

.view_invoice{
    width: 100%;
    padding: 5px;
    color:white
}
.invoice-items{
    margin-right: 10px;
    color: #9c9a9a;
    margin-top: 0px;
    font-size: 12px;
    font-weight: 400;
    font-family: Ubuntu-L;
    margin-right: 10px;
    margin-top:0px
}

.view-details-button{
    width: 150px;
    font-family: Ubuntu-L;
    float: right;
    padding: 7px 10px;
    margin: 5px;
    color: white;
    font-size: 16px;
    background-color:#0099cc;
}

.back-button{
    width: 150px;
    font-family: Ubuntu-L;
    float: right;
    padding: 7px 10px;
    margin: 5px;
    color: white;
    font-size: 16px;
    background-color:#ff6633;

}
.course-thumb-order{
    height: auto;
    width: 105px;
}
@media only screen and (max-width: 1024px)   {
    .course-thumb-order{
        width: 85px !important;
        margin-top: -9px !important;
    }
    .container-c{
        padding-top: 40px;
    }
.rowDefault  .course-thumb-order{
    width: 70px;
}
.course-purchase{
    font-size: 17px;
}
.invoice-items{
    font-size: 15px;
}
}

@media only screen and (max-width: 525px)   {
.course-purchase{
    margin: 0 auto !important;
}
.my-course-image{

}
}
@media only screen and (max-width: 768px)   {
    .container-c{
        min-height: 24vh;
    }
    }
@media only screen and (max-width: 2500px)   {
.upper-div-cost{
    display: none;
}
.lower-div-cost{
    display: block;
}
}

@media only screen and (max-width: 1023px)   {
    .upper-div-cost{
        display: block !important;
    }
    
    .lower-div-cost{
        display: none !important;
    }
    .account-nav-ipad{
        max-width: 100% !important;
    }
    .edit-button{
        width: 110px !important;
    }
    course-title{
        font-size: 20px;
    }
    .course-thumb-order{
        margin-top: 20px !important;
        height: auto;
        width: 90px !important;
    }
    }
@media only screen and (max-width: 525px)   {
 
    .windowView{
        display: none;
    }
    .account-nav-ipad{
        display: none;
    }
    .course-thumb-order{
        height: auto;
        margin-top: 0px !important;
        width: 80px !important;
    }
    .course-row > .row {
        margin-bottom: 20px;
        padding: 10px 10px 0px 10px;
    }
    .view_invoice{
        padding: 0;
    }
    .vsMobView{
        display:block;
    }
    .course-title {
        font-size: 14px;
    }
    .course-purchase{
        font-size: 13px;
    }
}
/* @media only screen and (min-width: 900px)   {
    .windowView{
        display: block;
    }

    .vsMobView{
        display:none;
    }
} */
















.my-orders-invoice-container{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    transition: all 0.15s ease-in-out ;
}


.order-invoice-button:hover{
    box-shadow: 0px 1px 9px 0px #929090;
    color: white !important;
}