.circular-item-container{

}

.circular-item-container a 
    {
        font-size: 16px;
        font-family: Ubuntu-R;
    }
.faq-im-con{
    display: flex !important;
    flex-direction: column;
    align-items: flex-end !important;
}
.caseLawsSearch{
    flex-direction: column;

    justify-content: center !important;
}
.searchIconActs{
    width: 30px;
    height: 30px;
}
.actsSearch{
    /* height: 35px;
    padding: 10px 10px;
    font-size: 16px;
    margin-left: 95px;
    margin-top: 20px;
    width: 260px;
    background-color: white;
    border-radius: 5px; */
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    color: #565656;
    padding: 8px 12px;
    height: 38px;
    width: 250px;
    box-shadow: none;
    margin-top: 10px;
}

.actsSearch:focus{
    background-color: #FFFFFF;
    border: 1px solid #AAAAAA;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
    color: #333333; 
}


.inputBox ::placeholder {
    color:rgb(124, 120, 120);
    opacity: 1;
  }
  .inputBox{
      margin-left: 95px;
      margin-top: 20px;
  }
.acts-and-regulations-page .circularModal .modal-content{
    position:fixed;
     width:98vw; 
     height:98vh;
     margin: 0 auto;
     top:2px;
     left:0;
     right:0
}

.circulars-filter-label{
position: static;
float:right;
margin-top:10px;
margin-bottom:20px
}

.circulars-filter-label select{
    width:80px;
}

.active-filter{
    background-color:grey
}

.circulars-filter-label:after{
    content: '\25BC';
    display: inline-block;
    box-sizing: border-box;
    color: #000;
    background-color: #fff;
    /* border: 1px solid #999; */
    margin-left: -22px;
    pointer-events: none;
    }



.circular-item-container i
{
    color: #b39732;
    display: block;
}
.circularModal iframe{

    height: 80vh !important;
}
.circular-item-main{
    cursor: pointer;
}
.modalFramePara{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
    font-family: Ubuntu-r;
}
@media only screen and (max-width: 576px)
{
    .actsSearch{
        margin-left: 0px;
    }

    .inputBox{
        text-align: center;
        margin-left: 0px;
    }
.circularModal iframe{

    height: 75vh !important;


}
.actsRegulation{
    margin-top: 45px;
}
.actsRegulation{
    font-size: 25px;
    font-family: Ubuntu-r;
}

.modalFramePara{
  
    font-size: 14px;

}

}