.admin_btn {
    background-color: #008ec2!important;
    height: 6vh;
    line-height: .5px!important;
    display: flex;
    align-items: center;
    font-size: unset;
    width: 132px;
    border: 1px solid #006799;
    justify-content: center;
    border-radius: 5px;
}
.navbar-dark{
    padding: 0 !important;
    box-shadow: none !important;
}
.media_input_div_modal{
    display: flex;
    /* padding: 10px; */
    justify-content: space-between;
}

.media_input_div_modal label{

margin: 0%;
    padding: 0% 5px !important;

    border-right: 1px solid #bfbfbf;

}


.edit-file-media{
    color:red;
    cursor:pointer
}


.edit-file-dialog-container{
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    z-index: 9999;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.edit-file-dialog-container .edit-file-dialog{
    border-radius: 10px;
    height: 200px;
    display: flex;
    width: 400px;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid black;
}

.edit-file-dialog-container .edit-file-dialog *{
    font-size:16px
}

.media_input_div_modal .thumbnail-file-input {

    height: 30px !important;
    margin: 10px !important;


}


.modal_top_padding{
    padding-top: 10px;
    padding-bottom: 10px;
}
.media_image_modal{
    padding-bottom: 10px;
    width: 100px;
    height: 100px;
    margin: 0px 10px;
}
.w-40{
    width: 100% !important;
}

.admin-button-style-margin{
    margin-right: 4px;
}