.search-banner{
    font-size: 22px;
}
.count-container{
    width: 100%;
    background-color: #d2d2d24a;
    padding-top: 2px;
    padding-bottom: 2px;
    color: #0074c5;
    box-shadow: 1px 1px 1px 1px #d2d2d2ba;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.search-image{
    width: 150px;
    height: auto;
    margin-right: 5%;
}
.search-row{
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #fff;
    margin: 15px 0%;
   

}
.search-row:hover{
    background-color:#f0f0f0cf;
    margin: 0px;
    padding: 15px 0%;
    cursor: pointer;

}
.search-row-container{
   
    align-items: center;
    justify-content: center;
    width: 100% ;
}
.offer_banner{
    margin: 15px;
    border: 1px solid #d2d2d2;
}
.search-row-container2{
    border-bottom: 1px solid #d2d2d2;
    align-items: center;
    justify-content: center;
    width: 90%;
    display: flex;
}
.search-subject-name{
    cursor: pointer;
font-weight: bold;
}
.search-image-vertical{
    width: 120px;
    height: auto;
    margin-right: 5%;
    padding: 2%;
}
.searchvertical2{
    font-size: 14px;
    color:#0074c5;
    cursor: pointer;
}
.search-subject-duration{
    font-size: 12px;
    margin-bottom: 0px;
}
.search-page-course-rating .star-ratings {
    position: unset;
    bottom: unset;
    left: unset;
    margin-right: 0px;
}
.search-page-course-rating {
    margin-bottom: 0px;
    opacity: 1;
    align-items: center;
}
@media only screen and (max-width: 1024px){


    .offer_banner {
    width: 250px !important;
    margin-left: -10%;
    }

    .search-image{
        width: 150px !important;
        height: auto;
        
    }

    .search-image-vertical {
        width: 150px !important;
        height: auto;
        
        margin-right: 5%;
        padding: 2%;
    }

    .search-banner{

        padding: 0px 25px;
    }
}

@media (max-width: 576px){


    .dashboard-para-img-mobile{
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .search-banner{
        font-size: 16px;
        padding: 0px 10px;
    }

    .offer_banner{
       width: 100px !important;
    }
    .search-page-course-rating {
       
        width: 100%;
       
        flex-direction: column;
        align-items: flex-start;
    }

    .search-image{
        width: 150px !important;
        height: auto;
        
    }

    .search-image-vertical {
        width: 90px !important;
    }

    .search-row-container {
    
        width: 100% !important;

}
.search-star-mobile{
    width: 50%;
}


.search-row-container2 {

    width: 100% !important;
    padding: 2% 5%;


}
}