.sweet-loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-c {
  width: 100%;
  padding: 0px 110px;
}
/* .swal-button {
  background-color: #0093d9 !important;
  color:white !important
} */
.bg-whitesmoke {
  background-color: whitesmoke;
}
.btn-primary.dropdown-toggle {
  box-shadow: none !important;
  width: 87%;
  display: flex;
  align-items: center;
}

.background-solid {
  background: #f5fcff;
}
button,
input:focus {
  outline: 0 !important;
}
@media (max-width: 992px) {
  .container-c {
    width: 100%;
    padding: 0px 40px;
  }
}
b,
strong {
  font-weight: bold;
  font-family: "Ubuntu-B";
}
@media (max-width: 768px) {
  .container-c {
    width: 100%;
    padding: 0px 40px;
  }
}

@media (max-width: 576px) {
  .container-c {
    width: 100%;
    padding: 0px 20px;
  }
}

#root {
}

body {
  font-family: "Ubuntu-R" !important;
  scrollbar-width: thin;
  scrollbar-color: grey #cecece;
}

.about-us-content {
  scrollbar-width: thin;
  scrollbar-color: grey #cecece;
}

.about-us-content::-webkit-scrollbar {
  width: 12px;
}
.about-us-content::-webkit-scrollbar-track {
  background: #cecece;
}
.about-us-content::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: 3px solid #cecece;
}

.about-us-content h2 {
  color: #0074c5;
}

.btn-custom {
  border: 0px;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.exams-content {
}

.exams-content h2 {
  font-size: 20px;
  color: #0074c5;
  font-weight: 600;
}

.exams-content p {
  font-size: 15.5px;
  color: #454545;
  font-weight: 300;
  line-height: 1.8;
  font-family: Ubuntu-L;
}

.exams-vertical-slide .slick-arrow {
  top: unset;
  background: none;
  opacity: 1;

  display: block;
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto;
}

.exams-vertical-slide .slick-arrow.slick-prev::before {
  display: block;
  transform: rotate(90deg) !important;
  top: 0px;
  left: 0;
  right: 0;
  position: relative;
  top: -10px;
}

.exams-vertical-slide .slick-arrow.slick-next::before {
  display: block;
  transform: rotate(90deg) !important;
  top: 0px;
  left: 0;
  right: 0 !important;
}

.exams-vertical-slide .test-series-item{
  width:275px !important;
  text-align:center
}

.exams-vertical-slide .tests-vertical .slick-list{
  height:250px !important;
}

@font-face {
  font-family: "Ubuntu-R";
  src: url("./Assets/Fonts/Ubuntu-R.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu-B";
  src: url("./Assets/Fonts/Ubuntu-B.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu-RI";
  src: url("./Assets/Fonts/Ubuntu-RI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu-L";
  src: url("./Assets/Fonts/Ubuntu-L.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu-M";
  src: url("./Assets/Fonts/Ubuntu-M.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu-LI";
  src: url("./Assets/Fonts/Ubuntu-LI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
