@font-face {
  font-family: "Ubuntu-R";
  src: url("../../Assets/Fonts/Ubuntu-R.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu-B";
  src: url("../../Assets/Fonts/Ubuntu-B.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu-RI";
  src: url("../../Assets/Fonts/Ubuntu-RI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

@media only screen and (min-width: 320px) {
  .socialButtonDiv {
    width: 100%;
  }
  .logoHeight-margin {
    margin-right: 0px;
  }
  .mainRegistration {
    background-color: #f9f9f9;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding-left: 6%;
    padding-top: 2%;
    padding-right: 6%;
    margin-top: 25%;
  }
  .mainRegistrationHeading {
    width: 95%;
  }

  .mainRegistrationHeading h2 {
    font-size: 20px;
    line-height: 25px;
    margin-top: 0%;
    font-family: Ubuntu-b;
    margin-bottom: 0.8%;
    color: #4a4a4a;
    letter-spacing: 1px;
  }

  .mainRegistrationHeading p {
    font-size: 14px;
    line-height: 25px;
    margin-top: 0%;
    font-family: Ubuntu-ri;
    margin-bottom: 0.8%;
    color: #4a4a4a;
    letter-spacing: 1px;
  }

  .RegistrationInputDiv1 {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
  .RegistrationLableAndType {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2% 2% 2% 2%;
    justify-content: space-between;
  }

  .RegistrationCheckBox {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    /* align-content: center; */
    margin-top: 1%;
  }
  .RegistrationCheckBoxhr {
    margin-top: 1%;
    border-top: 1px solid #e5e5e5 !important;
  }

  .RegistrationButtonMain {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 2% 0% 3%;
  }

  .RegistrationButtonLogIn {
    width: 100%;
  }

  .RegistrationButton {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
  }

  .RegistrationButton1 {
    display: flex;
    margin-right: 2%;
    font-size: 10px;
  }
  .RegistrationButton2 {
    display: flex;
    font-size: 10px;
  }

  .ButtonReset {
    width: 50px;
    height: 30px;
    border-radius: 5px;
    background-color: #dbaa34;
    border: 1px solid #dbaa34;
    text-align: center;
    color: #fff0e9;
  }
  .ButtonConfirm {
    width: 50px;
    height: 30px;
    border-radius: 5px;
    background-color: #0093d9;
    border: 1px solid #0093d9;
    text-align: center;
    color: #fff0e9;
  }

  .RegistrationInputDiv2 {
    display: flex;
    flex-direction: column;

    width: 100%;
  }

  .RegistrationInputLable {
    width: 40%;
  }
  .RegistrationInputTypeDiv {
    display: flex;
    width: 65%;
  }
  .RegistrationInputTypeDiv1 {
    display: flex;
    width: 65%;
  }

  .RegistrationInputType:focus {
    border: 1px solid #eeeeee !important;
  }

  .RegistrationInputType {
    width: 100% !important ;
    height: 35px !important ;
    border-radius: 2px;
    transition: all 0.3s ease-in-out !important;
    -moz-transition: all 0.3s ease-in-out !important;
    -ms-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important ;
    outline: none;
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid #e5e5e5;
  }

  .cross_icon {
    position: relative;
    top: 0;
    right: 0;
  }

  .UploadInputType {
    width: 100% !important ;
    height: 35px !important ;
  }

  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #4a4a4a;
    display: inline-block;
    padding: 2% 0% 2% 0%;
    text-align: center;
    cursor: pointer;
    background-color: #4a4a4a;
    color: #fff0e9;
    border-radius: 5px;
    width: 62%;
    height: 35px;
  }

  input[type="checkbox"] {
    color: #0093d9;
  }

  .dateInput {
    margin-bottom: 0%;
    padding-bottom: 0%;
    margin-left: -1%;
  }

  /* 
input::-webkit-calendar-picker-indicator {
display: none;
} */

  .dateDiv {
    flex-direction: column;
    width: 66%;
  }

  .dateDiv2 {
    margin-bottom: 2%;
  }

  .RegistrationButtonLogIn h1 {
    font-size: 26px;
    line-height: 28px;
    font-weight: 400;
  }
  .RegistrationInputLable1 {
    margin-right: 5%;
  }
  .ForgetPasswordText {
    font-family: Ubuntu-ri;
    color: #4a4a4a;
    letter-spacing: 0.2px;
  }

  .lableText {
    font-family: Ubuntu-r;
    color: #4a4a4a;
    font-size: 14px;
  }

  .checkboxText {
    font-family: Ubuntu-r;
    color: #4a4a4a;
    font-size: 14px;
  }
  .RegistrationText {
    font-family: Ubuntu-r;
    color: #4a4a4a;
    font-size: 14px;
  }
  .RegistrationTextColor {
    font-family: Ubuntu-r;
    color: #0093d9;
    font-size: 14px;
  }

  [type="date"] {
    text-transform: uppercase;
    padding-left: 3%;
    padding-right: 10%;
    opacity: 0.5;
    font-family: Ubuntu-r;

    font-size: 16px;
    /* background:#FAFAFA url(../../Assets/images/calendar.png)  97% 50% no-repeat !important; */
    background-size: 25px !important;
    color: #8080807a;
  }
  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  [type="number"]::-webkit-inner-spin-button {
    display: none;
  }

  [type="date"]::-webkit-calendar-picker-indicator {
    width: 10%;
    height: 100%;
    opacity: 0;
  }
  [type="text1"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  [type="text1"] {
    background: #fafafa url(../../Assets/images/down.png) 97% 50% no-repeat;
  }

  [type="email"] {
    background: #fafafa url(../../Assets/images/mail.png) no-repeat scroll;
    padding-left: 40px !important;
    background-position: 5% !important;
  }
  [type="email"]::-webkit-input-placeholder {
    padding-left: 2%;
    opacity: 0.2;
    font-family: Ubuntu-r;
    font-size: 16px;
  }

  [type="number"]::-webkit-input-placeholder {
    padding-left: 2%;
    opacity: 0.2;
    font-family: Ubuntu-r;

    font-size: 16px;
  }

  #create-course-form [type="password"] {
    background: #fafafa url(../../Assets/images/Password.png) no-repeat scroll;
    padding: 0px 3px 0px 3px !important;
    background-position: 5% !important;
  }
  [type="password"] {
    background: #fafafa url(../../Assets/images/Password.png) no-repeat scroll;
    padding-left: 40px !important;
    background-position: 5% !important;
  }
  [type="text"]::-webkit-input-placeholder {
    padding-left: 2%;
    opacity: 0.2;
    font-family: Ubuntu-r;

    font-size: 16px;
  }
 
  [type="password"]::-webkit-input-placeholder {
    padding-left: 2%;
    opacity: 0.2;
    font-family: Ubuntu-r;

    font-size: 16px;
  }
  [type="text1"]::-webkit-input-placeholder {
    padding-left: 2%;
    opacity: 0.2;
    font-family: Ubuntu-r;

    font-size: 16px;
  }
  [type="text"]:focus::-webkit-input-placeholder {
    color: transparent;
  }
  [type="text1"]:focus::-webkit-input-placeholder {
    color: transparent;
  }
  [type="password"]::-webkit-input-placeholder {
    padding-left: 2%;

    font-family: Ubuntu-r;

    font-size: 16px;
  }
  [type="password"]:focus::-webkit-input-placeholder {
    color: transparent;
  }
  [type="email"]::-webkit-input-placeholder {
    padding-left: 2%;

    font-family: Ubuntu-r;

    font-size: 16px;
  }
  [type="email"]:focus::-webkit-input-placeholder {
    color: transparent;
  }

  /* .vsMobView{
  display: block;
} */

  .windowView1 {
    display: none;
  }

  .socialIconbutton1 {
    width: 80%;
    margin: 1% 0% 2% 10%;
    height: 45px;
    border-radius: 5px;
    color: #fff;
    background-color: #1a538a;
    border: 0.5px solid #1a538a;
  }

  .socialIconbutton2 {
    width: 80%;
    margin: 1% 0% 2% 10%;
    height: 45px;
    border-radius: 5px;
    color: #fff;
    background-color: #fff;
    border: 0.5px solid #e5e5e5;
  }

  .forgetPassword {
    margin: 1% 0% 2% 26%;
  }
  .sweet-loading-main {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dontHaveAccount {
    margin: 10% 0% 5% 20%;
  }
  .container {
    padding-left: 4px;
  }
}

@media only screen and (min-width: 768px) {
  .mainRegistration {
    background-color: #f9f9f9;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding-left: 6%;
    padding-top: 2%;
    margin-top: 10%;
  }

  .custom-file-upload {
    border: 1px solid #4a4a4a;
    display: inline-block;
    padding: 1% 0% 2% 0%;
    text-align: center;
    cursor: pointer;
    background-color: #4a4a4a;
    color: #fff0e9;
    border-radius: 5px;
    width: 37%;
    height: 35px;
  }
  .ButtonConfirm {
    width: 75px;
    height: 30px;
    border-radius: 5px;
    background-color: #0093d9;
    border: 1px solid #0093d9;
    text-align: center;
    color: #fff0e9;
  }

  .ButtonReset {
    width: 75px;
    height: 30px;
    border-radius: 5px;
    background-color: #dbaa34;
    border: 1px solid #dbaa34;
    text-align: center;
    color: #fff0e9;
  }
}
@media only screen and (min-width: 900px) {
  .mainRegistration {
    background-color: #f9f9f9;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding-left: 6%;
    padding-top: 2%;
    margin-top: 0%;
  }
  .logoHeight-margin {
    margin-right: 35px;
  }

  .forgetPassword {
    margin: 1% 0% 2% 38%;
  }

  .dontHaveAccount {
    margin: 10% 0% 5% 30%;
  }
  .socialIconbutton1 {
    width: 80%;
    margin: 6% 10% 2% 10%;
    height: 45px;
    border-radius: 5px;
    color: #fff;
    background-color: #1a538a;
    border: 0.5px solid #1a538a;
  }

  .socialIconbutton2 {
    width: 80%;
    margin: 0% 10% 5% 10%;
    height: 45px;
    border-radius: 5px;
    color: #fff;
    background-color: #fff;
    border: 0.5px solid #e5e5e5;
  }

  .vsMobView {
    display: none;
  }
  .windowView1 {
    display: block;
  }
}
@media only screen and (min-width: 1100px) {
}

@media only screen and (min-width: 1300px) and (min-height: 525px) {
  .mega-menu-content {
    background: #ffffff;
    padding: 16px;
    border: 1px solid #ccc;
    position: absolute;
    top: 30px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 6px,
      rgba(0, 0, 0, 0.11765) 0px 1px 4px;
    margin-top: 4%;
    width: 216vh;
    height: 51vw;
    margin-left: -1466%;
    /* opacity: 1; */
    z-index: 1;
  }

  .mainRegistrationHeading {
    width: 95%;
  }

  .mainRegistrationHeading h2 {
    font-size: 25px;
    line-height: 25px;
    margin-top: 0%;
    font-family: Ubuntu-r;
    margin-bottom: 0.8%;
    color: #4a4a4a;
    letter-spacing: 1px;
    font-weight: 600;
  }
  .mainRegistrationHeading p {
    font-size: 14px;
    line-height: 18px;
    margin: 0%;
    font-family: Ubuntu-ri;
    color: #4a4a4a;
    letter-spacing: 0.2px;
  }

  .RegistrationForm {
    background-color: #fff;
    display: flex;
    width: 100%;
    height: auto;
    border: 1px solid #e5e5e5;
    margin-top: 1%;
    border-radius: 5px;
  }
  .RegistrationCheckBox {
    width: 100%;
    margin-top: 1%;
  }
  .RegistrationCheckBoxhr {
    margin-top: 1%;
    border-top: 1px solid #e5e5e5 !important;
  }

  .RegistrationButtonMain {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 2% 0% 3%;
  }

  .RegistrationButtonLogIn {
    width: 50%;
  }

  .RegistrationButton {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
  }

  .RegistrationButton1 {
    display: flex;
    margin-right: 2%;
    font-size: 16px;
  }
  .RegistrationButton2 {
    display: flex;
    font-size: 16px;
  }

  .ButtonReset {
    width: 160px;
    height: 40px;
    border-radius: 5px;
    background-color: #dbaa34;
    border: 1px solid #dbaa34;
    text-align: center;
    color: #fff0e9;
  }

  .ButtonReset:hover {
    background-color: rgb(185, 109, 28);
  }
  .ButtonConfirm {
    width: 160px;
    height: 40px;
    border-radius: 5px;
    background-color: #0093d9;
    border: 1px solid #0093d9;
    text-align: center;
    color: #fff0e9;
  }

  .ButtonConfirm:hover {
    background-color: rgb(43, 127, 167);
  }

  .RegistrationInputDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
  }

  .RegistrationInputDiv1 {
    display: flex;
    flex-direction: column;

    margin: 2% 3% 3.5% 4%;
    width: 50%;
  }

  .RegistrationLableAndType {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2.5%;
  }

  .RegistrationInputDiv2 {
    display: flex;
    flex-direction: column;

    margin: 2% 4% 2% 5%;
    width: 50%;
    float: left;
  }

  .RegistrationInputLable {
    width: 40%;
    margin-right: 5%;
  }
  .RegistrationInputTypeDiv {
    display: flex;
    width: 80%;
  }
  .RegistrationInputTypeDiv1 {
    display: flex;
    width: 80%;
  }

  .RegistrationInputType:focus {
    border: 1px solid #eeeeee !important;
  }

  .RegistrationInputType {
    width: 100% !important ;
    height: 35px !important ;
    border-radius: 5px;
    transition: all 0.3s ease-in-out !important;
    -moz-transition: all 0.3s ease-in-out !important;
    -ms-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important ;
    outline: none;
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid #e5e5e5;
  }

  .cross_icon {
    position: relative;
    top: 0;
    right: 0;
  }

  .UploadInputType {
    width: 100% !important ;
    height: 35px !important ;
  }

  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #4a4a4a;
    display: inline-block;
    padding: 2% 0% 2% 0%;
    text-align: center;
    cursor: pointer;
    background-color: #4a4a4a;
    color: #fff0e9;
    border-radius: 5px;
    width: 42%;
    height: 40px;
  }

  input[type="checkbox"] {
    color: #0093d9;
  }

  .dateInput {
    margin-bottom: 0%;
    padding-bottom: 0%;
    margin-left: -1%;
  }

  /* 
input::-webkit-calendar-picker-indicator {
    display: none;
  } */

  .dateDiv {
    flex-direction: column;
    width: 79%;
  }

  .dateDiv2 {
    margin-bottom: 2%;
  }

  .RegistrationButtonLogIn h1 {
    font-size: 26px;
    line-height: 28px;
    font-weight: 400;
  }
  .RegistrationInputLable1 {
    margin-right: 5%;
  }
  .ForgetPasswordText {
    font-family: Ubuntu-ri;
    color: #4a4a4a;
    letter-spacing: 0.2px;
  }

  .lableText {
    font-family: Ubuntu-r;
    color: #4a4a4a;
    font-size: 16px;
  }

  .checkboxText {
    font-family: Ubuntu-r;
    color: #4a4a4a;
    font-size: 16px;
  }
  .RegistrationText {
    font-family: Ubuntu-r;
    color: #4a4a4a;
    font-size: 20px;
  }
  .RegistrationTextColor {
    font-family: Ubuntu-r;
    color: #0093d9;
    font-size: 20px;
  }

  [type="date"] {
    text-transform: uppercase;
    padding-left: 3%;
    opacity: 0.5;
    font-family: Ubuntu-r;

    font-size: 16px;
    background: #fafafa url(../../Assets/images/cal.png) 97% 50% no-repeat;
  }
  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  [type="date"]::-webkit-calendar-picker-indicator {
    width: 10%;
    height: 100%;
    opacity: 0;
  }
  [type="text1"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  [type="text1"] {
    background: #fafafa url(../../Assets/images/down.png) 97% 50% no-repeat;
  }

  [type="text"]::-webkit-input-placeholder {
    padding-left: 2%;
    opacity: 0.2;
    font-family: Ubuntu-r;

    font-size: 16px;
  }
  [type="text1"]::-webkit-input-placeholder {
    padding-left: 2%;
    opacity: 0.2;
    font-family: Ubuntu-r;

    font-size: 16px;
  }

  .RegistrationProfessionalQualificationDiv {
    margin-top: -1%;
  }

  .RegistrationDivDate {
    margin-top: 6%;
  }
}

@media only screen and (min-width: 1300px) and (min-height: 668px) {
  .mega-menu-content {
    background: #ffffff;
    padding: 16px;
    border: 1px solid #ccc;
    position: absolute;
    top: 30px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 6px,
      rgba(0, 0, 0, 0.11765) 0px 1px 4px;
    margin-top: 4%;
    width: 216vh;
    height: 51vw;
    margin-left: -1466%;
    /* opacity: 1; */
    z-index: 1;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1800px) {
}

@media only screen and (min-device-width: 480px) and (max-device-width: 640px) and (orientation: landscape) {
}

.login-modal {
  width: 100%;
  border-radius: 5px;
}

.dob-input-container .dateDiv1 .dateInput {
  color: #6f6f6f !important;
  padding-left: 10px;
  padding-right: 5px;
  opacity: 1;
}

.file-size-limit {
  font-size: 12px;
  font-family: Ubuntu-L;
}
