


.lesson-mobile-header{
  padding:15px 10px;
  background-color:white
}

.haderProggressBar>div{
  margin-top: 0% !important;
  position: relative !important;
  width: 37px;
}




#lesson-header{
  width:100%;

}

.TrophyProgressbar{
  position: relative;
  bottom: 5px;
}




/* .ratingChangedDiv_ReviewInput{
  margin: 5% 5% 5% 5%;
} */
.textArea_ReviewInput{
  width: 350px !important;
}
.exam-input-column{
  padding: 0% !important;
}
.iconPlus{
  font-size: 10px;
  margin-top: 1px;
}
.renderLogin-4{
  padding: 0% !important;
}

.YourProgressBtn{
  padding: 0% !important;
}
.icon-div-lesson {
  width: 100% !important;
}
.wishlist-img-color{

  color:#FE8400;
}
.dropbtn {
  background-color: #fff;
  color: #4A4A4A;

  border: none;
}

.dropdown {
  position: absolute;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  width: 100px;
  text-align: center;


  z-index: 1;
}

.dropdown-content a {
  color: black;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #fff;}

#lesson-header .headerToggleMenu{
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  border-top: 1px solid #e8e9eb;
  box-shadow: 0 4px 16px rgba(20,23,28,.25);
  color: #505763;
  font-size: 16px;
  margin: 0;
  min-height: 30vh;
  z-index:99999;
  background-color: white;
  min-width: 250px;
  right: 0%;
  display: flex;
  flex-direction: column;
  padding-left:10px;
  padding-top: 20px;
  font-weight: 400;
  margin-top: 15px;
}


@media only screen and (min-width: 320px)   {
   .lesson-mobile-header .headerToggleMenuProgress{
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    border-top: 1px solid #e8e9eb;
    box-shadow: 0 4px 16px rgba(20,23,28,.25);
    color: #505763;
    font-size: 16px;
    margin: 0;
    min-height: 5vh;
    z-index: 13;
    background-color: white;
    min-width: 75px;
    /* right: 28%; */
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    font-weight: 400;
    margin-top: 15px;
    text-align: initial;
    left: 0;
    padding: 2%;
    /* left: 20%; */
    padding-left: 10px;
    top: 25px;
    width: 241px;
}

#header-mobile-nav1{
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #0093D9;
  overflow-x: hidden;
  padding-top: 20px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
}


#header-mobile-nav1 button {
  background-color: transparent;
  border: 0px;
  font-size: 16px;
  /* justify-content: space-between; */
  display: flex;
  text-align: left;
  color: white;
  align-items: center;
  padding: 0px 0px 0px 20px !important;
  cursor: pointer;
  font-family: Ubuntu-L;
  margin-bottom: 25px;
  text-transform: capitalize;
}


#header-mobile-nav1 .close-mob-menu-button{
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 36px;
}


  .YourProgressBtn{
    display:none
  }


    .lesson-mobile-header{
      display:block;
    }


    .lesson-mobile-header .TrophyProgressbar{
      position: relative;
      bottom: -5px;
    }
  
    .lesson-mobile-header .col-8 > div{
      width:50px;
      margin-left:auto;
      margin-right:20px
    }
  

  #lesson-header .container-c{
    padding:0px 15px;
  }

  .header-lesson-logo{
    width:40px;
}
.menu-toggle > img{
    width:35px;
}

#account-mobile-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #0093D9;
    overflow-x: hidden;
    padding-top: 20px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    top: 65px;
    
}



.haderProggressBar{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-right:0px
}

    
}

@media only screen and (min-width: 900px)   {

 
  .lesson-mobile-header{
      display:none;
    }

 .mobile-header{
     display:none
   }

  #lesson-header .container-c{
    padding:0px 40px;
  }

  .haderProggressBar{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-right:30px
  }

  #lesson-page .user-img{
    display:unset
  }

  .haderRatingChanged{
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-around;
    align-items: center;
    margin-right: 12px;
  }
 

  .headerToggleMenuProgress{
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    border-top: 1px solid #e8e9eb;
    box-shadow: 0 4px 16px rgba(20,23,28,.25);
    color: #505763;
    font-size: 16px;
    margin: 0;
    min-height: 5vh;
    z-index: 13;
    background-color: white;
    min-width: 75px;
    /* right: 28%; */
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    font-weight: 400;
    margin-top: 15px;
    text-align: initial;
    left: -188px;
    padding: 2%;
    /* left: 20%; */
    padding-left: 10px;
    top: 25px;
    width: 241px;
}
.headerToggleMenuProgress:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }
  .headerToggleMenuProgress:before {
    right: 19px;
    border: 11px solid transparent;
    border-bottom-color: #fff;
  }
  .headerToggleMenuProgress:after {
    left: 20px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
  }

  
  .headerToggleMenuLesson{
    
      border: none;
      border-radius: 0;
      border-bottom-left-radius: 2px;
      border-top: 1px solid #e8e9eb;
      box-shadow: 0 4px 16px rgba(20,23,28,.25);
      color: #505763;
      font-size: 16px;
      margin: 0;
      min-height: 30vh;
      z-index: 13;
      background-color: white;
      min-width: 250px;
      right: 3%;
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      font-weight: 400;
      margin-top: 11px;
  }
  
  .headerToggleMenuLesson:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }
  .headerToggleMenuLesson:before {
    right: 19px;
    border: 11px solid transparent;
    border-bottom-color: #fff;
  }
  .headerToggleMenuLesson:after {
    left: 20px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
  }



  .YourProgressBtn{
    display:block;
    background-color: #fff;
  border: none;
  width: 111px;
  }
  .LeaveArating{
    width: 150px;
    margin-left: 6%;
  }

  /* .TrophyProgressbar{
    margin-top: -60%;
  } */


.ratingChangedDiv{
 
  width: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  min-height: 200px;

}




.ratingChangedDiv_Select_Rating{
  margin-top: 2%;
}

.ratingChangedDiv_StarsRating{
  margin-top: 5%;
}
.ratingChangedButtonDiv{
  margin-top: 10%;
  margin-bottom: 5%;
 }

 .percentage-menu-list{
  padding: 0% 0% 0% 0%;
}

 

}

@media only screen and (min-width: 1100px)   {

  #lesson-header .container-c{
    padding:0px 45px;
  }

  .haderProggressBar{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-right:30px
  }

  .haderRatingChanged{
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: space-around;
    align-items: center;
    margin-right: 13px;
  }

  /* .TrophyProgressbar{
    margin-top: -54%;
  } */
  .headerToggleMenuProgress{
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    border-top: 1px solid #e8e9eb;
    box-shadow: 0 4px 16px rgba(20,23,28,.25);
    color: #505763;
    font-size: 16px;
    margin: 0;
    min-height: 5vh;
    z-index: 13;
    background-color: white;
    min-width: 75px;
    /* right: 28%; */
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    font-weight: 400;
    margin-top: 15px;
    text-align: initial;
    left: -188px;
    padding: 2%;
    /* left: 20%; */
    padding-left: 10px;
    top: 25px;
    width: 241px;
}
.YourProgressBtn{
  background-color: #fff;
border: none;
width: 125px;
font-size: 12px;
text-align: initial;
margin-left: 6%;
}
.LeaveArating{
  width: 150px;
  margin-left: 6%;
}
  
}




@media only screen and (min-width: 1300px)  and (min-height:525px){   
/* 
  .TrophyProgressbar{
    margin-top: -48%;
  } */

  #lesson-header .container-c{
    padding:0px 85px;
  }

  .LeaveArating{
    width: 150px;
    margin-left: 6%;
  }
 }  





@media only screen and (min-width: 1300px)  and (min-height:668px){   
  
  #lesson-header .container-c{
    padding:0px 85px;
  }

  /* .TrophyProgressbar{
    margin-top: -50%;
  } */
  }



@media only screen  and (min-width : 1800px)  {

  #lesson-header .container-c{
    padding:0px 85px;
  }

  /* .TrophyProgressbar{
    margin-top: -32%;
  } */
 
}

 

@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 640px) 
                   and (orientation: landscape) {


}


.header{
  height:80px;
  background-color: white;
}

.header-lesson-logo{
    width:90px;
}


.exam-toggle-lesson{
  margin-left: 35px;
}
.search-icon-header-lesson{
  right: 13px !important;
}

.haderHeartFav{

  display: flex;
  flex-direction: row;
  width: 10%;
  justify-content: space-around;
  align-items: center;
}



.haderUserDP{
  
  display: flex;
  flex-direction: row;
  width: 15%;
  justify-content: space-around;
  align-items: center;

}

.haderMenu-toggle{
  display: flex;
  flex-direction: row;
  width: 15%;
  justify-content: space-around;
  align-items: center;
}



.CircularProgressbar{
  height: 45px;
}



.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }




  .close-mob-menu-button img{
    width:25px;
  }


  
.accounts-container{
    background-color: white;
  }
  
  
  .account-navigation-container{
    background-color: #0093d9;
    border-bottom-left-radius: 5px;
  }
  
  
  .user-image-container{
    width: 90px;
    margin: 0 auto;
    height: 90px;
    border-radius: 50%;
    margin-top: 30px;
  }
  .user-image{
  
    width: 86px;
    margin: 0 auto;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .navigation-options-container > div{
    display:flex;
    flex-direction: column;
    padding:40px 0px;
  }
  
  .navigation-options-container button{
    background-color:transparent;
    color:white;
    margin: 2px 0px;
  }

  .dropdown .menu-subitems {
    background-color: white !important;
}
  
  .navigation-options-container .btn .active{
    background-color:#1A9EDE !important;
  }
  
  .navigation-options-container{
    display: flex;
    flex-direction: column;
  }
  
  .navigation-options-container .navigation-button{
    background-color: transparent;
    border: 0px;
    font-size: 16px;
    justify-content: space-between;
    display: flex;
    text-align: left;
    color: white;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    font-family: Ubuntu-L;
  }
  
  .bg-active{
    background-color:#1A9EDE !important;
  }
  
  
  .user-name, .user-email{
    color:white;
  }
  
  .user-name{
    font-family: 'Ubuntu-M';
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  
  .user-email{
    font-family:'Ubuntu-LI';
    font-size:12px;
  }
  
  
  .arrow-image{
    float:right;
  }
  
    /* .image-change-button{
  
  } */



 .container-lessonHeader{
  padding: 0px 5%;
 }









 @media(max-width:768px) and (min-width:576px){
     .header-lesson-logo{
         width:80px;
     }

     .menu-toggle > img{
         width:35px;
     }
 }


 /* @media(max-width:576px){
    .header-logo{
        width:60px;
    }
    .menu-toggle > img{
        width:35px;
    }

    #account-mobile-nav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: #0093D9;
        overflow-x: hidden;
        padding-top: 20px;
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        top: 65px;
    }

} */

.search-icon-header{
  position: absolute;
  right: 40px;
    top: 7px;
    cursor:pointer;
}

.search-input{
  width: 80%;
  background-color: whitesmoke;
  float: right;
  border-radius: 6px !important;
}


.header .row .exam-input-column{
  display:flex;
  justify-content: space-around;
}

.header .row .exam-input-column button{
background-color: transparent;
border:0px;
cursor:pointer;
}
.header .row .exam-input-column #exam-toggle-text{
font-family: Ubuntu-L;
font-size:16px;
color:#333333;
  }



  #header-mobile-nav1 .mobile-menu-items-divider{
    border: 0.3px solid #b7b7b74d;
    /* width: 100%; */
    padding: 0px 10px;
    margin: 3px 10px;
  }


  .header .row .col-5 .menu-toggle{
    background-color: transparent;
    border: 0px;
  }

  .header .row .col-3 .menu-toggle:focus{
    background-color: transparent;
    border: 0px !important;
  }


  .header .row .col-7 .header-search {
    width: 80%;
    background-color: whitesmoke;
    float: right;
    border-radius: 6px !important;
  }

