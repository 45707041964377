/* @media only screen and (min-width: 320px){
    .newsDateDisplay{
        display: block;
    }
    .newsDate{
        width: auto;
        height: 10vw;
        background-color: #ff8400;
    
        display: flex;
        justify-content: space-evenly;
 
        color: white;
        font-size: initial
    }
  
    .rotateText {
        font-size: 16px;
        padding: 0px;
        transform: rotate(-90deg);
    }
    .NewsDivWidth{
        width: 100%
    }

    
    } */


    .related-subjects-caselaw a{
    text-decoration: underline;
    color: black;
    }


    .bg-newsblue{
   
            background-color:#07a9fc !important
         
    }
    @media  screen and (min-width:320px){
        .newsDivPosition{
            display: flex;
       
            align-items: center;
            padding: 15px;
            height: auto;
            border: 1px solid #dee2e6;
            border-radius: 10px;
            margin: 10px 20px;
            height: auto;
        }

        .News-heading{


            align-items: center;
            background-image: url(../../Assets/images/Inner-Banner.jpg);
            background-repeat: no-repeat;
            background-size:cover;
            height: 200px;
margin-top: 15%;
            width: 100%;
       

        
            background-color:transparent;


        }

        .news_justify_content{
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
        }
        
        .newsText{
            font-size: 14px;
           
        }
        .newsHeadingFont{
            color: #303030;
            font-family: 'Ubuntu-b';
         
            font-size: 18px;
        }
       

    .newsDateDisplay{
        display: flex;
        flex-direction: column;
     
    }
    .newsDate{
     width: auto;
     height: auto;
     background-color: #dbaa34;
     border-radius: 5px;
  
     display: flex;
     flex-direction: column;
     justify-content: center;
  
     color: white;
   
     padding: 5px;
 }



 .newsYear{
     margin-top: 5px;
    width: auto;
    height: auto;
    background-color: #4A4A4A;
    border-radius: 5px;
 
    display: flex;
    flex-direction: column;
    justify-content: center;
 
    color: white;
    padding: 2px 0px 2px 8px;


}
 
 .rotateText {

    font-size: 14px;
    font-weight: bold;
    transform: rotate(-90deg);
    color: #ff8400;


 }
 .NewsDivWidth{
     width: 30%
 }

 .NewsDivWidth-title-description{


    width: 100%;
    margin-top: 5px;
    margin-left: 0px;
 }
.newsText .p{
    margin-bottom: 0px;
}
 .newsDate_font{
     font-size: 40px;
     text-align: center;
     font-family: 'Ubuntu-b';
 }
 .newsMonth_font{
    font-size: 20px;
    text-align: center;
    font-family: 'Ubuntu-r';
}

.newsYear_font{

    font-size: 15px;
   
    font-family: 'Ubuntu-r';
    letter-spacing: 7px;
    text-align: center;
}

 .exam-news-heading-font{

    font-size: 40px;
    font-family: Ubuntu-r;
    font-weight: 600;


    color: #fff;

 }



 .news-tab-btn-div{


    width: 100%;
    justify-content: center;

 }

 .btn-news{
    width: 320px;
    height: 40px;

    background-color: #1fb1eb;
    border: 1px solid #1fb1eb;
    margin-left: 2px;
    margin-top: 5px;
    color: #FFF0E9;
    



 }

.btn-news:focus{

    background-color: #fff;
    border: none;
    border-top: 1px solid #dee2e6;
    color: #4A4A4A;
}


 .btn-news-text{

    font-size: 14px;
    font-family: Ubuntu-r;
  
    text-align: center;
 }

 .btn-news-half{

    width: 100px;
    height: 40px;

    background-color: #1fb1eb;
    border: 1px solid #1fb1eb;
    margin-left: 2px;
    margin-top: 5px;
    color: #FFF0E9;

 }

     }
    @media  screen and (min-width:900px){
        .newsDivPosition{
            display: flex;
       
            align-items: center;
            padding: 30px;
            height: auto;
            border: 1px solid #dee2e6;
            border-radius: 10px;
            margin: 40px 90px;
            height: auto;
        }

        .News-heading{


            align-items: center;
            background-image: url(../../Assets/images/Inner-Banner.jpg);
            background-repeat: no-repeat;
            background-size:cover;
            height: 200px;
margin-top: 0%;
            width: 100%;
       

        
            background-color:transparent;


        }

        .news_justify_content{
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 100%;
        }
        
        .newsText{
            font-size: 16px;
           
        }
        .newsHeadingFont{
            color: #303030;
            font-family: 'Ubuntu-b';
         
            font-size: 25px;
        }
       

    .newsDateDisplay{
        display: flex;
        flex-direction: column;
     
    }
    .newsDate{
     width: auto;
     height: auto;
     background-color: #dbaa34;
     border-radius: 5px;
  
     display: flex;
     flex-direction: column;
     justify-content: center;
  
     color: white;
   
     padding: 5px;
 }

 .newsYear{
     margin-top: 5px;
    width: auto;
    height: auto;
    background-color: #4A4A4A;
    border-radius: 5px;
 
    display: flex;
    flex-direction: column;
    justify-content: center;
 
    color: white;
    padding: 2px 0px 2px 8px;


}
 
 .rotateText {

    font-size: 14px;
    font-weight: bold;
    transform: rotate(-90deg);
    color: #ff8400;


 }
 .NewsDivWidth{
     width: 10%
 }

 .NewsDivWidth-title-description{


    width: 88%;
    margin-top: 15px;
    margin-left: 25px;
 }

 .newsDate_font{
     font-size: 60px;
     text-align: center;
     font-family: 'Ubuntu-b';
 }
 .newsMonth_font{
    font-size: 30px;
    text-align: center;
    font-family: 'Ubuntu-r';
}

.newsYear_font{

    font-size: 20px;
   
    font-family: 'Ubuntu-r';
    letter-spacing: 7px;
    text-align: center;
}

 .exam-news-heading-font{

    font-size: 40px;
    font-family: Ubuntu-r;
    font-weight: 600;


    color: #fff;

 }



 .news-tab-btn-div{


    width: 100%;
    justify-content: center;

 }

 .btn-news{
    width: 320px;
    height: 40px;

    background-color: #1fb1eb;
    border: 1px solid #1fb1eb;
    margin-left: 2px;
    margin-top: 5px;
    color: #FFF0E9;
    



 }

.btn-news:focus{

    background-color: #fff;
    border: none;
    border-top: 1px solid #dee2e6;
    color: #4A4A4A;
}


 .btn-news-text{

    font-size: 14px;
    font-family: Ubuntu-r;
  
    text-align: center;
 }

 .btn-news-half{

    width: 100px;
    height: 40px;

    background-color: #1fb1eb;
    border: 1px solid #1fb1eb;
    margin-left: 2px;
    margin-top: 5px;
    color: #FFF0E9;

 }

     }

@media only screen and (min-width: 1100px){
   
     }
 @media only screen and (min-width: 1300px)  and (min-height:525px){
   
   }
 
@media only screen and (min-width: 1300px)  and (min-height:668px){
  

}
 @media only screen and (min-width: 1800px){
  
 
  }