.report{
    position:fixed;
    top: 40%;
    left: 40%;
    width:30em;
    height:12em;
    /* margin-top: -9em; set to a negative number 1/2 of your height */
    /* margin-left: -15em; set to a negative number 1/2 of your width */
    border: 1px solid #ccc;
    background-color: #f3f3f3;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content{
    text-align: center;

}
#order-list-page{
    width:100%;
    overflow:hidden;
}

#order-list-page .order-list-column{
    width:100%;
}

#order-list-page .pages_table tbody tr td:first-child{
    display: flex;
    flex-direction: column;
    align-items: start;
}

#order-list-page .pages_table tbody tr td:first-child button{
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-top:30px;
    color:blue
}


#order-list-page  .product-order-details-container{
    border: 1px solid black;
    padding: 12px 50px;
    margin: 10px 40px 0px;
}

.product-order-details-container hr{
    border:0.5px solid grey !important;
    width:100%;
    margin: 25px 0px;
}

#order-list-page .package-name-column{

}

#order-list-page .purchase-date-amount-column{
    display:flex;
    justify-content: space-between;
}

#order-list-page .status-column{

}

#order-list-page .transaction-column{
display: flex;
justify-content: space-between;
}

#order-list-page .refund-column{

}

.order-details-button{
    color:blue !important;
    text-decoration: underline;
    font-size:14px
}


#order-list-page #__range-picker-container .full-date-picker-container {
    padding:30px;
    height:550px !important;
}

#order-list-page .default-footer .buttons .today {
  visibility: visible;
}