.about-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../Assets/images/Inner-Banner.jpg");
  height: 200px;
  font-size: 40px;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  color: white;
  justify-content: center;
}
.about-us-content-container{
  line-height: 30px;
  letter-spacing: 0.6px;
}
.about-us-heading{
    font-family: Ubuntu-r;

    color: white;
    font-weight: 600;

}
.productMainFirstText {
  font-weight: 500;
  font-size: 12px;
  font-family: Ubuntu-r;
  color: #5A5A5A;
  word-spacing: 1;
  margin-left: 110px;
}
.about-main-description {
    
    height: 200px;
    width: 100%;
    font-family: Ubuntu-m;
    font-size: 18px;
    padding: 0 20px;
    text-align: center;
    margin: 20px 0px;
  }
 

  .about-faculty{
    background-color: #f2fbff;

    
  
    padding:50px;
    margin-top: -50px;

  }
  .about-us-sub-heading-text{
   
    font-size:30px;
    font-family: Ubuntu-m;
    margin-bottom: 20px;

  }
  .faculty{
      flex-direction: column;
      font-size: 30px;
        align-items: center;
      font-weight: 500;
      display: flex;
  }
  .faculty-details-box{

  
    background-color: white;
    width: 100%;
    position: relative;
    min-height: 200px;

  }
  .faculty-member-name1{
    color: black;
    font-size: 21px;
    text-transform: capitalize;
    text-align: left;
    margin-bottom: 2px;
  }
  .faculty-member-position1{
    color: black !important;
    font-size: 15.29373px;
    text-align: left;
    text-transform: capitalize;
    font-style: italic;
    font-weight: 300;
  }
  .faculty-member-quote1{
    color: black;
    font-weight: 300;
    font-size: 16px;
  }
  .faculty-read-more1{
    color: rgba(69, 69, 69, 255);
    padding: 8px 21px;
    background-color: white;
    border-radius: 6px;
    margin-top: 12px;
    cursor: pointer;
    -webkit-box-shadow: 7px 6px 18px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 7px 6px 18px 0px rgba(0,0,0,0.4);
    box-shadow: 7px 6px 18px 0px rgba(0,0,0,0.4);
    border:0px;
  }
  .faculty-image1{
    width:40%;
    height:auto;
    margin:20px;
    margin-left: 35%;
    border:2px solid #dee2e6;
  }

  .slick-arrow{
    opacity: 0;
  }

  
.direction-icons-about-us{
  background-color: #7f7f7f;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: center;
  right: 0px;
  padding: none;
  width: 30px !important;
  height: 80px !important;
  border: 0px;
  margin: 0;
  position: absolute;
  bottom: 100px;




}





.slide-next-about-us{
  background-image:url(../../Assets/images/next.png);
  right: -10px;
  background-size: 15px


 
  
}

.slide-prev-about-us{
  background-image: url(../../Assets/images/previous.png);
  left:-10px;
  background-size: 15px

}
.about-objective{
padding-top: 25px;
text-align: center;
}

.about-vision{

  padding-top: 25px;
  text-align: center;

      background-color: #f2fbff;


}
.mission-row{
  padding: 0 20px;
}
.slide-next-about-us {
   right: 0px;
}
.slide-prev-about-us {
  left: 0px;
}
.faculty-content{
  padding: 30px;
  overflow-y: auto;
    height: 200px;
}
.about-mission{

  padding-top: 25px;
  text-align: center;



}

.image-about-us{
  width: 550px;
  height: 300px;
  margin: 20px 40px;

}


.about-us-description-font{

  margin-top: 20px ;
  font-size:16px;
  font-family: Ubuntu-r;
  text-align: justify;

}

.faculty-member-name-about-us{

  font-size:20px;
  font-family: Ubuntu-m;

}
.about-faculty {
  background-color: #f2fbff;
  padding:30px 50px;
}

.faculty-member-position-about-us{

  font-size:16px;
  font-family: Ubuntu-r;

}

.faculty-member-quote-about-us{

  font-size:16px;
  font-family: Ubuntu-r;

}
@media only screen and (max-width: 1024px)   {
  .about-faculty {
    background-color: #f2fbff;
    padding:30px 50px;
}
  .faculty-details-box{
    position: relative;
  }
  .mission-row{
    padding: 0 20px;
  }
  .about-us-description-font{
    font-size: 18px;
  }
  .faculty-member-quote-about-us {
    font-size: 19px;
    font-family: Ubuntu-r;
}
.faculty-member-position-about-us {
  font-size: 19px;
  font-family: Ubuntu-r;
}
.image-about-us{
width: 323px;
height: auto;
}
.slick-next{
display: none !important;
}
.slick-prev{
display:none !important;
}
.faculty-member-name-about-us {
font-size: 25px;
font-family: Ubuntu-m;
}
.slide-next-about-us {
right: 0px; 
}
.slide-prev-about-us {
left: 0px;
}
.faculty-image1 {
width: 193px;
height: 163px;
margin: 57px 21px;
}
}

@media only screen and (max-width:768px){
  .about-faculty {
    padding: 30px 50px;
}
  .faculty-member-name-about-us {
    text-align: center;
    font-family: Ubuntu-m;
}
.faculty-member-position-about-us {
  font-size: 19px;
  text-align: center;
}
.faculty-member-quote-about-us {
  font-size: 19px;
  font-family: Ubuntu-r;
  text-align: center;
}
.faculty-image1 {
  width: 498px;
  height: 263px;
  /* margin: 57px 21px; */
}
.direction-icons-about-us{
  display: none;
}
.faculty-content {
  min-width: 100%;
}
}

@media only screen and (max-width: 525px)   {
  .faculty-content{
    padding: 30px;
    padding-top: 0px;
    overflow-y: auto;
      height: 200px;
  }
  .about-faculty {
    background-color: #f2fbff;
    padding: 20px 20px;
}
  .about-main-description {
    height: 155px;
}
  .direction-icons-about-us{
    display: none;
  }
  .faculty-member-quote-about-us {
    font-size: 14px;
    text-align: center;
    font-family: Ubuntu-r;
}
  .faculty-member-position-about-us {
    font-size: 13px;
    text-align: center;
    font-family: Ubuntu-r;
}
  .faculty-member-name-about-us {
    font-size: 21px;
    text-align: center;
    font-family: Ubuntu-m;
}
.faculty-image1 {
  width: 40%;
  height: auto;
  margin: 13px 16px;
  margin-left: 32%;
}
.about-us-heading{
  /* margin-top: 60px; */
}
.about-main-description{
  font-size: 12px;
  padding: 0 20px;
  height: max-content;
  margin-bottom: 60px;
  margin: 10px 0px;
}
.image-about-us{
  width: 323px;
  height: auto;
}
.about-us-description-font{
  padding: 0 20px;
  font-size: 15px;
}
  }

 