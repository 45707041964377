.quiz-subject{
    font-size:24px;
    font-family:Ubuntu-R;
}

.quiz-topic{
    font-family:Ubuntu-L;
    font-size:20px
}

.start-quiz-button{
    padding: 5px 10px!important;
    border-radius: 5px!important;
    background-color: #dbaa34!important;
    color: #fff!important;
    font-size: 14px!important;
    font-family: Ubuntu-R;
    font-weight: 300;
    border: 0;
    text-transform: capitalize!important;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
}


.start-quiz-page .bg-green{
    background-color: #6cf16c;
    border-radius: 5px;
}

.start-quiz-page .bg-red{
    background-color: #ff1212bd;
    border-radius: 5px;
}