.faq-main{
 
}

.downloads-container-main{
  padding-left: 85px;
  padding-right: 85px;
  min-height: 479px;
  background-color: #fff;
}
.downloads-item-main{
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-family: Ubuntu-R;
  text-align: justify;
}


@media only screen and (min-width: 320px){
  .downloads-container-main{
    padding-left: 15px;
    padding-right: 15px;
    min-height: 479px;
    background-color: #fff;
  }

}