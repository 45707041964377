


.badge {
    color: #fff !important;
    border-radius: 100px !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    width: 16px;
    height: 16px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    top: -2px;
    right: 0;
    font-size: 10px !important;
    background-clip: padding-box;
    background-color: #ec5252;
    border-radius: 100px;
    z-index: 1;
    position: absolute;
    border: none;
}
.collapse_m{
  background-color: rgb(41, 128, 169);
    margin: 5px 0px;
    padding: 5px 0px;
}
.rotateIcon_new {
 
  transform: rotate(180deg);
  transition:transform  0.3s ease-in-out;
}
.rotateIcon_new2 {
 
  transform: rotate(0);
  transition:transform  0.3s ease-in-out;
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: flex; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(1,1,1,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.iconPlus{
  font-size: 10px;
}
#homepage .top-icons-container-home{
  display: flex;
  width:100%;
  justify-content: space-between;
  align-items:center;
}

.mobile-header-icons-container{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 110px;
}
.icon-div-home2 .mobile-icon{
  width:30px;
  text-align:center;
}

.mobile-header-icons-container img{
  width:20px;
}
.mobile-cart-icon-container{
  position: relative;
}

.cart-badge{
  position: absolute;
  background-color: red;
  width: 20px;
  height: 20px;
  font-family: Ubuntu-M;
  font-size: 13px;
  top: -6px;
  left: 12px;
  border-radius: 50%;
  text-align: center;
  color: white;
  vertical-align: middle;
}
.header-options-fb{

  background-color: #ffffff;
  border: 1px solid #999999;
  width: 40px;
  height: 40px;
  border-radius: 5px;



}

.header-options-fb:hover {
  background-color: #0093D9;
  border: 1px solid #0093D9;
  color: #fff;
}



@media only screen and (min-width: 320px)   {
    
}

@media only screen and (min-width: 900px)   {

  .display-header{
    display: block;
  }

  .mobile-header{

    display: none;
  }
  .new-header-logo{
    width:90px !important;
}

  
 
}

@media only screen and (min-width: 1100px)   {
  
}




@media only screen and (min-width: 1300px)  and (min-height:525px){   
  .avatar{
    width: 40px;
border: 1px solid #d2d2d2;
border-radius: 50px;
}
 }  





@media only screen and (min-width: 1300px)  and (min-height:668px){       
  .avatar{
    width: 40px;
border: 1px solid #d2d2d2;
border-radius: 50px;
}
  }



/* Large screens ----------- */
@media only screen  and (min-width : 1800px)  {
 
  .avatar{
    width: 40px;
border: 1px solid #d2d2d2;
border-radius: 50px;
}
}

 

@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 640px) 
                   and (orientation: landscape) {


}




@media(min-width:768px){
  #header-mobile-nav button{
    background-color: transparent;
    border: 0px;
    font-size: 18px !important;
    /* justify-content: space-between; */
    display: flex;
    text-align: left;
    color: white;
    align-items: center;
    padding:0px 0px 0px 20px !important;
    cursor: pointer;
    font-family: Ubuntu-L;
    margin-bottom: 15px;
    text-transform: capitalize;
  }

  

}

.header{
  height:80px;
  background-color: white;
  width:100%;
}


.navBarDP{
  background-color: white !important;
}

.avatar{
  width: 40px !important;
border: 1px solid #d2d2d2;
border-radius: 50px;
}
.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }


  #header-mobile-nav{
    height: 100%;
    width: 250px;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: #0093D9;
    overflow-x: hidden;
    padding-top: 20px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
  }
.header-mobile-2{
  width: 75%;
    align-items: center;
    display: flex;
    justify-content: center;
}

  #header-mobile-nav button{
    background-color: transparent;
    border: 0px;
    font-size: 18px;
    /* justify-content: space-between; */
    display: flex;
    text-align: left;
    color: white;
    align-items: center;
    padding:0px 0px 0px 20px !important;
    cursor: pointer;
    font-family: Ubuntu-L;
    margin-bottom: 1rem;
    text-transform: capitalize;
  }

  #header-mobile-nav button:focus{
    height: 50px;
    margin-bottom: 0rem;
  
  }



  #header-mobile-nav .close-mob-menu-button{
    position: absolute;
    top: 18px;
    right: 25px;
    font-size: 36px;

  }

  .close-mob-menu-button img{
    width:20px;
  }


  
.accounts-container{
    background-color: white;
  }
  
  
  .account-navigation-container{
    background-color: #0093d9;
    border-bottom-left-radius: 5px;
  }
  
  
  .user-image-container{
    width: 90px;
    margin: 0 auto;
    height: 90px;
    border-radius: 50%;
    margin-top: 30px;
  }
  .user-image{
  
    width: 86px;
    margin: 0 auto;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .navigation-options-container > div{
    display:flex;
    flex-direction: column;
    padding:40px 0px;
  }
  
  .navigation-options-container button{
    background-color:transparent;
    color:white;
    margin: 2px 0px;
  }

  .dropdown .menu-subitems {
    background-color: white !important;
}
  
  .navigation-options-container .btn .active{
    background-color:#1A9EDE !important;
  }
  
  .navigation-options-container{
    display: flex;
    flex-direction: column;
  }
  
  .navigation-options-container .navigation-button{
    background-color: transparent;
    border: 0px;
    font-size: 16px;
    justify-content: space-between;
    display: flex;
    text-align: left;
    color: white;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    font-family: Ubuntu-L;
  }
  
  .bg-active{
    background-color:#1A9EDE !important;
  }
  
  
  .user-name, .user-email{
    color:white;
  }
  
  .user-name{
    font-family: 'Ubuntu-M';
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 5px;
  }
  
  .user-email{
    font-family:'Ubuntu-LI';
    font-size:12px;
  }
  
  
  .arrow-image{
    float:right;
  }
  
  
  .image-change-button{
  
  }

 .mobile-header{
    position: fixed;
    width: 100%;
    box-shadow: 1px -1px 14px 0px;
    z-index: 99;
    background-color: white;
    top:0;
    height:70px;
 }


 @media(max-width:768px) and (min-width:576px){

  .mobile-header-padding{
    padding: 0 25px;
  }


  .side-bar-mobile-help-loin-top{

    

    margin-top: -25px;
  }
.side-bar-name-email{

 color:white;
  font-size: 20px;
  text-align:center
}


  .mobile-header {
    display:block;


  }
  .display-header{
    display:none
  }
.mobile-sidebar-exams{
  background-color: transparent;
  border: 0px;
  font-size: 16px;
  justify-content: space-between;
  display: flex;
  text-align: left;
  color: white;
  align-items: center;
  padding: 5px 30px;
  cursor: pointer;
  font-family: Ubuntu-L;
  margin: 0;
}


    .new-header-logo{
        width:50px;
    }
    .menu-toggle > img{
        width:30px;
        margin-right:10px;
    }

    .profile-menu-list{

   
      font-size: 18px;
      align-items: center;
      padding-left: 20px !important;
      font-family: Ubuntu-L;
      margin-bottom: 25px;
      line-height: 30px;

  }


    #account-mobile-nav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: #0093D9;
        overflow-x: hidden;
        padding-top: 20px;
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        top: 65px;
    }
    #login-button-mobile{
      width: 80px !important;
      height: 35px;
  
      border-radius: 5px !important;
      background-color: #fff !important;
      border: 1px solid #fff !important;
      text-align: center !important;
      color: #4A4A4A !important;
      font-size: 14px !important;
    }

    #register-button-mobile{
      width: 95px !important;
      height: 35px;
      border-radius: 5px !important;
      background-color: #FF8500 !important;
      border: 1px solid #FF8500 !important;
      text-align: center !important;
      color: #ffffff !important;
      font-size: 14px !important;
    }
    .button-mobile-side-bar{
      display: flex;
      flex-direction: row;
      justify-content:space-evenly;
    }
    .button-mobile-side-bar1{
      display: flex;
      flex-direction: row;
      justify-content:space-evenly;
    }



    
  #header-mobile-nav .close-mob-menu-button{
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 35px;
  }

.MainMenuBar1{
  opacity: 1;
}

 .new-header-logo{
         width:60px;
     }

     .menu-toggle > img {
      width: 40px;
  }

 
.mobile-sidebar-exams {
  background-color: transparent;
  border: 0px;
  font-size: 16px;
  justify-content: space-between;
  display: flex;
  text-align: left;
  color: white;
  align-items: center;
  padding: 5px 30px;
  cursor: pointer;
  font-family: Ubuntu-L;
  margin: 0;
}


 

  .display-header{
    display: none
  }




  #header-mobile-nav button{
    background-color: transparent;
    border: 0px;
    font-size: 18px;
    /* justify-content: space-between; */
    display: flex;
    text-align: left;
    color: white;
    align-items: center;
    padding:0px 0px 0px 20px !important;
    cursor: pointer;
    font-family: Ubuntu-L;
    margin-bottom: 25px;
    text-transform: capitalize;
  }







    


 }


 @media(max-width:576px){

  #header-mobile-nav button{
    background-color: transparent;
    border: 0px;
    font-size: 16px;
    /* justify-content: space-between; */
    display: flex;
    text-align: left;
    color: white;
    align-items: center;
    padding:0px 0px 0px 20px !important;
    cursor: pointer;
    font-family: Ubuntu-L;
    margin-bottom: 25px;
    text-transform: capitalize;
  }


  .mobile-header-logo-div{
margin-left: 10px;
  
  }
  .mobile-header-logo-div2{
    width: 80%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .mobile-header-padding{
    padding: 0 10px;
  }

  .side-bar-mobile-help-loin-top{

    margin-top: -12px;
  }

  .side-bar-name-email{

    color:white;
     font-size: 16px;
     text-align:center
   }

  .mobile-header {
    display:block;


  }
  .display-header{
    display:none
  }
.mobile-sidebar-exams{
  background-color: transparent;
  border: 0px;
  font-size: 16px;
  justify-content: space-between;
  display: flex;
  text-align: left;
  color: white;
  align-items: center;
  padding: 5px 30px;
  cursor: pointer;
  font-family: Ubuntu-L;
  margin: 0;
}


    .new-header-logo{
        width:50px;
    }


    .menu-toggle > img{
        width:30px;
        margin-right:10px;
    }

    .profile-menu-list{

   
      font-size: 16px;
      align-items: center;
      padding-left: 20px !important;
      font-family: Ubuntu-L;
line-height: 30px;
  }


    #account-mobile-nav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: #0093D9;
        overflow-x: hidden;
        padding-top: 20px;
        transition: 0.5s;
        display: flex;
        flex-direction: column;
        top: 65px;
    }
    #login-button-mobile{
      width: 80px !important;
      height: 35px;
  
      border-radius: 5px !important;
      background-color: #fff !important;
      border: 1px solid #fff !important;
      text-align: center !important;
      color: #4A4A4A !important;
      font-size: 14px !important;
      margin-top: 20px;
    }

    #register-button-mobile{
      width: 95px !important;
      height: 35px;
      border-radius: 5px !important;
      background-color: #FF8500 !important;
      border: 1px solid #FF8500 !important;
      text-align: center !important;
      color: #ffffff !important;
      font-size: 14px !important;
      margin-top: 20px;
    }
    .button-mobile-side-bar{
      display: flex;
      flex-direction: row;
      justify-content:space-evenly;
    }

    .button-mobile-side-bar{
      display: flex;
      flex-direction: row;
      justify-content:space-evenly;
    }



    
  #header-mobile-nav .close-mob-menu-button{
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 36px;
  }



 
 
.mobile-sidebar-exams {
  background-color: transparent;
  border: 0px;
  font-size: 16px;
  justify-content: space-between;
  display: flex;
  text-align: left;
  color: white;
  align-items: center;
  padding: 5px 30px;
  cursor: pointer;
  font-family: Ubuntu-L;
  margin: 0;
}



  .display-header{
    display: none
  }


  #header-mobile-nav .close-mob-menu-button{
    position: absolute;
    top: 18px;
    right: 25px;
    font-size: 36px;
  }














}

.search-icon-header{
  position: absolute;
  right: 40px;
    top: 7px;
    cursor:pointer;
    padding-right:24px;
}

.search-input{
  width: 80%;
  background-color: whitesmoke;
  float: right;
  border-radius: 6px !important;
}


.header .row .exam-input-column{
  display:flex;
  justify-content: space-around;
}

.header .row .exam-input-column button{
background-color: transparent;
border:0px;
cursor:pointer;
}
.header .row .exam-input-column #exam-toggle-text{
font-family: Ubuntu-L;
font-size:16px;
color:#333333;
  }

.exam-toggle{
  display: flex;
  align-items: center;
}

  #header-mobile-nav .mobile-menu-items-divider{
    border: 0.3px solid #b7b7b74d;
    /* width: 100%; */
    padding: 0px 10px;
    margin: 3px 10px;
  }


  .header .row .col-3 .menu-toggle{
    background-color: transparent;
    border: 0px;;;
  }

  .header .row .col-3 .menu-toggle:focus{
    background-color: transparent;
    border: 0px !important;
  }


  .header .row .col-7 .header-search {
    width: 80%;
    background-color: whitesmoke;
    float: right;
    border-radius: 6px !important;
  }
  @media only screen and (min-width: 1300px)  and (min-height:668px){
    .email-text-profile-menu{
        font-size: 12px;
    }
    .profile-menu-list{
        background-color: #fff;
        padding-left: 20px;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0px;
        transition: background-color 0.6s;
    }
    .profile-menu-list:hover{
background-color: #8080801f;
padding-left: 20px;
cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        transition: background-color 0.6s;
    }
    hr {
        display: block;
        height: 2px;
        border: 0;
        border-top: 1px solid grey !important;
        margin: 1em 0;
        padding: 0;
        margin: 20px;
    }
    .headerToggleMenu{
        border: none;
        border-radius: 0;
        border-bottom-left-radius: 2px;
        border-top: 1px solid #e8e9eb;
        box-shadow: 0 4px 16px rgba(20,23,28,.25);
        color: #505763;
        font-size: 16px;
        margin: 0;
        min-height: 30vh;
        z-index:99999;
        background-color: white;
        min-width: 250px;
        right: 0%;
        display: flex;
        flex-direction: column;
       
        padding-top: 20px;
        font-weight: 400;
        margin-top: 15px;
    }
    .headerToggleMenu:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
      }
      .headerToggleMenu:before {
        right: 19px;
        border: 11px solid transparent;
        border-bottom-color: #fff;
      }
      .headerToggleMenu:after {
        left: 20px;
        border: 10px solid transparent;
        border-bottom-color: #fff;
      }
   #homepage .top-icons-container-home{
        display: flex;
        width:100%;
        justify-content: space-between;
        align-items:center;
    }
    .icon-div-home{
        width: 250px;
    }
    .avatar{
        width: 40px;
    border: 1px solid #d2d2d2;
    border-radius: 50px;
    }
}

@media only screen and (max-width: 1024px) {

  .display-header{
  padding: 0px 30px }
}