.error-page-margin-top{
    margin-top:unset;
    padding:unset
}


@media(max-width:768px){
    .error-page-margin-top{
        margin-top:80px !important;
        padding:1.5rem
    }
}