#faculty-page .faculty-image{
    width: 170px;
    margin: 0;
    height: auto;
    /* box-shadow: 0px 2px 16px 0px #7b7b7b;
    border: 1px solid #d4b445; */
}

#faculty-page .faculty-member-content{
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
    font-family: Ubuntu-L;
}
.faculty_upper{
    line-height: 26px;
}
.productMainFirstText {
    font-weight: 500;
    font-size: 12px;
    font-family: Ubuntu-r;
    color: #5A5A5A;
    word-spacing: 1;
    margin-left: 50px;
  }
#faculty-page .faculty-member-row{
    border-bottom: 1px solid #d0d4d8;
    padding: 30px 0px;
    letter-spacing: 0.5px;
    line-height: 26px;
}

#faculty-page .faculty-member-row:last-child{
    border:unset
}

#faculty-page .faculty-member-row .content-container{
    padding: 10px 15px;
    /* background-color: #f9f9f9; */
    /* box-shadow: inset 0px 0px 10px 0px #dcdada */
}

#faculty-page .faculty-member-row h3{
    font-family: Ubuntu-B;
    font-size:20px;

}

#faculty-page .faculty-member-row h4{
   font-family: Ubuntu-LI;
    font-size:16px;
}



@media(max-width:768px){
    .content-container{
        margin-top: -50px;
        z-index: -1;
        padding-top: 3em !important;
        padding:inherit
    }
    #faculty-page{
        margin-top:80px
    }
}