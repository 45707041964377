.product-page-course-rating {
  margin-bottom: 10px;
  opacity: 1;
}

.productMain .productMainFirst .productMainFirstText span {
  cursor: pointer;
}

.purchased-test {
  display: block;
}

.Description .productHR {
  height: 1px !important;
}

input[type="checkbox"] {
  color: none !important;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: none;
}
.product-page-course-rating .star-ratings {
  position: unset;
  bottom: unset;
  left: unset;
  margin-right: 10px;
}

.product-page-course-rating > p {
  margin-bottom: 0px;
}

.buyNow:hover {
  background-color: rgb(143, 108, 71);
  border: 1px solid rgb(143, 108, 71);
}

.addCart:hover {
  background-color: #3a3a3a;
  border: 1px solid #bbbbbb;
  color: white;
}

.shareIt:hover {
  background-color: rgb(68, 125, 153);
  border: 1px solid rgb(68, 125, 153);
}

.toast2 {
  position: absolute;
  animation-duration: 1s;
  opacity: 1 !important;
  bottom: 0;
  left: 50%;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

/* body {
  
    -webkit-overflow-scrolling: touch;
  } */

.productMainThird1Button:hover {
  background-color: rgb(68, 125, 153);
  border: 1px solid rgb(68, 125, 153);
}

.productMainThird1Button:active {
  background-color: rgb(68, 125, 153);
  border: 1px solid rgb(68, 125, 153);
  border-radius: 5px;
}

.CollapseInside {
  margin: 0% 5%;
}

*:focus {
  outline: 0 !important;
}

@media only screen and (min-width: 320px) {
  .RequrmentMain .productHR {
    margin-top: 10px;
  }
  .Description .productHR {
    margin-top: 10px;
  }

  .contact-us-container .contact-us-form {
    display: none;
  }
  .contact-us-button-container {
    display: none;
  }
  .star-container {
    display: none;
  }
  .product-page-review-font {
    font-size: 12px;
  }

  .purchased-test {
    display: none;
  }

  input[type="checkbox"] {
    color: none !important;
    width: 15px;
    height: 15px;
  }
  .productCourseContentPic1 {
    height: auto;
    width: 100%;
    justify-content: center;
  }
  .checked {
    color: orange;
  }
  .fa-heart {
    font-size: 18px;
  }
  .StudentsHR {
    border-top: 0.5px solid #e5e5e5 !important;
    width: 100%;
    margin: 3.5% 0% 0% 0%;
    height: 1px;
  }

  .productHR {
    border-top: 0.5px solid #e5e5e5 !important;
    width: 100%;
    margin: 0%;
  }

  .productCourseContentPic2Text {
    color: #4a4a4a;
    font-size: 20px;
    font-weight: bold;
    font-family: Ubuntu-r;
    letter-spacing: 1;
    text-align: center;
  }
  .productCourseContentPic2TextSpan {
    font-weight: 400;
    color: gray;
    font-size: 12px;
    margin-left: 5%;
    font-family: Ubuntu-r;
    letter-spacing: 1;
  }
  .productMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .productMainFirst {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: auto;
    padding: 2% 2% 2% 2%;
  }
  .productMainFirstText {
    font-weight: 500;
    font-size: 12px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
    word-spacing: 1;
  }
  .productMainSecond {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    background-color: #3a3a3a;
    color: #ffffff;
    height: auto;
  }
  .productMainSecondText {
    width: 100%;
    padding: 3% 2%;
  }
  .productMainSecondTextH2 {
    font-weight: bolder;
    font-size: 16px;
    font-family: Ubuntu-b;
  }
  .productMainSecondTextH5 {
    font-weight: 200;
    font-size: 12px;
    word-spacing: 1;
    font-family: Ubuntu-r;
  }
  .productMainSecondTextp {
    font-weight: 200;
    font-size: 10px;
    word-spacing: 1;
    font-family: Ubuntu-r;
  }
  .productMainThird1 {
    background-color: transparent;
    width: 110px;
    float: right;
    text-align: center;
  }
  .productMainThird1Button {
    background-color: #0093d9;
    width: 100%;
    border: 1px solid #0093d9;
    color: #fff;
  }
  .productCourseContentTextHeadingH1 {
    font-weight: 200;
    font-size: 20px;
    word-spacing: 1;
    font-family: Ubuntu-r;
  }
  .productMainSecondPic {
    width: 30%;
    padding: 0.5% 1% 0.5% 1%;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
    display: none;
  }
  .productCourseContent {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .productCourseContentText {
    width: 100%;
    padding: 5%;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
  }
  .productCourseContentPic {
    /* width: auto; */
    padding: 0.2% 0% 3% 0%;
    /* margin: -10% 6% 0.5% 1%; */
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    margin: 2em auto;
  }
  .productCourseContentFullHeading {
    margin: 3% 1% 0.5% 5%;
  }
  .productCourseContentPic2 {
    width: 80%;
  }
  /* .productCourseContent-box-Course {
          min-width: 85%;
        height: 40px;
        background-color: #F9F9F9;
        border: 1px solid #E5E5E5;
        display: flex;
      
        padding-left: 5%;
    } */
  .productCourseContent-box-QuestionBank {
    min-width: 80%;
    height: 40px;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .productCourseContentPic6 {
    width: 80%;
    min-height: 42px;
  }
  .addCart {
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
  }
  .productCourseContentPic7 {
    width: 80%;
    height: 40px;
  }
  .buyNow {
    width: 100%;
    height: 40px;
    background-color: #dbaa34;
    border: 1px solid #dbaa34;
    color: #ffffff;
  }
  .productCourseContentPic8 {
    width: 80%;
    height: 40px;
  }
  .shareIt {
    width: 100%;
    height: 40px;
    background-color: #0093d9;
    border: 1px solid #0093d9;
    color: #ffffff;
  }
  .POffproductCourseContentPic5 {
    font-size: 14px;
    line-height: 0px;
    color: #0093d9;
  }
  .productCourseContentText1 {
    width: 50%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    font-family: Ubuntu-r;
    color: #5a5a5a;
    font-size: 14px;
    padding-left: 5%;
  }
  .productCourseContentText2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    font-family: Ubuntu-r;
    color: #5a5a5a;
    font-size: 14px;
  }
  .productCourseContentText3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    font-family: Ubuntu-r;
    color: #5a5a5a;
    font-size: 14px;
  }
  .productCourseContentTextInner1 {
    width: 100%;
    margin: 5% 2%;
    display: flex;
    flex-direction: row;
  }
  .productCourseContentTextInner2 {
    width: 50%;
    margin: 2% 2% 0.5% 2%;
    display: flex;
    flex-direction: row;
  }
  .productCourseContentFull {
    width: 100%;
    display: flex;
    height: auto;
    justify-content: flex-start;
    padding: 1% 0% 1% 0%;
  }
  .productCourseContentFullHeading {
    width: 90%;
    margin: 2% 5% 2% 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Ubuntu-r;
    align-items: center;
  }
  .productCourseContentFullHeadingp {
    font-size: 12px;
  }
  .productCourseContentFullHeadingH1 {
    font-size: 24px;
    margin-bottom: 0%;
  }
  .CollapseMain {
    width: 100%;
    padding: 0% 5% 0% 5%;
  }
  .CollapseBtn {
    width: 100% !important;
    height: 60px;
    display: flex;
    flex-direction: row;
    background-color: #f4f4f4 !important;
    border: 1px solid #e5e5e5 !important;
    box-shadow: none !important;
    color: #4a4a4a !important;
    margin: 0.5% !important;
    z-index: 0;
    padding: 0% 2% 0% 2%;
  }
  .CollapseBtn:hover:active:focus {
    background-color: #f4f4f4 !important;
    border: 1px solid #e5e5e5 !important;
    width: 100% !important;
  }
  .fa-plus {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .CollapseBtnIntroduction {
    text-transform: unset !important;
    width: 55%;
    display: flex;
    font-family: Ubuntu-r;
    font-weight: 600;
    color: #5a5a5a !important;
    letter-spacing: 1;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    text-align: initial;
    margin-right: 4%;
    font-size: 12px;
  }
  .CollapseBtnLectures {
    width: 35%;
    display: flex;
    font-family: Ubuntu-r;
    font-weight: 600;
    color: #5a5a5a !important;
    letter-spacing: 1;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    margin-right: 4%;
    justify-content: flex-end;
    scroll-behavior: smooth;
  }
  .CollapseBtnTime {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    font-family: Ubuntu-r;
    font-weight: 600;
    color: #5a5a5a !important;
    letter-spacing: 1;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-end;
    scroll-behavior: smooth;
  }
  .RequrmentMain {
    width: 100%;
    padding: 5%;
  }
  .RequrmentMainH2 {
    font-family: Ubuntu-r;
    font-size: 24px;
    margin-bottom: 0%;
    margin-top: 20px;
  }
  .RequrmentList {
    margin-left: -6.5%;
    font-size: 14px;
  }
  .RequrmentList1 {
    margin-top: 10px;
    font-weight: 500;
    color: #5a5a5a !important;
    font-family: Ubuntu-r;
  }
  .RequrmentList2 {
    margin-top: 2.5%;
    font-weight: 500;
    color: #5a5a5a !important;
    font-family: Ubuntu-r;
  }
  .RequrmentList3 {
    margin-top: 2.5%;
    font-weight: 500;
    color: #5a5a5a !important;
    font-family: Ubuntu-r;
  }
  .Description {
    width: 100%;
    padding: 5%;
  }
  .DescriptionH1 {
    font-family: Ubuntu-r;
    font-size: 24px;
    margin-bottom: 0%;
  }
  .DescriptionP {
    font-family: Ubuntu-r;
    font-size: 14px;
    color: #5a5a5a !important;
  }
  .review {
    width: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
  }
  .review1 {
    font-family: Ubuntu-r;
    font-size: 24px;
    color: #5a5a5a !important;
    margin-bottom: 0%;
  }
  .review2 {
    width: 100%;
    padding: 2% 2% 2% 4%;
    background-color: #f4f4f4;
    border: 1px solid #e5e5e5;
    margin-top: 10px;
  }
  .review2H5 {
    font-family: Ubuntu-b;
    font-size: 14px;
    color: #5a5a5a !important;
  }
  .review2H5P {
    font-family: Ubuntu-r;
    font-size: 14px;
    color: #5a5a5a !important;
  }
  .review2P {
    font-family: Ubuntu-r;
    font-size: 12px;
    color: #5a5a5a !important;
  }
  .review2P1 {
    font-family: Ubuntu-r;
    font-size: 14px;
    color: #5a5a5a !important;
  }
  .addYes {
    width: 50px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    font-family: Ubuntu-r;
    font-size: 12px;
    color: #5a5a5a !important;
  }
  .addYes:hover {
    background-color: #abee75;
    border: 1px solid #abee75;
    color: #5a5a5a !important;
  }
  .addNo {
    width: 50px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    font-family: Ubuntu-r;
    font-size: 12px;
    color: #5a5a5a !important;
  }
  .addNo:hover {
    background-color: #f07474;
    border: 1px solid #f07474;
    color: #5a5a5a !important;
  }
  .StudentsAlsobought {
    width: 100%;
    padding: 5%;
  }
  .StudentsAlsoboughtH1 {
    font-family: Ubuntu-r;
    font-size: 24px;
    margin-bottom: 0px;
  }
  .InstructorRating {
    width: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
  }
  .InstructorRating1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 55px;
    margin-bottom: 10%;
  }
  .InstructorRating2 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .InstructorRating1RoundPic {
    width: 20%;
    display: flex;
  }
  .InstructorRating1A {
    width: 20%;
    display: flex;
    flex-direction: row;
    margin: 0% 2% 0% 2%;
  }
  .InstructorRating1B {
    width: 20%;
    display: flex;
    flex-direction: row;
    margin: 0% 2% 0% 2%;
  }
  .InstructorRating1C {
    width: 20%;
    display: flex;
    flex-direction: row;
    margin: 0% 2% 0% 2%;
  }
  .InstructorRating1D {
    width: 20%;
    display: flex;
    flex-direction: row;
    margin: 0% 2% 0% 2%;
  }
  .logoHeight12 {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-left: 2%;
    margin-bottom: 5%;
  }
  .picAndTime {
    width: 25%;
    display: flex;
    flex-direction: column;
  }
  .StudentsAlsoboughtHead {
    width: 35%;
    padding: 0% 2% 1% 2%;
  }
  .StudentsAlsoboughtRate {
    width: 15%;
    padding: 0% 1% 1% 2%;
  }
  .StudentsAlsoboughtUser {
    width: 15%;
    padding: 0% 1% 1% 1%;
    text-align: end;
  }
  .StudentsAlsoboughtCost {
    width: 20%;
    padding: 0% 0.5% 1% 2%;
    text-align: end;
  }
  .StudentsAlsoboughtHeart {
    width: 6%;
    padding: 0% 1% 1% 1%;
  }
  /* .xxx{
    background-color: rgb(255, 255, 255);
    backface-visibility: hidden;
    margin-top: -9%;
    height: 50px;
    width: 100%;
} */
  .StudentsAlsoboughtHeadH3 {
    font-weight: 600;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtHeadP {
    font-weight: 300;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
    margin-bottom: 1%;
  }
  .StudentsAlsoboughtRateText {
    font-weight: 300;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtRateText {
    font-weight: 500;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtCostH4 {
    font-weight: 600;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtUserText {
    font-weight: 500;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtCostP {
    font-weight: 300;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .logoHeight1 {
    height: auto;
  }
  .StudentsAlsoboughtRateTextStar {
    color: orange;
  }
  .StudentsAlsoboughtHeart {
    color: orange;
  }
  .StudentsAlsoboughtHeart:hover {
    color: red;
  }
  .StudentsAlsoboughtHeart:focus {
    color: red;
  }
  .StudentsAlsoboughtHeart:active {
    color: red;
  }
  .InstructorRating2h4 {
    font-weight: 600;
    font-size: 12px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .InstructorRating2P {
    font-weight: 300;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .InstructorRating2P1 {
    font-weight: 300;
    font-size: 12px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
    text-align: justify;
  }
  .InstructorRating1P {
    font-weight: 300;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .InstructorRating1PSpan {
    font-weight: 600;
    font-size: 10px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .vsMobView {
    display: block;
  }
}

@media only screen and (min-width: 900px) {
  .purchased-test {
    display: block;
  }

  .test_name_text {
    margin-left: "0%";
    font-family: "Ubuntu-r";
    font-size: 13px;
  }
  .Description .productHR {
    margin-top: 50px;
  }

  .RequrmentMain .productHR {
    margin-top: 50px;
  }
  .contact-us-container .contact-us-form {
    display: none;
  }
  .contact-us-button-container {
    display: none;
  }

  input[type="checkbox"] {
    color: none !important;
    width: 15px;
    height: 15px;
  }
  .vsMobView {
    display: none;
  }
  .productCourseContentPic1 {
    height: 215px;
    width: 100%;
  }
  .fa-plus {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .fa-heart {
    font-size: 22px;
  }
  .StudentsHR {
    border-top: 0.5px solid #e5e5e5;
    width: 100%;
    /* margin: 4% 0% 4% 0%; */
  }
  .productCourseContentPic2Text {
    color: #4a4a4a;
    font-size: 24px;
    font-weight: bold;
  }
  .productCourseContentPic2TextSpan {
    font-weight: 400;
    color: gray;
    font-size: 12px;
    margin-left: 5%;
  }
  .productMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .productMainFirst {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: auto;
    padding: 0.5% 1% 0.5% 5%;
  }
  .productMainFirstText {
    font-weight: 500;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
    word-spacing: 1;
  }
  .productMainSecond {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #3a3a3a;
    color: #ffffff;
  }
  .productMainSecondText {
    width: 70%;
    padding: 2%;
  }
  .productMainSecondTextH2 {
    font-weight: bolder;
    font-size: 30px;
    font-family: Ubuntu-b;
  }
  .productMainSecondTextH5 {
    font-weight: 200;
    font-size: 20px;
    word-spacing: 1;
    font-family: Ubuntu-r;
  }
  .productMainSecondTextp {
    font-weight: 200;
    font-size: 14px;
    word-spacing: 1;
    font-family: Ubuntu-r;
  }
  .productCourseContentTextHeadingH1 {
    font-weight: 200;
    font-size: 30px;
    word-spacing: 1;
    font-family: Ubuntu-r;
  }
  .productMainSecondPic {
    width: 30%;
    padding: 0.5% 1% 0.5% 1%;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
  }
  .productCourseContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
  }
  .productCourseContentText {
    width: 100%;
    padding: 1% 0% 0% 1%;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    margin: 60px 0% 0% 0%;
  }
  .productCourseContentPic {
    /* width: auto; */
    padding: 0.2% 0% 3% 0%;
    /* margin: -10% 6% 0.5% 1%; */
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    margin: -8em 10px 0px auto;
  }
  .productCourseContentFullHeading {
    margin: 3% 1% 0.5% 5%;
  }
  .productCourseContentPic2 {
    width: 80%;
  }
  /* .productCourseContent-box-Course {
         min-width: 85%;
        height: 40px;
        background-color: #F9F9F9;
        border: 1px solid #E5E5E5;
        display: flex;
   
        padding-left: 5%;
    } */
  .productCourseContent-box-QuestionBank {
    min-width: 85%;
    height: 50px;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    display: flex;

    padding-left: 5%;
  }
  .productCourseContentPic6 {
    width: 80%;
    min-height: 42px;
  }
  .addCart {
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
  }
  .productCourseContentPic7 {
    width: 80%;
    height: 40px;
  }
  .buyNow {
    width: 100%;
    height: 40px;
    background-color: #dbaa34;
    border: 1px solid #dbaa34;
    color: #ffffff;
  }
  .productCourseContentPic8 {
    width: 80%;
    height: 40px;
  }
  .shareIt {
    width: 100%;
    height: 40px;
    background-color: #0093d9;
    border: 1px solid #0093d9;
    color: #ffffff;
  }
  .POffproductCourseContentPic5 {
    font-size: 14px;
    line-height: 0px;
    color: #0093d9;
  }
  .productCourseContentText1 {
    width: 50%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    font-family: Ubuntu-r;
    color: #5a5a5a;
    padding-left: 1%;

    font-size: 16px;
  }
  .productCourseContentText2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .productCourseContentText3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .productCourseContentTextInner1 {
    width: 100%;
    margin: 5% 2%;
    display: flex;
    flex-direction: row;
  }
  .productCourseContentTextInner2 {
    width: 50%;
    margin: 2% 2% 0.5% 2%;
    display: flex;
    flex-direction: row;
  }
  .productCourseContentFull {
    width: 100%;
    display: flex;
    padding-left: 1%;
    padding-top: 0px;
    height: auto;
  }
  .productCourseContentFullHeading {
    width: 100%;

    margin: 50px 1% 0% 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: Ubuntu-r;
  }
  .productCourseContentFullHeadingp {
    font-size: 16px;
  }
  .productCourseContentFullHeadingH1 {
    font-size: 30px;
    margin-bottom: 0%;
  }
  .CollapseMain {
    width: 100%;
    padding: 0% 1% 0% 0%;
    margin: 10px 1% 0% 0%;
  }
  .CollapseBtn {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    background-color: #f4f4f4 !important;
    border: 1px solid #e5e5e5 !important;
    box-shadow: none !important;
    color: #4a4a4a !important;
    margin-top: 0.5% !important;
    padding: 0% 3% 0% 3%;
  }
  .CollapseBtn:hover:active:focus {
    background-color: #f4f4f4 !important;
    border: 1px solid #e5e5e5 !important;
    width: 100% !important;
  }
  .CollapseBtnIcon {
    width: 3%;
    overflow: hidden;
    -webkit-overflow-scrolling: none;
  }
  .CollapseBtnIntroduction {
    text-transform: unset !important;
    width: 80%;
    display: flex;
    font-family: Ubuntu-r;
    font-weight: 600;
    color: #5a5a5a !important;
    letter-spacing: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: none;
  }
  .CollapseBtnLectures {
    width: 30%;
    display: flex;
    font-family: Ubuntu-r;
    font-weight: 600;
    color: #5a5a5a !important;
    letter-spacing: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: none;
    margin-right: 7%;
  }
  .CollapseBtnTime {
    width: 12%;
    display: flex;
    justify-content: flex-end;
    font-family: Ubuntu-r;
    font-weight: 600;
    color: #5a5a5a !important;
    letter-spacing: 1;
    overflow: hidden;
    -webkit-overflow-scrolling: none;
  }
  .RequrmentMain {
    width: 100%;
    padding: 0%;
    margin: 50px 1% 0% 0%;
  }
  .RequrmentMainH2 {
    font-family: Ubuntu-r;
    font-size: 30px;
    padding-left: 1%;
    margin-bottom: 0%;
  }
  .productMainThird1 {
    background-color: transparent;
    margin: 85px -40% 42% 51%;
    transform: rotateZ(90deg);
    width: 110px;
    margin: 110% -40% 42% 51%;
    text-align: center;
    border-radius: 5px;
  }
  .productMainThird1Button {
    color: #fff;
    background-color: #0093d9;
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #0093d9;
  }
  .RequrmentList {
    margin-left: -2.5%;
  }
  .RequrmentList1 {
    margin-top: 10px;
    font-weight: 500;
    color: #5a5a5a !important;
    font-family: Ubuntu-r;
    font-size: 14px;
  }
  .RequrmentList2 {
    margin-top: 2.5%;
    font-weight: 500;
    color: #5a5a5a !important;
    font-family: Ubuntu-r;
  }
  .RequrmentList3 {
    margin-top: 2.5%;
    font-weight: 500;
    color: #5a5a5a !important;
    font-family: Ubuntu-r;
  }
  .Description {
    width: 100%;
    padding: 0%;
    margin: 50px 1% 0% 0%;
  }
  .DescriptionH1 {
    font-family: Ubuntu-r;
    font-size: 30px;
    margin-bottom: 0%;
  }
  .DescriptionP {
    font-family: Ubuntu-r;
    font-size: 16px;
    color: #5a5a5a !important;
  }
  .review {
    width: 65%;
    padding: 0% 1% 1% 0%;
    margin: 50px 1% 1% 5%;
    display: flex;
    flex-direction: column;
  }
  .review1 {
    font-family: Ubuntu-r;
    font-size: 30px;
    color: #5a5a5a !important;
    margin-bottom: 0%;
  }
  .review2 {
    width: 100%;
    padding: 2% 2% 2% 4%;
    background-color: #f4f4f4;
    border: 1px solid #e5e5e5;
    margin-top: 10px;
  }
  .review2H5 {
    font-family: Ubuntu-b;
    font-size: 16px;
    color: #5a5a5a !important;
  }
  .review2H5P {
    font-family: Ubuntu-r;
    font-size: 16px;
    color: #5a5a5a !important;
  }
  .review2P {
    font-family: Ubuntu-r;
    font-size: 14px;
    color: #5a5a5a !important;
  }
  .review2P1 {
    font-family: Ubuntu-r;
    font-size: 18px;
    color: #5a5a5a !important;
  }
  .addYes {
    width: 50px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    font-family: Ubuntu-r;
    font-size: 12px;
    color: #5a5a5a !important;
  }
  .addNo {
    width: 50px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    font-family: Ubuntu-r;
    font-size: 12px;
    color: #5a5a5a !important;
  }
  .StudentsAlsobought {
    width: 100%;
    padding: 0% 1% 1% 0%;
    margin: 50px 1% 0% 0%;
  }
  .InstructorRating {
    width: 65%;
    padding: 0% 1% 1% 1%;
    margin: 0% 1% 1% 5%;
    display: flex;
    flex-direction: row;
  }
  .InstructorRating1 {
    width: 25%;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .InstructorRating2 {
    width: 75%;
    display: flex;
    flex-direction: column;
  }
  /* .InstructorRating1RoundPic{

    width: 100%;
    display: flex;
    flex-direction: row;
 } */
  .InstructorRating1A {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .InstructorRating1B {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .InstructorRating1C {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .InstructorRating1D {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .logoHeight12 {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-left: 2%;
    margin-bottom: 5%;
  }
  .picAndTime {
    width: 25%;
    display: flex;
    flex-direction: column;
  }
  .StudentsAlsoboughtH1 {
    font-family: Ubuntu-r;
    font-size: 30px;
    margin-bottom: 0px;
  }
  .StudentsAlsoboughtHead {
    width: 38%;
    padding: 0% 2% 1% 2%;
  }
  .StudentsAlsoboughtRate {
    width: 8%;
    padding: 0% 1% 1% 2%;
  }
  .StudentsAlsoboughtUser {
    width: 13%;
    padding: 0% 1% 1% 1%;
    text-align: end;
  }
  .StudentsAlsoboughtCost {
    width: 16%;
    padding: 0% 0.5% 1% 2%;
    text-align: end;
  }
  .StudentsAlsoboughtHeart {
    width: 6%;
    padding: 0% 1% 1% 1%;
  }
  /* .xxx{
    background-color: rgb(255, 255, 255);
    backface-visibility: hidden;
    margin-top: -9%;
    height: 50px;
    width: 100%;
} */
  .StudentsAlsoboughtHeadH3 {
    font-weight: 600;
    font-size: 18px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtHeadP {
    font-weight: 300;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
    margin-bottom: 1%;
  }
  .StudentsAlsoboughtRateText {
    font-weight: 300;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtRateText {
    font-weight: 500;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtUserText {
    font-weight: 500;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtCostH4 {
    font-weight: 600;
    font-size: 18px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtCostP {
    font-weight: 300;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .logoHeight1 {
    height: auto;
  }
  .StudentsAlsoboughtRateTextStar {
    color: orange;
  }
  .StudentsAlsoboughtHeart {
    color: orange;
  }
  .StudentsAlsoboughtHeart:hover {
    color: red;
  }
  .StudentsAlsoboughtHeart:focus {
    color: red;
  }
  .StudentsAlsoboughtHeart:active {
    color: red;
  }
  .InstructorRating2h4 {
    font-weight: 600;
    font-size: 20px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .InstructorRating2P {
    font-weight: 300;
    font-size: 16px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .InstructorRating2P1 {
    font-weight: 300;
    font-size: 18px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .InstructorRating1P {
    font-weight: 300;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .InstructorRating1PSpan {
    font-weight: 600;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
}

@media only screen and (min-width: 1100px) {
  .test_name_text {
    margin-left: "0%";
    font-family: "Ubuntu-r";
    font-size: 13px;
  }
  .Description .productHR {
    margin-top: 50px;
  }

  .RequrmentMain .productHR {
    margin-top: 50px;
  }
  .contact-us-container .contact-us-form {
    display: flex;
  }
  .contact-us-button-container {
    position: absolute;
    /* align-items: center; */
    /* justify-content: flex-start; */
    /* height: 50px; */
    top: calc(50% - 20px);
    right: -82px;
    transform: rotate(90deg);
    display: block;
  }
}

@media only screen and (min-width: 1300px) and (min-height: 525px) {
  .test_name_text {
    margin-left: "0%";
    font-family: "Ubuntu-r";
    font-size: 13px;
  }
  .Description .productHR {
    margin-top: 50px;
  }

  .RequrmentMain .productHR {
    margin-top: 50px;
  }
  .contact-us-container .contact-us-form {
    display: flex;
  }
  .contact-us-button-container {
    position: absolute;
    /* align-items: center; */
    /* justify-content: flex-start; */
    /* height: 50px; */
    top: calc(50% - 20px);
    right: -82px;
    transform: rotate(90deg);
    display: block;
  }
  input[type="checkbox"] {
    color: none !important;
    width: 20px;
    height: 20px;
  }
  .vsMobView {
    display: none;
  }
  .fa-heart {
    font-size: 22px;
  }
  .StudentsHR {
    border-top: 0.5px solid #e5e5e5;
    width: 100%;
    /* margin: 4% 0% 4% 0%; */
  }
  .productCourseContentPic2Text {
    color: #4a4a4a;
    font-size: 24px;
    font-weight: bold;
  }
  .productCourseContentPic2TextSpan {
    font-weight: 400;
    color: gray;
    font-size: 15px;
    margin-left: 5%;
  }
  .productMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .productMainFirst {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: auto;
    padding: 0.5% 1% 0.5% 5%;
  }
  .productMainFirstText {
    font-weight: 500;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
    word-spacing: 1;
  }
  .productMainSecond {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #3a3a3a;
    color: #ffffff;
  }
  .productMainSecondText {
    width: 70%;
    padding: 2% 5%;
  }
  .productMainSecondTextH2 {
    font-weight: bolder;
    font-size: 30px;
    font-family: Ubuntu-b;
  }
  .productMainSecondTextH5 {
    font-weight: 100;
    font-size: 20px;
    word-spacing: 1;
    font-family: Ubuntu-r;
  }
  .productMainSecondTextp {
    font-weight: 200;
    font-size: 14px;
    word-spacing: 1;
    font-family: Ubuntu-r;
  }
  .productCourseContentTextHeadingH1 {
    font-weight: 200;
    font-size: 30px;
    word-spacing: 1;
    font-family: Ubuntu-r;
  }
  .productMainSecondPic {
    width: 30%;
    padding: 0.5% 1% 0.5% 1%;
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
  }
  .productCourseContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
  }
  .productCourseContentText {
    width: 100%;
    padding: 1% 0% 0% 1%;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    margin: 60px 0% 0% 0%;
  }
  .productCourseContentPic {
    /* width: auto; */
    padding: 0.2% 0% 3% 0%;
    /* margin: -10% 6% 0.5% 1%; */
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    margin: -8em 10px 0px auto;
  }
  .productCourseContentFullHeading {
    margin: 3% 1% 0.5% 5%;
  }
  .productCourseContentPic2 {
    width: 80%;
  }
  .productCourseContent-box-Course {
    min-width: 85%;
    height: 40px;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    display: flex;

    padding-left: 5%;
  }
  .productCourseContent-box-QuestionBank {
    min-width: 80%;
    height: 40px;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    display: flex;

    padding-left: 5%;
  }
  .productCourseContentPic6 {
    width: 80%;
    min-height: 42px;
  }
  .addCart {
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
  }
  .productCourseContentPic7 {
    width: 80%;
    height: 40px;
  }
  .buyNow {
    width: 100%;
    height: 40px;
    background-color: #dbaa34;
    border: 1px solid #dbaa34;
    color: #ffffff;
  }
  .productCourseContentPic8 {
    width: 80%;
    height: 40px;
  }
  .shareIt {
    width: 100%;
    height: 40px;
    background-color: #0093d9;
    border: 1px solid #0093d9;
    color: #ffffff;
  }
  .POffproductCourseContentPic5 {
    font-size: 14px;
    line-height: 0px;
    color: #0093d9;
  }
  .productCourseContentText1 {
    width: 50%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    font-family: Ubuntu-r;
    color: #5a5a5a;
    padding-left: 1%;
  }
  .productCourseContentText2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .productCourseContentText3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    word-spacing: 1;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .productCourseContentTextInner1 {
    width: 100%;
    margin: 5% 2%;
    display: flex;
    flex-direction: row;
  }
  .productCourseContentTextInner2 {
    width: 50%;
    margin: 2% 2% 0.5% 2%;
    display: flex;
    flex-direction: row;
  }
  .productCourseContentFull {
    width: 100%;
    display: flex;
    padding-left: 1%;
    padding-top: 0px;
    height: auto;
  }
  .productCourseContentFullHeading {
    width: 100%;

    margin: 50px 1% 0% 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: Ubuntu-r;
  }
  .productCourseContentFullHeadingp {
    font-size: 16px;
  }
  .productCourseContentFullHeadingH1 {
    font-size: 30px;
    margin-bottom: 0%;
  }
  .CollapseMain {
    width: 100%;
    padding: 0% 1% 0% 0%;
    margin: 10px 1% 0% 0%;
  }
  .CollapseBtn {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    background-color: #f4f4f4 !important;
    border: 1px solid #e5e5e5 !important;
    box-shadow: none !important;
    color: #4a4a4a !important;
    margin: 0.5% !important;
  }
  .CollapseBtn:hover:active:focus {
    background-color: #f4f4f4 !important;
    border: 1px solid #e5e5e5 !important;
    width: 100% !important;
  }
  /* 
.CollapseBtnIcon{
    width: 3%;
} */
  .CollapseBtnIntroduction {
    text-transform: unset !important;
    width: 80%;
    display: flex;
    font-family: Ubuntu-r;
    font-weight: 600;
    color: #5a5a5a !important;
    letter-spacing: 1;
  }
  .CollapseBtnLectures {
    width: 30%;
    display: flex;
    font-family: Ubuntu-r;
    font-weight: 600;
    color: #5a5a5a !important;
    letter-spacing: 1;
    justify-content: flex-start;
    margin-right: 7%;
  }
  .CollapseBtnTime {
    width: 12%;
    display: flex;
    justify-content: center;
    font-family: Ubuntu-r;
    font-weight: 600;
    color: #5a5a5a !important;
    letter-spacing: 1;
  }
  .RequrmentMain {
    width: 100%;
    padding: 0%;
    margin: 50px 1% 0% 0%;
  }
  .RequrmentMainH2 {
    font-family: Ubuntu-r;
    font-size: 30px;
    padding-left: 1%;
    margin-bottom: 0%;
  }
  .productMainThird1 {
    background-color: transparent;
    margin: 85px -40% 42% 51%;
    transform: rotateZ(90deg);
    width: 110px;
    margin: 80px -40% 42% 51%;
    text-align: center;
    border-radius: 5px;
  }
  .productMainThird1Button {
    color: #fff;
    background-color: #0093d9;
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #0093d9;
  }
  .RequrmentList {
    margin-left: -2.5%;
  }
  .RequrmentList1 {
    margin-top: 10px;
    font-weight: 500;
    color: #5a5a5a !important;
    font-family: Ubuntu-r;
    font-size: 16px;
  }
  .RequrmentList2 {
    margin-top: 2.5%;
    font-weight: 500;
    color: #5a5a5a !important;
    font-family: Ubuntu-r;
  }
  .RequrmentList3 {
    margin-top: 2.5%;
    font-weight: 500;
    color: #5a5a5a !important;
    font-family: Ubuntu-r;
  }
  .Description {
    width: 100%;
    padding: 0%;
    margin: 50px 1% 0% 0%;
  }
  .DescriptionH1 {
    font-family: Ubuntu-r;
    font-size: 30px;
    margin-bottom: 0%;
  }
  .DescriptionP {
    font-family: Ubuntu-r;
    font-size: 16px;
    color: #5a5a5a !important;
  }
  .review {
    width: 65%;
    padding: 0% 1% 1% 0%;
    margin: 50px 1% 1% 5%;
    display: flex;
    flex-direction: column;
  }
  .review1 {
    font-family: Ubuntu-r;
    font-size: 30px;
    color: #5a5a5a !important;
    margin-bottom: 0%;
  }
  .review2 {
    width: 100%;
    padding: 2% 2% 2% 4%;
    background-color: #f4f4f4;
    border: 1px solid #e5e5e5;
    margin-top: 10px;
  }
  .review2H5 {
    font-family: Ubuntu-b;
    font-size: 16px;
    color: #5a5a5a !important;
  }
  .review2H5P {
    font-family: Ubuntu-r;
    font-size: 16px;
    color: #5a5a5a !important;
  }
  .review2P {
    font-family: Ubuntu-r;
    font-size: 14px;
    color: #5a5a5a !important;
  }
  .review2P1 {
    font-family: Ubuntu-r;
    font-size: 18px;
    color: #5a5a5a !important;
  }
  .addYes {
    width: 50px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    font-family: Ubuntu-r;
    font-size: 12px;
    color: #5a5a5a !important;
  }
  .addNo {
    width: 50px;
    height: 22px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    font-family: Ubuntu-r;
    font-size: 12px;
    color: #5a5a5a !important;
  }
  .StudentsAlsobought {
    width: 100%;
    padding: 0% 1% 1% 0%;
    margin: 50px 1% 0% 0%;
  }
  .InstructorRating {
    width: 65%;
    padding: 0% 1% 1% 1%;
    margin: 0% 1% 1% 5%;
    display: flex;
    flex-direction: row;
  }
  .InstructorRating1 {
    width: 25%;
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .InstructorRating2 {
    width: 75%;
    display: flex;
    flex-direction: column;
  }
  /* .InstructorRating1RoundPic{

    width: 100%;
    display: flex;
    flex-direction: row;
 } */
  .InstructorRating1A {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .InstructorRating1B {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .InstructorRating1C {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .InstructorRating1D {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .logoHeight12 {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-left: 2%;
    margin-bottom: 5%;
  }
  .picAndTime {
    width: 25%;
    display: flex;
    flex-direction: column;
  }
  .StudentsAlsoboughtH1 {
    font-family: Ubuntu-r;
    font-size: 30px;
    margin-bottom: 0px;
  }
  .StudentsAlsoboughtHead {
    width: 38%;
    padding: 0% 2% 1% 2%;
  }
  .StudentsAlsoboughtRate {
    width: 8%;
    padding: 0% 1% 1% 2%;
  }
  .StudentsAlsoboughtUser {
    width: 13%;
    padding: 0% 1% 1% 1%;
    text-align: end;
  }
  .StudentsAlsoboughtCost {
    width: 16%;
    padding: 0% 0.5% 1% 2%;
    text-align: end;
  }
  .StudentsAlsoboughtHeart {
    width: 6%;
    padding: 0% 1% 1% 1%;
  }
  /* .xxx{
    background-color: rgb(255, 255, 255);
    backface-visibility: hidden;
    margin-top: -9%;
    height: 50px;
    width: 100%;
} */
  .StudentsAlsoboughtHeadH3 {
    font-weight: 600;
    font-size: 18px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtHeadP {
    font-weight: 300;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
    margin-bottom: 1%;
  }
  .StudentsAlsoboughtRateText {
    font-weight: 300;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtRateText {
    font-weight: 500;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtUserText {
    font-weight: 500;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtCostH4 {
    font-weight: 600;
    font-size: 18px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .StudentsAlsoboughtCostP {
    font-weight: 300;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .logoHeight1 {
    height: auto;
    width: 100%;
  }
  .logoHeight {
  }
  .StudentsAlsoboughtRateTextStar {
    color: orange;
  }
  .StudentsAlsoboughtHeart {
    color: orange;
  }
  .StudentsAlsoboughtHeart:hover {
    color: red;
  }
  .StudentsAlsoboughtHeart:focus {
    color: red;
  }
  .StudentsAlsoboughtHeart:active {
    color: red;
  }
  .InstructorRating2h4 {
    font-weight: 600;
    font-size: 20px;
    font-family: Ubuntu-r;
    color: #4a4a4a;
  }
  .InstructorRating2P {
    font-weight: 300;
    font-size: 16px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .InstructorRating2P1 {
    font-weight: 300;
    font-size: 18px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .InstructorRating1P {
    font-weight: 300;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
  .InstructorRating1PSpan {
    font-weight: 600;
    font-size: 14px;
    font-family: Ubuntu-r;
    color: #5a5a5a;
  }
}

@media only screen and (min-width: 1300px) and (min-height: 668px) {
  .test_name_text {
    margin-left: "0%";
    font-family: "Ubuntu-r";
    font-size: 13px;
  }
  .Description .productHR {
    margin-top: 50px;
  }

  .RequrmentMain .productHR {
    margin-top: 50px;
  }
  .contact-us-container .contact-us-form {
    display: flex;
  }
  .contact-us-button-container {
    position: absolute;
    /* align-items: center; */
    /* justify-content: flex-start; */
    /* height: 50px; */
    top: calc(50% - 20px);
    right: -82px;
    transform: rotate(90deg);
    display: block;
  }
  .productCourseContentPic {
    /* width: auto; */
    padding: 0.2% 0% 3% 0%;
    /* margin: -10% 6% 0.5% 1%; */
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    margin: -8em 10px 0px auto;
  }
  .productCourseContentPic1 {
    height: 215px;
    width: 330px;
  }
}

/* Large screens ----------- */

@media only screen and (min-width: 1800px) {
  .contact-us-container .contact-us-form {
    display: flex;
  }
  .contact-us-button-container {
    position: absolute;
    /* align-items: center; */
    /* justify-content: flex-start; */
    /* height: 50px; */
    top: calc(50% - 20px);
    right: -82px;
    transform: rotate(90deg);
    display: block;
  }
  .productCourseContentPic1 {
    height: 215px;
    width: 330px;
  }
  .productCourseContentPic {
    margin: -8% 6% 0.5% 1%;
  }
  .productMainThird1 {
    background-color: transparent;
    margin: 85px -40% 42% 51%;
    transform: rotateZ(90deg);
    width: 110px;
    margin: 80px -40% 42% 51%;
    text-align: center;
    border-radius: 5px;
  }
  .productCourseContentPic2Text {
    font-size: 27.5px;
  }
  .productCourseContentPic2TextSpan {
    font-size: 21px;
  }
  text {
    font-size: 16px;
  }
  .RequrmentList1 {
    font-size: 18px;
  }
  .DescriptionP {
    font-size: 18px;
  }
  .container {
    padding-left: 0px;
  }
  .Description {
    padding: 0%;
  }
  .DescriptionH1 {
    font-family: Ubuntu-r;
    font-size: 30px;
    margin-left: 31px;
    margin-bottom: 27px;
  }
  .dashboard-banner-text-container .dashboard-banner-text-three {
    font-size: 18px;
    margin-bottom: 0px;
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 640px) and (orientation: landscape) {
}

@media (max-width: 769px) {
  #product-page {
    margin-top: 70px;
  }
}

.already-purchased-text {
  font-size: 14px;
  text-align: center;
  font-style: italic;
}

.container {
  padding-left: 0px !important;
}

.RequrmentMain hr {
  border-top: 0.5px solid #e5e5e5 !important;
  width: 100%;
  margin: 0%;
  height: 2px !important;
}

#myContact {
  width: 425px;
  transition: all 0.3s ease-in-out;
}

#myContact.fadein-contact {
  left: 0;
}
.contact-us-container {
  display: flex;
  left: -402px;
  position: fixed;
  z-index: 99;
  top: 25%;
}

.contact-us-button-container {
  position: absolute;
  /* align-items: center; */
  /* justify-content: flex-start; */
  /* height: 50px; */
  top: calc(50% - 20px);
  right: -82px;
  transform: rotate(90deg);
}

.contact-us-button-container .contact-us-button {
  background-color: #20aeff;
  font-family: Ubuntu-R;
  font-size: 18px;
  color: white;
  border: none;
  box-shadow: unset;
  width: 175px;
}

.contact-us-container .contact-us-button > span {
  -webkit-transform: rotateZ(90deg); /* Safari prior 9.0 */
  transform: rotateZ(90deg);
}

.contact-us-container .contact-us-form {
  flex-direction: column;
  background-color: white;
  border: 5px solid #9a9a9a;
  padding: 20px 50px;
  border-radius: 15px;
}

.contact-us-container .contact-us-form h2 {
  text-align: center;
}

.contact_input_email {
  background-image: none !important;
}

.contact_send_btn {
  border-radius: 5px;
  background-color: #20aeff;
  padding: 5px 10px;
  color: white;
  border: none;
}

.POffproductCourseContentPic5 {
  cursor: pointer;
}
