.test-series-item {
    background-color: #f7f3f3;
    margin: 5px 0px;
    border: 1px solid #dedada;
    border-radius: 5px;
}


.test-pc-or-desktop{
    display:none
}

.test-series .test-detail .start-test-button-testseries{
    display:block
}

@media(min-width:767px) {

    .test-series .test-detail .start-test-button-testseries{
        display:none
    }

    .test-pc-or-desktop{
        display:block
    }
    #test-series-page {
        margin-top: 70px;
    }
    .exam-series-container {
        padding: 0px 85px;
        margin-bottom: unset;
    }
    .TestSeries-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url(/static/media/TestSeries-Banner.99e06fcb.png);
        height: 300px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 128%;

        color: white;
    }
    .TestSeries-heading {
        font-family: Ubuntu-B;
        color: rgb(219, 170, 52);
        font-size: 30px
    }
    .TestSeries-Para {
        font-family: Ubuntu-L;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    }
    .TestSeries-overlay {
        background-color: rgba(0, 149, 218, 0.9);
        z-index: 12;
        position: absolute;
        left: 0px;
        width: 70%;
        height: 300px;
        clip-path: polygon(0 0, 100% 0, 74% 100%, 0% 100%);
    }
    .TestSeries-Banner-Text {
        width: 50%;
        position: absolute;
        left: 30px;
        z-index: 1000;
    }
    .exam-series-container .row .test-name {
        width: 80%;
        text-align: unset;
        font-family: Ubuntu-R;
        margin-bottom: unset
    }
    .exam-series-container .row .test-detail {
        width: 20%
    }
}

@media(min-width:900px) {

    .test-series .test-detail .start-test-button-testseries{
        display:block
    }

    .test-pc-or-desktop{
        display:none
    }

    #test-series-page {
        margin-top: 0px;
    }
    .exam-series-container {
        padding: 0px 85px;
        margin-bottom: unset;
    }
    .TestSeries-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("../../Assets/images/TestSeries-Banner.png");
        height: 300px;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        
        width: 100%;
        display: flex;
        color: white;
        justify-content: center;
    }
    .TestSeries-heading {
        font-family: Ubuntu-B;
        color: rgb(219, 170, 52);
        font-size: 30px
    }
    .TestSeries-Para {
        font-family: Ubuntu-L;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
    }
    .TestSeries-overlay {
        background-color: rgba(0, 149, 218, 0.9);
        z-index: 12;
        position: absolute;
        left: 0px;
        width: 60%;
        height: 300px;
        clip-path: polygon(0 0, 100% 0, 74% 100%, 0% 100%);
    }
    .TestSeries-Banner-Text {
        width: 40%;
        position: absolute;
        left: 70px;
        z-index: 1000;
    }
}

@media(max-width:580px) {


    .test-series-item .test-detail .start-test-button-testseries{
        display:none
    }

    .test-pc-or-desktop{
        display:block
    }

    .TestSeries-overlay {
        display: none;
    }
    .TestSeries-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("../../Assets/images/Inner-Banner.jpg");
        height: 250px;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        display: flex;
        color: white;
        justify-content: center;
    }
    .TestSeries-heading {
        font-family: Ubuntu-r;
        font-size: 25px;
        color: rgb(219, 170, 52);
        font-weight: 600;
    }
    .TestSeries-Para {
        font-size: 14px;
        padding: 10px;
        text-align: center;
    }
    #test-series-page {
        margin-top: 60px;
        min-height: 100vh;
    }
    .exam-series-container {
        padding: 20px;
        margin-bottom: 5rem;
    }
    .exam-series-container .row .test-name {
        width: 100%;
        text-align: center;
        font-family: Ubuntu-B;
        margin-bottom: 1rem
    }
    .exam-series-container .row .test-detail {
        width: 100%
    }
    .TestSeries-Banner-Text {
        text-align: center;
        padding: 10px;
    }
}

@media (min-width: 1800px) {
    .TestSeries-Para {
        font-family: Ubuntu-L;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
    }
    .TestSeries-overlay {
        height: 400px;
    }
    .TestSeries-main {
        height: 400px;
    }
}