.my-courses-title{
    font-family: Ubuntu-M;
    font-size: 25px;
}

.my-courses-subtitle{
    font-family: Ubuntu-LI;
    font-size: 14px;
}
.text-end{
    text-align: end !important;
}
.my-course-name{
    max-width: 42.3333% !important;
}
.course-row > .row{
    align-items: center;
    background-color: #f7f3f3;
    margin: 5px 0px;
    border: 1px solid #dedada;
    border-radius:5px;
}
.course-row > .row .col-2{
    padding: 2px;
}


.course-title{
    margin-top:10px;
    margin-bottom:0px;
    font-family:Ubuntu-M;
    font-size:18px;
}

.course-dates{
    font-family: Ubuntu-LI;
    font-size:14px;
}

.start-learning{
    padding: 5px 20px;
    font-size: 16px;
    font-family: Ubuntu-L;
    background-color: #dbaa34;
    min-width: 140px;
}

@media only screen and (max-width: 1800px)   {
    .course-thumb {
        width: 99px;
    }
}

@media only screen and (max-width: 1024px)   {
    .course-title {
        margin-top: 10px;
        margin-bottom: 0px;
        font-family: Ubuntu-M;
        font-size: 19px;
    }
    .course-row > .row {
        margin: 15px 0px;
        padding: 20px 0;
    }
.course-dates{
    font-size: 11px;
}
.course-thumb{
    width: 88px !important;
    margin-top: -12px;
}
    }

   




@media only screen and (max-width: 768px)   {


    .course-row .render-button-div .btn-test{
    }

    .my-course-name{
        max-width: 42.3333% !important;
    }
    .course-thumb {
        height: 91px !important;
        width: 152px !important;
    }
    .outer-div{
        flex-direction: column;
        padding: 20px 10px;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    .course-row > .row {
        margin-bottom: 20px;
            padding: 20px 0;
    }
    .course-thumb-div{
        display: flex;
        justify-content: center;
    }
    .start-learning {
        min-width: 0px !important;
        margin-top: 0 !important;
    }
    .btn-test{
        width: 150px;
    }
    .render-button-div{
        display: flex;
        justify-content: center;
    }
    .course-title {
        margin-left: 20px;
    }
    .course-dates{
        display: none;
    }
}
@media only screen and (max-width: 525px)   {
    .start-learning{
        padding: 0px 20px;
        font-size: 16px;
        font-family: Ubuntu-L;
        background-color: #dbaa34;
        min-width: 140px;
    }
    .course-thumb {
        height: auto !important;
        width: 230px !important;
    }
    .course-title {
        font-size: 12px;
        margin-left: 0px !important;
        text-align: center;
    }
    .my-courses-container{
        min-height: 77vh;
    }
    .my-course-name{
        max-width: 60% !important;
    }
    
}