
.legend_container{    display: flex;    flex-direction: row;    align-items: center;    justify-content: flex-start;    width: 100%;    padding-left: 0px;    margin-top: 2%;}.single_legend_container{    display: flex;    flex-direction: row;    align-items: center;    justify-content: center;    margin-left: 10px;}.legend_color_div{    width: 30px;    height: 15px;    margin-right: 5px;}.legend_text{    font-size: 13px;}

    @media only screen and (min-width: 320px){
.skip_Next_btnFlex_end{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
}
.quesMainDiv{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 1px;
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #FF8500;
     font-family: 'Ubuntu-M';
}
.testTimerMainDiv{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 3% 0 1%;
    width: 85%;
    margin-left: 105px;
}
.countdown_btn{
        width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
}
.subTimerDiv{
        width: 130px;
}
.testTimer{
    display: flex;
    justify-content: center;
    border: 1px solid;
    font-family: 'Ubuntu-B';
    align-items: center;
    height: 60px;
    /* margin: 3% 10%; */
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #d2d2d2;
    color: white;
    font-size: 19px;
}

.testMainHeadingFont{
    font-family: 'Ubuntu-M';
    font-size: 25px;
    color: #3A3A3A;
}
.btn_clock{
     display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;   
    border-radius: 0px 5px 5px 0px;
    background-color: #4A4A4A;
    width: 60px;
    color: #fff;
}
.quesDivPosition{
    background-color: #F9F9F9;
    border-radius: 5px;
    width: 702px;
    height: 332px;
    border: 1px solid #f0f0f0;
    /* width: 50%; */
}
.quesOuterDiv{
    width: 35%;
    margin: 0 0 0 7px;
}
.quesDiv{
    height: 107px;
    padding: 15px 30px 0;
    background-color: #F0F0F0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
   
}
.questionsFont{
     font-size: 16px;
     font-family: 'Ubuntu-M';
     width: 95%;
     color: #4A4A4A;
}
.optionDiv{
    margin: 3% 0 0 10%;
    font-size: 16px;
    font-family: 'Ubuntu-L';
    position: relative;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Ubuntu-L';
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
 position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
    border: 2px solid #4A4A4A;
    margin-left: -5px;
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #494949;
}
.captionPadding{
    padding: 0 0 0 30px;
    font-size: 16px;
    font-family: 'Ubuntu-LI';
}
.bottomBtnDiv{
    display: flex;
    justify-content: space-between;
    width: 80%;
    /* margin-left: 190px;
    height: 15vh; */
    margin: 20px 0 12px 190px;
}

.backBtnFlexEvenly{
        display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}
.skipBtnMainDiv{
    display: flex;
    justify-content: space-evenly;
    width: 33%;
}
.skipBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #4A4A4A !important;
    border-color:#4A4A4A;;
    width: 140px;
    color: white !important;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px !important;
    font-family: 'Ubuntu-L';
    text-transform: capitalize !important;
}

.saveBtnMargin{
    margin-left: 10px;
}

}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
.skip_Next_btnFlex_end{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
}
.quesMainDiv{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 1px;
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #FF8500;
     font-family: 'Ubuntu-M';
}
.testTimerMainDiv{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 3% 0 1%;
    width: 85%;
    margin-left: 105px;
}
.countdown_btn{
        width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
}
.subTimerDiv{
        width: 130px;
}
.testTimer{
    display: flex;
    justify-content: center;
    border: 1px solid;
    font-family: 'Ubuntu-B';
    align-items: center;
    height: 60px;
    /* margin: 3% 10%; */
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #d2d2d2;
    color: white;
    font-size: 19px;
}

.testMainHeadingFont{
    font-family: 'Ubuntu-M';
    font-size: 25px;
    color: #3A3A3A;
}
.btn_clock{
     display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;   
    border-radius: 0px 5px 5px 0px;
    background-color: #4A4A4A;
    width: 60px;
    color: #fff;
}
.quesDivPosition{
    background-color: #F9F9F9;
    border-radius: 5px;
    width: 702px;
    height: 332px;
    border: 1px solid #f0f0f0;
    /* width: 50%; */
}
.quesOuterDiv{
    width: 35%;
    margin: 0 0 0 7px;
}
.quesDiv{
    height: 107px;
    padding: 15px 30px 0;
    background-color: #F0F0F0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
   
}
.questionsFont{
     font-size: 16px;
     font-family: 'Ubuntu-M';
     width: 95%;
     color: #4A4A4A;
}
.optionDiv{
    margin: 3% 0 0 10%;
    font-size: 16px;
    font-family: 'Ubuntu-L';
    position: relative;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Ubuntu-L';
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
 position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
    border: 2px solid #4A4A4A;
    margin-left: -5px;
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #494949;
}
.captionPadding{
    padding: 0 0 0 30px;
    font-size: 16px;
    font-family: 'Ubuntu-LI';
}
.bottomBtnDiv{
    display: flex;
    justify-content: space-between;
    width: 80%;
    /* margin-left: 190px;
    height: 15vh; */
    margin: 20px 0 12px 190px;
}
.backBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #FF8500;
    width: 140px;
    color: white;
    border-radius: 5px;
    border-color:#FF8500 ;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.backBtnFlexEvenly{
        display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}
.skipBtnMainDiv{
    display: flex;
    justify-content: space-evenly;
    width: 33%;
}
.skipBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #4A4A4A;
    border-color:#4A4A4A;;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #0093D9;
    border-color :#0093D9;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnMargin{
    margin-left: 10px;
}

}

@media only screen and (min-width: 900px){



.question-container-question{
    background-color: #F0F0F0;
    padding: 10px;
    }
.countdown_btn_1 {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin: 6px;
}

/* .question-boxes-container{
    width: 50%;
    padding: 75px 20px;


} */


.evaluation_button_backBtnDiv{
    width: 70%;
    display: flex;
    flex-direction: row;
    padding-left: 5%;

}

.evaluation_buttons_save_skip{


    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


    .skip_Next_btnFlex_end{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
}
   .quesMainDiv{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 1px;
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #FF8500;
     font-family: 'Ubuntu-M';
}
.testTimerMainDiv{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 3% 0 1%;
    width: 85%;
    margin-left: 105px;
}
.countdown_btn{
        width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
}
.subTimerDiv{
        width: 130px;
}
.testTimer{
    display: flex;
    justify-content: center;
    border: 1px solid;
    font-family: 'Ubuntu-B';
    align-items: center;
    height: 60px;
    /* margin: 3% 10%; */
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #d2d2d2;
    color: white;
    font-size: 19px;
}

.testMainHeadingFont{
    font-family: 'Ubuntu-M';
    font-size: 25px;
    color: #3A3A3A;
}
.btn_clock{
     display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;   
    border-radius: 0px 5px 5px 0px;
    background-color: #4A4A4A;
    width: 60px;
    color: #fff;
}
.quesDivPosition{
    background-color: #F9F9F9;
    border-radius: 5px;
    width: 702px;
    height: 332px;
    border: 1px solid #f0f0f0;
    /* width: 50%; */
}
.quesOuterDiv{
    width: 35%;
    margin: 0 0 0 7px;
}
.quesDiv{
    height: 107px;
    padding: 15px 30px 0;
    background-color: #F0F0F0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
   
}
.questionsFont{
     font-size: 16px;
     font-family: 'Ubuntu-M';
     width: 95%;
     color: #4A4A4A;
}
.optionDiv{
    margin: 3% 0 0 10%;
    font-size: 16px;
    font-family: 'Ubuntu-L';
    position: relative;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Ubuntu-L';
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
 position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
    border: 2px solid #4A4A4A;
    margin-left: -5px;
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #494949;
}
.captionPadding{
    padding: 0 0 0 30px;
    font-size: 16px;
    font-family: 'Ubuntu-LI';
}
.bottomBtnDiv{
    display: flex;
    justify-content: space-between;
    width: 80%;
    /* margin-left: 190px;
    height: 15vh; */
    margin: 20px 0 12px 190px;
}
.backBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #FF8500;
    width: 140px;
    color: white;
    border-radius: 5px;
    border-color:#FF8500 ;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.backBtnFlexEvenly{
        display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}
.skipBtnMainDiv{
    display: flex;
    justify-content: space-evenly;
    width: 33%;
}
.skipBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #4A4A4A;
    border-color:#4A4A4A;;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #0093D9;
    border-color :#0093D9;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnMargin{
    margin-left: 10px;
}
 
}

@media only screen and (min-width: 1100px){
    .skip_Next_btnFlex_end{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
}
   .quesMainDiv{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 2px;
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #FF8500;
     font-family: 'Ubuntu-M';
}
.testTimerMainDiv{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 3% 0 1%;
    width: 88%;
    margin-left: 6%;
}
.countdown_btn{
        width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
}
.subTimerDiv{
        width: 130px;
}
.testTimer{
    display: flex;
    justify-content: center;
    border: 1px solid;
    font-family: 'Ubuntu-B';
    align-items: center;
    height: 60px;
    /* margin: 3% 10%; */
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #d2d2d2;
    color: white;
    font-size: 19px;
}

.testMainHeadingFont{
    font-family: 'Ubuntu-M';
    font-size: 25px;
    color: #3A3A3A;
}
.btn_clock{
     display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;   
    border-radius: 0px 5px 5px 0px;
    background-color: #4A4A4A;
    width: 60px;
    color: #fff;
}
.quesDivPosition{
    background-color: #F9F9F9;
    border-radius: 5px;
    width: 48%;
    height: 332px;
    border: 1px solid #f0f0f0;
    /* width: 50%; */
}
.quesOuterDiv{
    width: 40%;
    margin: 0 0 0 7px;
}
.quesDiv{
    height: 107px;
    padding: 15px 30px 0;
    background-color: #F0F0F0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
   
}
.questionsFont{
     font-size: 16px;
     font-family: 'Ubuntu-M';
     width: 95%;
     color: #4A4A4A;
}
.optionDiv{
    margin: 3% 0 0 10%;
    font-size: 16px;
    font-family: 'Ubuntu-L';
    position: relative;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Ubuntu-L';
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
 position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
    border: 2px solid #4A4A4A;
    margin-left: -5px;
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #494949;
}
.captionPadding{
    padding: 0 0 0 30px;
    font-size: 16px;
    font-family: 'Ubuntu-LI';
}
.bottomBtnDiv{
    display: flex;
    justify-content: space-between;
    width: 85.5%;
    /* margin-left: 190px;
    height: 15vh; */
    margin: 20px 0 12px 10%;
}
.backBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #FF8500;
    width: 140px;
    color: white;
    border-radius: 5px;
    border-color:#FF8500 ;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.backBtnFlexEvenly{
        display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}
.skipBtnMainDiv{
    display: flex;
    justify-content: space-evenly;
    width: 29.5vw;
}
.skipBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #4A4A4A;
    border-color:#4A4A4A;;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #0093D9;
    border-color :#0093D9;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnMargin{
    margin-left: 10px;
}
 
}

@media only screen and (min-width: 1300px) and (min-height:525px){
    .skip_Next_btnFlex_end{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0 1.5% 0 0;
    
}
   .quesMainDiv{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 1px;
    width: 57px;
    height: 57px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #FF8500;
     font-family: 'Ubuntu-M';
}
.testTimerMainDiv{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 3% 0 1%;
    width: 86.3%;
    margin-left: 90px;
}
.countdown_btn{
        width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
}
.subTimerDiv{
        width: 130px;
}
.testTimer{
    display: flex;
    justify-content: center;
    border: 1px solid;
    font-family: 'Ubuntu-B';
    align-items: center;
    height: 60px;
    /* margin: 3% 10%; */
    border: none;
    border-top-left-radius: 5px;
   
    background-color: #d2d2d2;
    color: white;
    font-size: 19px;
}

.testMainHeadingFont{
    font-family: 'Ubuntu-M';
    font-size: 25px;
    color: #3A3A3A;
}
.btn_clock{
     display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;   
    border-radius: 0px 5px 5px 0px;
    background-color: #4A4A4A;
    width: 60px;
    color: #fff;
}
.quesDivPosition{
    background-color: #F9F9F9;
    border-radius: 5px;
    width: 702px;
    height: 332px;
    border: 1px solid #f0f0f0;
    /* width: 50%; */
}
.quesOuterDiv{
    width: 35%;
    margin: 0 0 0 7px;
}
.quesDiv{
    height: 107px;
    padding: 15px 30px 0;
    background-color: #F0F0F0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
   
}
.questionsFont{
     font-size: 16px;
     font-family: 'Ubuntu-M';
     width: 95%;
     color: #4A4A4A;
}
.optionDiv{
    margin: 3% 0 0 10%;
    font-size: 16px;
    font-family: 'Ubuntu-L';
    position: relative;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Ubuntu-L';
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
 position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
    border: 2px solid #4A4A4A;
    margin-left: -5px;
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #494949;
}
.captionPadding{
    padding: 0 0 0 30px;
    font-size: 16px;
    font-family: 'Ubuntu-LI';
}
.bottomBtnDiv{
    display: flex;
    justify-content: space-between;
    width: 80%;
    /* margin-left: 190px;
    height: 15vh; */
    margin: 20px 0 12px 190px;
}
.backBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #FF8500;
    width: 140px;
    color: white;
    border-radius: 5px;
    border-color:#FF8500 ;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.backBtnFlexEvenly{
        display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}
.skipBtnMainDiv{
    display: flex;
    justify-content: space-between;
    width: 25vw;
}
.skipBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #4A4A4A;
    border-color:#4A4A4A;;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #0093D9;
    border-color :#0093D9;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnMargin{
    margin-left: 10px;
}
 
}

@media only screen and (min-width: 1300px) and (min-height:668px){
    .skip_Next_btnFlex_end{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0 .75% 0 0;
}
    .quesMainDiv{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 1px;
    width: 12%;
    height: 60px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #FF8500;
     font-family: 'Ubuntu-M';
}
.testTimerMainDiv{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 3% 0 1%;
    width: 84%;
    margin-left: 7.8%;
}
.countdown_btn{
        width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
}
.subTimerDiv{
        width: 130px;
}
.testTimer{
    display: flex;
    justify-content: center;
    border: 1px solid;
    font-family: 'Ubuntu-B';
    align-items: center;
    height: 60px;
    /* margin: 3% 10%; */
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #d2d2d2;
    color: white;
    font-size: 19px;
}

.testMainHeadingFont{
    font-family: 'Ubuntu-M';
    font-size: 25px;
    color: #3A3A3A;
}
.btn_clock{
     display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;   
    border-radius: 0px 5px 5px 0px;
    background-color: #4A4A4A;
    width: 60px;
    color: #fff;
}
.quesDivPosition{
    background-color: #F9F9F9;
    border-radius: 5px;
    width: 49%;
    height: 332px;
    border: 1px solid #f0f0f0;
    /* width: 50%; */
}
.quesOuterDiv{
    width: 35%;
    margin: 0 0 0 7px;
}
.quesDiv{
    height: 107px;
    padding: 15px 30px 0;
    background-color: #F0F0F0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
   
}
.questionsFont{
     font-size: 16px;
     font-family: 'Ubuntu-M';
     width: 95%;
     color: #4A4A4A;
}
.optionDiv{
    margin: 3% 0 0 10%;
    font-size: 16px;
    font-family: 'Ubuntu-L';
    position: relative;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Ubuntu-L';
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
 position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
    border: 2px solid #4A4A4A;
    margin-left: -5px;
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #494949;
}
.captionPadding{
    padding: 0 0 0 30px;
    font-size: 16px;
    font-family: 'Ubuntu-LI';
}
.bottomBtnDiv{
    display: flex;
    justify-content: space-between;
    width: 79%;
    /* margin-left: 190px;
    height: 15vh; */
    margin: 20px 0 12px 190px;
}
.backBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #FF8500;
    width: 140px;
    color: white;
    border-radius: 5px;
    border-color:#FF8500 ;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.backBtnFlexEvenly{
        display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}
.skipBtnMainDiv{
    display: flex;
    justify-content: space-between;
    width: 340px;
}
.skipBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #4A4A4A;
    border-color:#4A4A4A;;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #0093D9;
    border-color :#0093D9;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnMargin{
    margin-left: 10px;
}

}

@media only screen and (min-width: 1800px){
    .skip_Next_btnFlex_end{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
}
    .quesMainDiv{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 3px;
    width: 78px;
    font-size: 22px;
    height: 78px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #FF8500;
    font-family: 'Ubuntu-M';
}
.testTimerMainDiv{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 3% 0 1%;
    width: 85%;
    margin-left: 95px;
}
.countdown_btn{
        width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
}
.subTimerDiv{
        width: 200px;
}
.testTimer{
    display: flex;
    justify-content: center;
    border: 1px solid;
    font-family: 'Ubuntu-B';
    align-items: center;
    height: 80px;
    /* margin: 3% 10%; */
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #d2d2d2;
    color: white;
    font-size: 25px;
}

.testMainHeadingFont{
    font-family: 'Ubuntu-M';
    font-size: 32px;
    color: #3A3A3A;
}
.btn_clock{
     display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;   
    border-radius: 0px 5px 5px 0px;
    background-color: #4A4A4A;
    width: 80px;
    color: #fff;
}
.quesDivPosition{
    background-color: #F9F9F9;
    border-radius: 5px;
    width: 50%;
    height: 430px;
    border: 1px solid #f0f0f0;
    /* width: 50%; */
}
.quesOuterDiv{
    width: 40%;
    margin: 0 0 0 7px;
}
.quesDiv{
    height: 120px;
    padding: 15px 30px 0;
    background-color: #F0F0F0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
   
}
.questionsFont{
     font-size: 22px;
     font-family: 'Ubuntu-M';
     width: 95%;
     color: #4A4A4A;
}
.optionDiv{
    margin: 3% 0 0 10%;
    font-size: 22px;
    font-family: 'Ubuntu-L';
    position: relative;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 38px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Ubuntu-L';
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
 position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
    border: 2px solid #4A4A4A;
    margin-left: -5px;
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #494949;
}
.captionPadding{
    padding: 0 0 0 30px;
    font-size: 22px;
    font-family: 'Ubuntu-LI';
}
.bottomBtnDiv{
    display: flex;
    justify-content: space-between;
    width: 80%;
    /* margin-left: 190px;
    height: 15vh; */
    margin: 20px 0 12px 190px;
}
.backBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #FF8500;
    width: 140px;
    color: white;
    border-radius: 5px;
    border-color:#FF8500 ;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.backBtnFlexEvenly{
        display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}
.skipBtnMainDiv{
    display: flex;
    justify-content: space-between;
    width: 24.5vw;
}
.skipBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #4A4A4A;
    border-color:#4A4A4A;;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}
.saveBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #0093D9;
    border-color :#0093D9;
    width: 140px;
    color: white;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
}


.saveBtnMargin{
    margin-left: 10px;
}

}


.review-answer-button{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: rgb(93, 179, 14) !important;
    /* border-color: #d4f5e4 !important; */
    width: 140px;
    color: white !important;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
    text-transform: capitalize !important;
}

.question-container{
    background-color:#f9f9f9;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;

    border: 1px solid transparent;
}



.question-boxes-container{
     display:flex;
     flex-wrap: wrap;
    height: fit-content;
    align-content: flex-start;
}


.question-box{
    height: 53px;
    width: 54px;
    font-size: 15px;
    /* padding: 15px 20px 0; */
    margin-left: 2px;
    margin-bottom: 2px;
    background-color: white;
    text-align: center;
    border-radius: 5px;
    display: flex;
    /* color: grey; */
    font-family: Ubuntu-M;
    color: black;
    justify-content: center;
    border: 3px solid #ff8500;
    align-items: center;
}


.bg-orange-box{
    background-color:#ff8500;
    color: white;
}

.blue-box{
    color:white;
    background-color:#0093D9;
    border:0px solid transparent;
}


.review-box{
    color:black;
    border:3px solid rgb(93, 179, 14);
}


.timer-row{
    justify-content: space-between;
}

.evaluation-heading-container{
    justify-content: flex-start;
}


#test-page-container{
    margin-top:50px;
    padding:0px 110px;
}

.evaluation_buttons_div{
    margin-top:20px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.quit-evaluation-button{
    height: 40px;
    display: flex;
    align-items: center;
    width: 140px;
    color: white !important;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
    text-transform: capitalize !important;
}


.evalutaion-back-button{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #FF8500 !important;
    width: 140px;
    color: white !important;
    border-radius: 5px;
    border-color:#FF8500 ;
    justify-content: center;
    font-size: 16px;
    font-family: 'Ubuntu-L';
    text-transform: capitalize !important;
}


.saveBtnDiv{
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #0093D9 !important;
    border-color :#0093D9 !important;
    width: 150px !important;
    color: white !important;
    border-radius: 5px;
    justify-content: center;
    font-size: 16px !important;
    font-family: 'Ubuntu-L';
    text-transform:capitalize !important;
}


.vsMobView .row .question-container{
    margin-top: 85px;
    padding-left: 5px;
    padding-right: 10px;
}


.vsMobView .row .question-boxes-container{
    height: 250px;
    justify-content: center;
    overflow-y: scroll;
    border: 2px solid #bfbfbf;
    box-shadow: inset -1px -2px 4px 0px;
    padding: 10px;
}


.vsMobView .mobile-test-page-container {
    min-height:95vh
}