#add-subject-page textarea {
    width: 60%;
    transition: all .3s;
    outline: none;
    box-shadow: none;
    border: 1px solid grey;
    border-radius: 4px;
    background-color: transparent;
    box-sizing: border-box;
    background-color: white;
}