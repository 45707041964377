.faq-main{
 
}
.faq-main-question{
  background-color: transparent;
  font-size: 15px;
  margin-bottom: 0px;
  display: block;
  font-weight: bold;
  overflow: hidden;
  color: black;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  margin: 5px 0px;

}
.productMainFirstText {
  font-weight: 500;
  font-size: 12px;
  font-family: Ubuntu-r;
  color: #5A5A5A;
  word-spacing: 1;
  margin-left: 50px;
}
.plus-icon-main{
 
    font-size: 14px;
    color: #0093d9;
    background-color: none;
    width: 40px;
    height: 40px;
    text-align: center;
    top: 8px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}
.faq-main-page-image{
  width: 100%;
  margin-bottom: 2%;
}
.faq-answer{
  background-color: none;
  padding:10px;
  padding-top:0px;
}
.ques-main{
  border-bottom: 1px solid #0093d93d;
  margin-bottom: 5px;
}
@media only screen and (min-width: 320px){
  .faq-container{
    /* min-height: 100vh; */
  }
  .faq-im-con{
    width: 100%;
    flex: 1;
    max-width: 100%;
  }
}
@media only screen and (min-width: 1100px){
  .faq-container{
    min-height: auto;
  }
}
