.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.toast2{
  position: fixed;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 1 !important;
  bottom: 100px;
  left: 0;
  background-color: transparent !important;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.snack{
 
  background-color: #000000de;
  padding: 5px 5px;
  border-radius: 5px;
  text-align: center;
}