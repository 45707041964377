.react-autosuggest__container
.react-autosuggest__container--open
.react-autosuggest__suggestions-container--open{
  z-index:99
}

.react-autosuggest__container {
    position: relative;
    display: flex;
  }
  
  .react-autosuggest__input {
    width: 580px;
    height: 40px;
    padding: 10px 5px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 18px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 9999999;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 580px;
        border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 11111;
    text-align: start;
    height:400px;
    overflow-y: scroll !important;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    color:grey
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
  .react-autosuggest__suggestion-match {
    color: black;
    font-weight: bold;
  }


  @media only screen and (min-width: 320px)   {
    .react-autosuggest__container {
      position: relative;
      display: flex;
    }
    
    .react-autosuggest__input {
      width: 580px;
      height: 32px;
      padding: 10px 5px;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 18px;
      border: 1px solid #aaa;
      border-radius: 4px;
    }
    
    .react-autosuggest__input--focused {
      outline: none;
    }
    
    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 9999999;
    }
    
    .react-autosuggest__suggestions-container {
      display: none;
    }
    
    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      top: 51px;
      width: 300px;
          border: 1px solid #aaa;
      background-color: #fff;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 14px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 11111;
      text-align: start;
      height:165px;
      overflow-y: scroll !important;
    }
    
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    
    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 10px;
      color:grey
    }
    
    .react-autosuggest__suggestion--highlighted {
      background-color: #ddd;
    }
    
    .react-autosuggest__suggestion-match {
      color: black;
      font-weight: bold;
    }
  }
  @media only screen and (min-width: 1300px)   {
     
    .react-autosuggest__input {
      width: 580px;
      height: 40px;
      padding: 10px 5px;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 18px;
      border: 1px solid #aaa;
      border-radius: 4px;
    }
  }


  #homepage .search-options{
    background: white;
    position: absolute;
    line-height: 2.2;
    border-bottom-left-radius: 10px;
    z-index: 12;
    border: 1px solid grey;
    border-top: none;
    padding: 5px 20px;
    border-bottom-right-radius: 10px;
    top: 38px;
    width: 100%;
  }


  .search-options{
    background: white;
    position: absolute;
    line-height: 2.2;
    border-bottom-left-radius: 10px;
    z-index: 10;
    border: 1px solid grey;
    border-top: none;
    padding: 5px 20px;
    border-bottom-right-radius: 10px;
    top: 38px;
    width:100%;
    left:0
  }

#homepage .search-bar{
  border:none
}


.search-bar{
  border:1px solid #cccccc;
  border-radius:5px
}


.search-bar input[type="text"]::placeholder{
    color: black;
    font-weight: bolder;
}


  .search-options ul{
    margin-bottom: 0;
  }

  .search-options ul li {
    border-bottom: 1px solid gainsboro;
    cursor:pointer
  }

  .search-options ul li:last-child {
    border-bottom: none;
  }

  .search-options ul li:first-child {
    border-top: 1px solid gainsboro;
  }