.banner {
  background-image: url("https://www.vimasmiham.com/shape3.png");
  height: 600px;
  background-repeat: no-repeat;
  margin-top: -75px;
  background-size: cover;
  background-position: 0px 20%;
  /* -webkit-clip-path: polygon(44% 20%, 62% 20%, 70% 0, 100% 0, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 0, 39% 0);
    clip-path: polygon(43% 13%, 57% 13%, 59% 0, 100% 0, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 0, 41% 0);
    margin-top: -75px;
    background-size: cover;
    background-position: 100% 60%; */
}
/* .testimonial-container .testimonial-container-container .testimonial-box .slick-slider .slick-arrow{
    display: none !important;
} */

#homepage .faculty-content {
  padding: 30px 90px 30px 30px !important;
}

.new-ul {
  padding: 10px 11px 10px 10px;
}
#homepage .mobile-banner {
  display: none;
}

.our-faculty-title {
  margin-bottom: 35px;
}
.downloads-container .downloads-item:hover {
  color: white;
  text-shadow: 0px 0px 20px #8c8282;
  transform: scale(10, 12);
}

@media (max-width: 1200px) {
  .banner {
    clip-path: polygon(
      0% 0,
      100% 0,
      100% 42%,
      100% 77%,
      100% 100%,
      32% 100%,
      0 100%,
      0 0%,
      20% 10%
    );
    margin-top: 0px;
    background-position: 0px -200px;
  }
}

@media only screen and (min-width: 1300px) and (min-height: 668px) {
}

@media only screen and (min-width: 1280px) and (min-height: 720px) {
}

@media only screen and (min-width: 1300px) and (min-height: 625px) {
  .banner-text-container .col .search-icon {
    position: absolute;
    top: -33px;
    right: 10px;
  }
}

@media (max-width: 768px) {
  #homepage .banner {
    margin-bottom: -378px;
    margin-top: 30px;
  }

  .mobView {
    display: block;
  }

  .windowView {
    display: none;
  }
}

.banner-image img {
  width: 360px;
}

.upcoming-image {
  /* height: 300px !important; */
  width: 280px !important;
  /* overflow-y: scroll; */
  position: absolute !important;
  right: 50px;
  z-index: 10;
}

.upcoming-image-admin {
  /* height: 300px !important; */
  width: 280px !important;
  /* overflow-y: scroll; *
}


.upcoming-image:hover{
    /* overflow-y: scroll; */
}

.upcoming-image img {
  /* 
width: 100%; */
}

.upcoming-exam-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 35px;
  color: #fff;
  font-family: Ubuntu-b;
  font-size: 18px;
  text-align: center;

  background-color: #1fb1eb;
}

.upcoming-exam-heading p {
  margin-bottom: 0;
}

.upcoming-exam-notification {
  overflow-y: scroll;
  height: 195px !important;
  position: relative;
  height: auto;
  color: #4a4a4a;
  font-size: 14px;
  text-align: left;
  padding: 10px;
  /* padding-right: 40px; */
  z-index: 9999;

  background-color: #fff;
  /* box-shadow: 1px 20px 15px 0px #ECECEC */
}
/* .upcoming-exam-notification:hover{
    overflow-y: scroll;
} */

.upcoming-exam-notification li {
  line-height: 1.2;
  font-style: oblique;
  margin-bottom: 10px;
  font-family: Ubuntu-R;
}

.about-us-image {
  width: 80%;
}

.banner-text-container {
  position: absolute;
  text-align: center;
  width: 700px;
  top: 140px;
  margin: 0 auto;
  width: 100%;
  left: 0px;
}

.search-text-container {
  position: absolute;
  text-align: center;
  width: 700px;
  top: 62px;
  margin: 0 auto;
  width: 100%;
  left: 0px;
}

.search-text-container .col .search-icon {
  position: absolute;
  top: -25px;
  right: 10px;
}

#homepage .search-bar {
  width: 575px;
  margin: 0 auto;
  padding: 0px;
  /* opacity:0 */
}
.search-bar > input {
  height: 40px;
  border-radius: 5px;
  border: 0px;
  width: 100%;
  padding: 0px 15px;
}

#homepage .headerToggleMenu {
  right: 75px;
}

.search-bar > input::placeholder {
  color: #cacaca;
  font-family: Ubuntu-L;
}

.banner-first-text {
  font-size: 40px;
  color: white;
  /* font-weight: 300; */
  font-family: Ubuntu-L;
}

.banner-first-text > span {
  font-family: Ubuntu-B;
  color: #0074c5;
}

.banner-second-text {
  color: white;
  font-size: 20px;
  font-family: Ubuntu-L;
}

.banner-second-text span {
  font-size: 20px;
  font-family: Ubuntu-L;
}

.banner-third-text {
  color: white;
  font-family: Ubuntu-L;
  font-size: 20px;
  padding: 0px 25%;
}

@media (max-width: 920px) {
  .banner-text-container {
    left: 0px;
    width: 100%;
  }

  #homepage .search-bar {
    width: 300px;
  }

  .banner-first-text {
    font-size: 30px;
  }
}

.about-us-div {
  margin-top: 75px;
}

.about-us-container {
  background-image: url(../../Assets/images/aboutus.png);
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  position: relative;
  margin-top: -200px;
  background-size: 100%;
  overflow: hidden;
  padding: 20px 55px;
}
.testimonial-container {
  background-image: url(../../Assets/images/WhyUsBG.png);
  background-repeat: no-repeat;
  width: 100%;
  /* max-height: 704px; */
  /* height: 100%; */
  position: relative;
  background-size: 100% 100%;
  overflow: hidden;
  padding: 60px 85px;
  background-origin: border-box;
}

.why-us-image {
  padding-top: 40px;
}

.boxmodals-container {
  margin: 0px 45px 0px;
}

.box-container {
  position: relative;
  height: 235px;
  width: 230px;
  transition: all 0.1s ease-in-out;
  background-color: #fff;
  /* box-shadow: 4px 15px 35px 9px rgba(0, 0, 0, 0.15); */
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
}

.offer-banner-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* z-index: 9; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
}

.box-content {
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0px;
  display: flex;
  flex-direction: column;
  /* padding-right: 35px; */
  justify-items: center;
  align-items: center;
}

.box-text {
  font-size: 20px;
  width: 70%;
  color: #0074c5;
  padding-top: 14px;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: Ubuntu-R;
  font-weight: 600;
}
.box-text1 {
  font-size: 14px;
  width: 70%;
  color: #0074c5;
  line-height: 1.2;
  font-family: Ubuntu-R;
  font-weight: 500;
}

.box-icon {
  margin-top: -5px;
}

@media (min-width: 2200px) {
  .box-text {
    width: 30%;
  }
}

@media (max-width: 1370px) and (min-width: 1200px) {
  .box-text {
    width: 70%;
  }
}

.about-us-title,
.testimonial-title {
  color: rgba(69, 69, 69, 255);
  /* font-weight: 300; */
  font-size: 33px;
  font-family: Ubuntu-L;
}

.about-us-para,
.why-us-para {
  font-size: 15.5px;
  color: #454545;
  font-weight: 300;
  padding-top: 30px;
  line-height: 1.8;
  font-family: Ubuntu-L;
}

.about-us-content{
    overflow-y: scroll;
    height: 25rem;
    position: relative;
}
.about-us-read-more,
.why-us-read-more,
.faqs-read-more {
  padding: 10px 20px !important;
  border-radius: 5px !important;
  margin-top: 25px !important;
  background-color: #dbaa34 !important;
  border: 1px solid #dbaa34 !important;
  color: white !important;
  font-size: 14px !important;
  font-family: Ubuntu-R !important;
  font-weight: 300 !important;
  border: 0px !important;
  cursor: pointer !important;
  text-transform: capitalize !important ;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important; */
}

@media (max-width: 768px) {
  .banner-text-container {
    background-color: rgba(33, 32, 30, 0.3);
    padding-top: 0px;
  }

  .banner-third-text {
    padding: 0px 15%;
  }
}

@media (max-width: 576px) {
  .lighter-vein-quote {
    text-align: justify;
  }
  .banner-text-container .col .search-icon {
    position: absolute;
    top: -35px;
    right: 10px;
  }
  .faculty-view-all {
    display: flex;
    justify-content: center;
  }
  .about-us-read-more,
  .why-us-read-more,
  .faqs-read-more {
    padding: 10px 20px !important;
    border-radius: 5px !important;
    margin-top: 10px !important;
    background-color: #dbaa34 !important;
    border: 1px solid #dbaa34 !important;
    color: white !important;
    font-size: 14px !important;
    font-family: Ubuntu-R !important;
    font-weight: 300 !important;
    border: 0px !important;
    cursor: pointer !important;
    text-transform: capitalize !important;
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important; */
  }

  .about-home-mobile {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .about-us-para,
  .why-us-para {
    font-size: 15.5px;
    color: #454545;
    font-weight: 300;
    padding-top: 0px;
    line-height: 1.8;
    font-family: Ubuntu-L;
    text-align: justify;
  }




  .banner {
    background-position: 0px -120px;
    height: 658px;
  }
  .banner-first-text {
    padding: 0px 5px;
    font-size: 22px;
  }

  .banner-second-text {
    font-size: 18px;
  }

  .banner-second-text span {
    font-size: 16px !important;
  }
  .banner-third-text {
    padding: 0px 20px;
  }
}

.faculty-member-quote p {
  text-align: justify;
}

.testimonial-title,
.why-us-para {
  margin-bottom: 30px;
}

/* 
@media(max-width:768px){
    .testimonial-title, .why-us-para{
            margin-bottom: 10px;
        }
        
} */

.testimonial-container-container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.our-faculty-container {
  padding-left: 85px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 85px;
}

.our-faculty-title,
.insurance-industry-title {
  font-size: 33px;
  color: #333333;
  text-align: left;
  /* font-weight: 300; */
  font-family: Ubuntu-L;
  margin-bottom: 55px;
}

#homepage .faculty-content {
  overflow-y: auto;
  height: 250px;
}

.faculty-box {
  min-height: 280px;
  background-color: #1fb1eb;
  margin-bottom: 20px;
  border-radius: 10px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.faculty-content {
  -ms-overflow-style: none;
}
.faculty-content::-webkit-scrollbar {
  display: none;
}

.faculty-image {
  width: 200px;
  margin: 20px auto 10px;
  height: 200px;
  border: 6px solid white;
}

.down-cursor-image-container {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100vw;
  bottom: 0;
}

.down-cursor-image-container > i {
  animation: bounce 2s infinite;
  color: white;
}

.faculty-member-name-sub-image {
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
}

@keyframes bounce {
  0% {
    transform: translateY(5px);
  }
  25% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.faculty-member-name {
  color: rgba(255, 255, 255, 255);
  text-align: left;
  margin-bottom: 2px;
  font-family: Ubuntu-R;
  font-size: 18px;
}

.testimonial-name {
  font-size: 21px;
  color: #0074c5;
  text-align: center;
  margin-bottom: 2px;
}
.testimonial-image {
  width: 140px;
  margin: 20px auto;
  height: 140px;
  border: 6px solid white;
}

.faculty-member-position {
  font-size: 15.29373px;
  color: rgba(255, 255, 255, 255);
  text-align: justify;
  font-style: italic;
  font-weight: 300;
}

.faculty-member-quote {
  color: white;
  font-weight: 300;
  font-size: 16px;
}

.testimonial-quote {
  color: #4a4a4a;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
}

.upcoming-exam-quote {
  color: #4a4a4a;
  font-weight: 300;
  font-size: 12px;
  text-align: justify;
}

#homepage .faculty-read-more {
  color: rgba(69, 69, 69, 255);
  padding: 8px 21px;
  background-color: white;
  border-radius: 6px;
  margin-top: 12px;
  cursor: pointer;
  -webkit-box-shadow: 7px 6px 18px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 7px 6px 18px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 7px 6px 18px 0px rgba(0, 0, 0, 0.4);
  border: 0px;
}

.our-faculty-container .faculty-box .slick-slider .slick-arrow {
  display: none !important;
}

.testimonial-container
  .testimonial-container-container
  .testimonial-box
  .slick-slider
  .slick-arrow {
  display: none !important;
}

.direction-icons {
  background-color: #167ca4;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: center;
  padding: none;
  width: 36px;
  height: 94px;
  border: 0px;
  margin: 0;
  position: absolute;
  bottom: 90px;
}
.direction-icons-updates {
  background-color: grey;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: center;
  padding: none;
  width: 22px;
  height: 45px;
  border: 0px;
  margin: 7px;
  position: absolute;
  bottom: 140px;
}

.direction-icons-testimonial {
  background-color: transparent;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: center;
  padding: none;
  width: 36px;
  height: 94px;
  border: 0px;
  margin: 0;
  position: absolute;
  bottom: 90px;
}

.slide-next {
  background-image: url(../../Assets/images/next.png);
  right: 0;
  background-size: 15px;
}

.slide-prev {
  background-image: url(../../Assets/images/previous.png);
  left: 0;
  background-size: 15px;
}

.slide-next-testimonial {
  background-image: url(../../Assets/images/Forward.png);
  right: 0;
}

.slide-prev-testimonial {
  background-image: url(../../Assets/images/Backward.png);
  left: 0;
}

@media (max-width: 768px) {
  .our-faculty-container {
    padding-left: 45px;
    padding-right: 90px;
  }
  .faculty-image {
    margin: 50px auto;
  }
  #homepage .faculty-read-more {
    margin-bottom: 15px;
  }
  .faqs-content .accordion-head {
    width: 121%;
  }
  .faq-image {
    margin-right: -20px;
  }
  .faculty-member-name {
    margin-top: 20px;
  }
  #homepage .faculty-content {
    padding: 0px 35px 20px !important;
    margin-top: 10px;
    text-align: justify;
  }

  #homepage .faculty-content p {
    text-align: center;
  }

  .direction-icons {
    bottom: 150px;
  }
}

@media (max-width: 576px) {
  #homepage .faculty-content p {
    text-align: justify;
  }
  .slide-next {
    right: 0;
    background-size: 10px;
  }
  .slide-prev {
    left: 0;
    background-size: 10px;
  }
  .direction-icons {
    background-color: #167ca4;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position: center;
    padding: none;
    width: 17px;
    padding: 10px;
    height: 60px;
    border: 0px;
    margin: 0;
    position: absolute;
    bottom: 240px;
  }
}

.faq-title {
  color: rgba(52, 52, 52, 255);
  /* font-weight: 300; */
  font-size: 35px;
  margin-top: 20px;
  font-family: Ubuntu-L;
  margin-bottom: 0px;
}

.faq-container {
  background-image: url("https://www.vimasmiham.com/faqBG.svg");
  background-size: cover;
  padding-left: 85px;
  padding-right: 85px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.faq-row {
  display: inline;
}
.faq-row::after {
  content: "+";
  display: inline;
}

.faq-icon {
  position: absolute;
}

.active-accordion,
.accordion-head:hover {
  background-color: transparent !important;
  color: inherit;
}

.faqs-content .accordion-head {
  background-color: transparent;
  color: rgba(52, 52, 52, 255);
  cursor: pointer;
  padding: 18px 25% 18px 0px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: all 0.4s;
  font-size: 15px;
  position: relative;
}

.faq-question::after {
  content: "+";
  right: 60px;
  position: absolute;
  font-size: 28px;
  color: white;
  font-weight: 300;
  background-color: #0093d9;
  width: 43px;
  height: 43px;
  text-align: center;
  top: 8px;
  vertical-align: middle;
  transition: content 0.1s ease-in-out;
}

.faqs-content .active-accordion::after {
  content: "-";
  right: 60px;
  position: absolute;
  font-size: 28px;
  color: white;
  font-weight: 300;
  background-color: #0093d9;
  width: 43px;
  height: 43px;
  text-align: center;
  top: 8px;
  vertical-align: middle;
  transition: content 0.1s ease-in-out;
}

.faqs-content .panel-accordion {
  padding: 12px 0px;
  background-color: transparent;
  font-size: 15px;
  display: none;
  overflow: hidden;
  color: rgba(52, 52, 52, 255);
}

.faqs-content .active-accordion + .panel-accordion {
  animation-name: faq-fadein;
  animation-duration: 0.3s;
  animation-name: faq-fadein;
  animation-duration: 0.3s;
  text-align: justify;
  margin-right: 105px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}

@keyframes faq-fadein {
  0% {
    opacity: 0;
    height: 0px;
  }
  60% {
    opacity: 0.5;
    height: 50%;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

.faqs-content {
  padding-top: 0px;
}

@media (max-width: 992px) {
  .faq-question::after {
    right: 30px;
  }
  .faqs-content .active-accordion::after {
    right: 30px;
  }
  .banner-text-container {
    z-index: -1;
  }
}
/* 
@media(max-width:768px){
    .faqs-content{
            padding-top: 60px !important;
    }
} */

@media (max-width: 576px) {
  .faq-title {
    color: rgba(52, 52, 52, 255);
    /* font-weight: 300; */
    font-size: 35px;
    margin-top: 0px;
    font-family: Ubuntu-L;
    text-align: center;
  }
  #homepage .faculty-content {
    padding: 0px 30px !important;
    height: 192px;
  }

  .faq-container {
    padding: 60px 45px;
  }
  .faq-question::after {
    content: "+";
    right: 0px;
    position: absolute;
    font-size: 21px;
    color: white;
    font-weight: 300;
    background-color: #0093d9;
    width: 30px;
    height: 30px;
    text-align: center;
    top: 11px;
    vertical-align: middle;
  }
  .faqs-content .active-accordion::after {
    content: "-";
    right: 0px;
    position: absolute;
    font-size: 21px;
    color: white;
    font-weight: 300;
    background-color: #0093d9;
    width: 30px;
    height: 30px;
    text-align: center;
    top: 11px;
    vertical-align: middle;
  }
}

.insurance-industry-container {
  padding-left: 85px;
  padding-top: 40px;
  padding-bottom: 0px;
  padding-right: 85px;
}

.flippy-cardContainer-wrapper {
  /* position: relative;
    height: 100%;
    width: 94% !important;
    margin: 0 auto; */
}

.insurance-industry-container .slick-slide .card-image img {
  width: 400px;
  height: 320px;
}

.flippy-back,
.flippy-front {
  padding: 0px !important;
}

#homepage .flippy-card .card-title {
  position: absolute;
  z-index: 9;
  bottom: 0;
  width: 100%;
  background-color: #1fb1eb;
  height: 50px;
  color: white;
  padding: 14px;
  font-family: Ubuntu-R;
  font-size: 20px;
  margin-bottom: 0px;
  text-align: center;
  line-height: 1;
}

.insurance-industry-container .flippy-cardContainer {
  height: 100%;
  width: 95%;
  position: relative;
  transition: 0.6s;
  transform: perspective(1000px);
  transform-style: preserve-3d;
  cursor: pointer;
}

.flippy-cardContainer .flippy-front,
.flippy-cardContainer .flippy-back {
  box-shadow: none;
}

.flippy-cardContainer .flippy-back {
  background-color: #1fb1eb;
}

.insurance-industry-container .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: 0px 40px;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.insurance-industry-container .slick-list {
  /* margin: 0px 60px; */
  padding: 30px 0px 60px 0px;
}

.card-icon {
  height: 30%;
  padding: 8px 4px 2px 4px;
}
.card-back-title {
  font-weight: 400;
  font-size: 21px;
  height: 15%;
  padding: 17px 0px;
  font-family: Ubuntu-M;
  line-height: 1;
}

.insurance-industry-container .card-description {
  font-family: Ubuntu-L;
  font-size: 14px;
  padding: 2px 4px;
  height: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.insurance-industry-container .card-read-more {
  color: rgba(69, 69, 69, 255);
  font-size: 13px;
  background-color: white;
  padding: 8px 20px;
  border-radius: 5px;
}

.insurance-industry-title {
  margin-bottom: 20px !important;
}

.insurance-industry-container .slick-prev,
.insurance-industry-container .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 55%;
  display: block;
  width: 25px;
  height: 80px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: #cccccc;
}

.insurance-industry-container .slick-arrow {
  text-indent: -9999px;
}
.webView {
  display: block;
}

.insurance-industry-container .slick-prev {
  left: -25px;
  background-image: url(../../Assets/images/left-arrow.png);
  background-size: 147%;
  background-repeat: no-repeat;
  background-position: center;
}

.insurance-industry-container .slick-next {
  right: -25px !important;
  background-image: url(../../Assets/images/next.png);
  background-size: 84%;
  background-repeat: no-repeat;
  background-position: center;
}

.insurance-industry-container .slick-prev:hover,
.insurance-industry-container .slick-prev:focus,
.insurance-industry-container .slick-next:hover,
.insurance-industry-container .slick-next:focus {
  font-size: 0;
  line-height: 0;
  position: absolute;
  /* top: 50%; */
  display: block;
  width: 25px;
  height: 80px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  /* background: #cccccc;*/
}

.downloads-image {
  width: 80% !important;
}
@media (max-width: 992px) {
  .faq-image {
    max-width: 80%;
    height: auto;
  }
  .insurance-industry-container .slick-list {
    /* margin: 0px 60px; */
    padding: 30px 0px 60px 0px;
  }
  .card-title {
    font-size: 16px;
  }
  .card-back-title {
    font-size: 17px;
    height: 12%;
  }
  #homepage .flippy-card .card-title {
    font-size: 16px;
  }
}

/* @media(max-width:768px){
    .insurance-industry-container{
    padding-left:45px;
    padding-right:45px;
    }
    .insurance-industry-container .slick-slider {
            position: relative;
            display: block;
            box-sizing: border-box;
            margin: 0px 20px;
    }
} */

@media (max-width: 576px) {
  .faqs-content {
    padding-top: 20px;
  }
  .insurance-industry-container .slick-list {
    /* margin: 0px 60px; */
    padding: 30px 0px 60px 0px;
  }
  .insurance-industry-container .slick-slide .card-image img {
    width: 250px;
    height: 300px;
  }
  /* .insurance-industry-container .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    margin: 0px 15px;
} */

  .insurance-industry-container .card-description {
    padding: 2px 60px;
  }
}

.downloads-container {
  padding-left: 85px;
  padding-right: 85px;
  min-height: 479px;
  background-color: #1fb1eb;
}

.downloads-image {
  width: 100% !important;
}

.downloads-item {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-family: Ubuntu-R;
  text-align: justify;
}

.downloads-title {
  font-size: 35px;
  color: rgba(69, 69, 69, 255);
  text-align: left;
  /* font-weight: 300 !important; */
  margin-bottom: 15px;
  padding-top: 25px;
  font-family: Ubuntu-L;
}

#homepage .downloads-container .view-all-button {
  margin-top: 30px;
  font-size: 13px;
  color: rgba(69, 69, 69, 255);
  text-align: justify;
  padding: 10px 30px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: Ubuntu-L;
  text-transform: none;
}

@media (max-width: 992px) {
  .webView {
    display: none;
  }
}
@media (max-width: 576px) {
  #homepage .downloads-container .view-all-button {
    margin-top: 20px;
    font-size: 13px;
    color: rgba(69, 69, 69, 255);
    text-align: justify;
    padding: 10px 30px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: Ubuntu-L;
    text-transform: none;
  }

  .downloads-title {
    font-size: 35px;
    color: rgba(69, 69, 69, 255);
    text-align: left;
    /* font-weight: 300 !important; */
    margin-bottom: 30px;
    margin-top: 80px;
    font-family: Ubuntu-L;
    text-align: center;
  }
  .boxmodals-container .box-container:nth-child(1) .box-icon {
    margin-top: 1px;
    margin-right: 10px;
  }
  .boxmodals-container .box-container:nth-child(1) .box-text {
    padding: 10px 0px 0px;
    margin-right: 9px;
  }
  .boxmodals-container .box-container:nth-child(2) .box-text {
    margin-right: 10px;
  }
  .boxmodals-container .box-container:nth-child(3) .box-icon {
    margin-top: 1px;
    margin-right: 4px;
  }
  .boxmodals-container .box-container:nth-child(3) .box-text {
    margin-top: 1px;
    margin-right: 10px;
  }
  .boxmodals-container .box-container:nth-child(4) .box-icon {
    margin-top: 1px;
    margin-right: 5px;
  }
  .boxmodals-container .box-container:nth-child(4) .box-text {
    margin-right: 15px;
  }

  .faq-container {
    padding: 60px 45px;
  }

  .faq-question::after {
    content: "+";
    right: 0px;
    position: absolute;
    font-size: 21px;
    color: white;
    font-weight: 300;
    background-color: #0093d9;
    width: 30px;
    height: 30px;
    text-align: center;
    top: 11px;
    vertical-align: middle;
  }
  .faqs-content .active-accordion::after {
    content: "-";
    right: 0px;
    position: absolute;
    font-size: 21px;
    color: white;
    font-weight: 300;
    background-color: #0093d9;
    width: 30px;
    height: 30px;
    text-align: center;
    top: 11px;
    vertical-align: middle;
  }

  .insurance-industry-container .slick-slide .card-image img {
    width: 310px;
    height: 300px;
  }
  .insurance-industry-container .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    margin: 0px 0px;
  }

  .insurance-industry-container .card-description {
    padding: 2px 60px;
  }
}

.lighter-vein-container {
  min-height: 500px;
  padding: 0px 85px;
  padding-bottom: 40px;
}

.lighter-vein-content {
  padding: 75px 0px 30px 0px;
}

.lighter-vein-title {
  font-size: 33px;
  color: rgb(99, 96, 96);
  text-align: left;
  /* font-weight: 300; */
  margin-bottom: 60px;
  font-family: Ubuntu-L;
  font-size: 35px;
}

.lighter-vein-author {
  font-size: 20px;
  color: rgb(69, 69, 69);
  text-align: justify;
  margin-bottom: 0px;
  margin-top: 50px;
  font-family: Ubuntu-M;
}
.lighter-vein-position {
  font-size: 16px;
  color: rgba(69, 69, 69, 255);
  text-align: justify;
  /* font-weight: 300; */
  /* font-style: italic; */
  font-family: Ubuntu-LI;
}

/* @media(max-width:768px){
    .lighter-vein-container{

            padding-left: 45px;
            padding-right: 45px;
        
    }
} */

@media (min-width: 1800px) {
  #homepage .banner {
    background-position: 0px 16%;
  }
}

/* @media(max-width:1130px) and (min-width:768px){
   #homepage .menu-header .row .padding-left-80{
    padding-left: 20px !important;
    padding-right: 0px !important;

    }
    
    #homepage .menu-header .row .padding-right-80{
        padding-right:20px !important;

    }



} */

@media (max-width: 992px) {
  #homepage .menu-header .row .padding-left-80 {
    align-self: flex-end;
  }
  #homepage .menu-header .row .padding-right-80 {
    align-self: flex-end;
  }
}

@media (max-width: 1200px) {
  .lighter-vein-quote {
    font-size: 15px;
    color: rgba(69, 69, 69, 255);
    line-height: 1.8;
    text-align: left;
    font-style: normal;
    /* font-weight: 300; */
    margin-bottom: 50px;
    font-family: Ubuntu-L;
  }
  .boxmodals-container {
    margin: -30px -20px 0px;
  }
  .box-text {
    font-size: 15px;
    width: 65%;

    padding-top: 14px;
    line-height: 1.2;
    font-family: Ubuntu-R;
  }
  .box-container {
    background-position: center;
    background-size: 290px;
    background-repeat: no-repeat;
    height: 290px;
    transition: all 0.1s ease-in-out;
  }

  #homepage .menu-header .row .padding-left-80 {
    align-self: flex-end;
    padding-left: 0px !important;
    justify-content: space-around !important;
  }
  #homepage .menu-header .row .padding-right-80 {
    align-self: flex-end;
    padding-right: 0px !important;
    justify-content: space-around !important;
  }

  #homepage .exam-submenu-container {
    width: 100%;
    height: 86%;
    position: fixed;
    background-color: #fafafafc;
    z-index: 9999;
    padding: 20px 30px;
    top: 140px !important;
    /* border-radius: 10px; */
    border: 2px solid #b9b9b9;
    /* margin: 0px 10%; */
    animation-name: examMenuAnimate;
    animation-duration: 0.1s;
  }

  .exam-submenu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;
    height: 500px;
    align-items: flex-start;
    padding: 20px 5%;
    border-radius: 5px;
  }
}

@media (max-width: 992px) {
  .boxmodals-container {
    margin: -60px 0px 0px;
  }
  .box-text {
    font-size: 14px;
    width: 60%;
    color: white;
    padding: 14px 5px 0px;
    padding-top: 14px;
    line-height: 1.2;
    font-family: Ubuntu-R;
  }
  .box-container {
    background-position: center;
    background-size: 290px;
    background-repeat: no-repeat;
    height: 290px;
    transition: all 0.1s ease-in-out;
  }
}

@media (max-width: 576px) {
  .lighter-vein-title {
    font-size: 33px;
    color: rgb(99, 96, 96);
    text-align: left;
    /* font-weight: 300; */
    margin-bottom: 30px;
    font-family: Ubuntu-L;
    font-size: 35px;
  }

  .lighter-vein-author {
    font-size: 20px;
    color: rgb(69, 69, 69);
    text-align: justify;
    margin-bottom: 0px;
    margin-top: 20px;
    font-family: Ubuntu-M;
  }
  .lighter-vein-quote {
    text-align: justify;
    margin-bottom: 20px;
  }
  .box-text {
    font-size: 15px;
    width: 70%;
    color: white;
    padding: 10px 0px 0px 0px;
    /* padding-top: 14px; */
    text-align: center;
    line-height: 1.2;
    font-family: Ubuntu-R;
  }

  .box-container {
    background-size: 250px;
    transition: all 0.1s ease-in-out;
  }
}

@media (max-width: 340px) {
  .box-text {
    font-size: 15px;
    width: 70%;
    color: white;
    padding: 10px 0px 0px 0px;
    /* padding-top: 14px; */
    text-align: center;
    line-height: 1.2;
    font-family: Ubuntu-R;
  }
}

#homepage .insurance-industry-container .flippy-cardContainer {
  margin: 0 auto;
}

@media (max-width: 899px) {
  .insurance-industry-container .slick-prev,
  .insurance-industry-container .slick-next {
    top: 47%;
  }
}

@media (max-width: 992px) {
  .search-text-container .col .search-icon {
    position: absolute;
    top: -25px;
    right: 10px;
  }
  /* .banner{
        display:none;
    } */

  .about-us-container {
    margin-top: 250px;
  }

  /* 
    .mobile-banner-1{
        position: relative;
        height: 100vh;
        overflow: hidden;
        height: 100%;
        z-index: 1;
    } */

  /* .mobile-banner-2{
        position: relative;
    z-index: 3;
    bottom: 520px;
    } */

  /* .mobile-banner-1 img{
      width:100%;
      height:60%;
    }

    .mobile-banner-3{
        position: absolute;
        bottom: 120px;
        width: 100%;
    }

    .banner-girl-image{
        position: absolute;
        top: 10%;
        width: 100%;
        z-index:2;
    }

    .banner-girl-image img{
        width: 40vw;
        margin-left: 80px;
     }
    

     .mobile-banner-text{
         position: absolute;
         bottom:6%;
         width:100%;
         z-index: 4;
         text-align:center;
     }

     .mobile-banner-first-text{
            font-family: Ubuntu-L;
            font-size:26px;
            color: white;
            width:100%;
            line-height:1.4;
     }

     .mobile-banner-first-text span{
        font-family: Ubuntu-B;
    }
     .mobile-banner-second-text{
        font-family: Ubuntu-L;
        font-size:22px;
        color: white;
        width:100%;
        line-height:1.4;
    }

    .mobile-banner-second-text span{
        font-family: Ubuntu-B;
        font-size:17px;
    }
    .mobile-banner-third-text{
        font-family: Ubuntu-L;
        font-size:19px;
        color: white;
        width:100%;
        padding:0px 10%;
    } */
}

@media (min-width: 320px) and (max-width: 767px) {
  /* #homepage .mobile-banner{
        display: block;
        height: 100%;
        position: relative;
    }
    
    .mobile-banner-1{
        position: relative;
        height: 720px;
        overflow: hidden;
    
        z-index: 1;
    }

    .mobile-banner-1 img {
        width: 100%;
        height: 100%;
    }

    .mobile-banner-text{
        position: absolute;
        bottom: 0%;
        width: 100%;
        height: 50%;
        z-index: 4;
        text-align: center;
    }
    .arrow {
        text-align: center;
        margin: 3% 0% 3% 0%;
        width: 100%;
        display: flex;
        justify-content: center;
     
      }
      .scrollor{
        height: 100px !important;
        width: 52px !important;

      }
      
    .mobile-banner-first-text{
        font-family: Ubuntu-L;
    font-size: 20px;
    color: white;
    width: 100%;
    line-height: 1.4;
    }

    .mobile-banner-second-text{
        font-family: Ubuntu-L;
        font-size: 20px;
        color: white;
        width: 100%;
        line-height: 1.4;
    }

    .mobile-banner-third-text{
        font-family: Ubuntu-L;
        font-size: 14px;
        color: white;
        width: 100%;
        padding: 0px 10%;}
 */

  .about-us-container {
    margin-top: 10px;
  }
}

/* //////////////////////////////IPADDDDDDDDDDDDDDDDDDDDDDDDDDD pROOOOOOOOOOOOOOOOOOOOOOOOO/////////////////////////////////////////////////// */
@media only screen and (max-width: 1024px) {
  .hamburger-menu {
    opacity: 0;
  }
  .rowDefault {
    /* margin-top: 40px; */
    margin-right: -29px;
  }
  .rowDefault img {
    width: 30px;
    /* margin-right: 35px; */
  }
  .rowDefault .badge {
    /* margin-top: 25px;
        margin-right: -90px; */
    opacity: 0;
  }
  .banner-image img {
    width: 222px;
  }
  .banner-text-container {
    width: 86%;
    left: 17px;
  }
  .upcoming-image {
    padding-right: 0 !important;
  }
  .search-bar > input {
    width: 81%;
  }
  .banner-text-container .col .search-icon {
    right: 64px;
  }
  .search-text-container .col .search-icon {
    right: 50px;
  }
  .banner-first-text {
    font-size: 33px;
  }
  .banner-second-text {
    font-size: 18px;
  }
  .box-content {
    top: 58px;
  }
  /* .insurance-industry-container {
        padding-left: 17px;
        padding-right: 26px;
    } */
  #homepage .flippy-card .card-title {
    height: 55px;
    padding: 11px;
    font-size: 20px;
  }

  .faqs-content .accordion-head {
    width: 111%;
  }

  .downloads-item {
    font-size: 15px;
  }
  .slick-arrow {
    opacity: 1 !important;
    margin-top: 30px;
  }
  .insurance-industry-container .slick-next {
    top: 48%;
  }
  .insurance-industry-container .slick-prev {
    top: 48%;
  }
}

/* //////////////////////////////IPADDDDDDDDDDDDDDDDDDDDDDDDDDD pROOOOOOOOOOOOOOOOOOOOOOOOO/////////////////////////////////////////////////// */
@media only screen and (max-width: 768px) {
  .upcoming-image {
    width: 280px !important;
    position: absolute !important;
    right: calc(100% / 2 - (140px));
    z-index: 10;
    top: 10px;
  }

  .hamburger-menu {
    opacity: 1;
  }
  .box-text {
    font-size: 15px;
    color: #0074c5;
  }
  .box-container {
    margin-top: 20px;
    transition: all 0.1s ease-in-out;
  }
  .upcoming-image img {
    display: none;
  }

  .upcoming-image .upcoming-exam-heading {
    display: none;
  }

  .upcoming-image .upcoming-exam-notification {
    display: none;
  }

  .banner-image {
    display: none;
  }
  .search-bar > input {
    width: 96%;
  }
  .banner-text-container {
    margin-top: -43px;
    width: 100%;
    left: 4px;
    background-color: none;
  }
  .search-icon {
    margin-right: -40px;
    width: 20px;
  }
  .faqs-content .accordion-head {
    width: 126%;
  }
  /* .faq-image{
    margin-left: 40px;
} */
  .slick-arrow {
    opacity: 1;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 525px) {
  .faculty-member-position {
    font-size: 12.29373px;
    color: rgba(255, 255, 255, 255);
    text-align: left;
    font-style: italic;
    font-weight: 300;
  }

  .our-faculty-title,
  .insurance-industry-title {
    font-size: 33px;
    color: #333333;
    text-align: center;
    /* font-weight: 300; */
    font-family: Ubuntu-L;
    margin-bottom: 20px;
  }
  .testimonial-container,
  .about-us-container,
  .our-faculty-container,
  .faq-container,
  .insurance-industry-container,
  .downloads-container,
  .lighter-vein-container {
    padding: 10px 25px;
  }
  .insurance-industry-container .slick-list {
    /* margin: 0px 60px; */
    padding: 0px 0px 0px 0px;
  }
  #homepage .flippy-card .card-title {
    height: 42px;
    padding: 11px;
    font-size: 17px;
  }
  .about-us-title,
  .testimonial-title {
    text-align: center;
  }
  .box-content {
    width: 100%;
    position: absolute;
    top: 30px;
    left: 0px;
    display: flex;
    flex-direction: column;
    /* padding-right: 35px; */
    justify-items: center;
    align-items: center;
  }
  .direction-icons {
    bottom: 150px;
  }
  .faculty-read-more {
    display: none;
  }
  .faq-answer {
    margin-right: 0 !important;
    padding: 0;
  }
  .insurance-industry-container .slick-next {
    top: 37%;
  }
  .insurance-industry-container .slick-prev {
    top: 37%;
  }
  .lighter-vein-content {
    padding: 0px 0px 0px 0px;
  }
  .downloads-title {
    margin-top: 0px;
    padding-top: 0px;
  }
  .banner-first-text {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .banner-second-text {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .banner-third-text {
    font-size: 11px;
  }
  .banner-text-container {
    background-color: none;
    margin-top: -28px;
  }
  .box-container {
    background-position: center;
    background-size: 290px;
    background-repeat: no-repeat;
    height: 227px;
    width: 312px;
    transition: all 0.1s ease-in-out;
  }
  .boxmodals-container .box-container:nth-child(1) .box-icon {
    margin-top: 0px;
    margin-right: 1px;
  }
  .banner {
    background-position: 0px -209px;
  }

  .about-us-title,
  .testimonial-title {
    font-size: 33px;
    padding-top: 0px;
  }
  .testimonial-image {
    width: 128px;
    height: 122px;
  }

  .direction-icons-testimonial {
    display: none;
  }
  .testimonial-quote {
    font-size: 13px;
  }
  .testimonial-name {
    font-size: 16px;
  }

  .faculty-image {
    margin: 12px auto;
    width: 124px;
    height: 120px;
  }
  .faculty-member-name {
    margin-top: 8px;
  }
  .faculty-member-name {
    margin-top: 8px;
    text-align: center;
  }
  .faculty-member-quote {
    text-align: center;
    font-size: 13px;
  }
  .faculty-box {
    height: 400px;
    padding-top: 10px;
  }
  .direction-icons {
    height: 45px;
    margin: 28px 0;
  }
  .faculty-read-more {
    margin-left: 115px;
  }
  .faq-question::after {
    top: 20px;
  }
  .faqs-content .accordion-head {
    width: 100%;
  }

  .testimonial-container,
  .about-us-container,
  .our-faculty-container,
  .faq-container,
  .insurance-industry-container,
  .downloads-container,
  .lighter-vein-container {
    padding: 25px 25px;
  }
}

@media (max-width: 900px) {
  /* .faculty-member-name{
        text-align:center;
    }

    .faculty-member-quote{
        text-align:center
    }

    .faculty-read-more{
        margin:0 auto
    } */
}

#homepage .box-container:hover {
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
}
