

 .footer-company-column-details a{
    color:inherit
}


#homepage .footer-container ul a {
    color:white
}


.footer-container ul a {
    color:#666666;
}



#homepage .windowView .footer-container{
    padding:0px 85px;
    background-image: url(../../Assets/images/fb1.svg), url(../../Assets/images/fb2.svg),url(../../Assets/images/fb3.svg) !important;
    background-repeat: no-repeat;
    background-size:cover;
    padding-top:150px;
    margin-top:-40px;
    position: relative;
    padding-bottom:20px;
    background-color:transparent;
}


#homepage .footer-bottom {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #09c!important;
}

#homepage .footer-company-column-details a{
    color:white
}

.footer-company-column-details a{
   color:inherit
}


#homepage .footer-container ul a {
   color:white
}


.footer-container ul a {
   color:#666666;
}


#homepage .footer-container .footer-info-text{
    font-family:Ubuntu-L;
    font-size:16px;
    color: white;
}

#homepage .footer-container h3 {
    font-family: Ubuntu-B;
    font-size:18px;
    color: white;
}

#homepage .footer-container hr{
    border-color: #f9f9f9ab !important;
    margin-left:0px !important
}
#homepage .footer-container ul {
    list-style:none;
    padding-left:0px;
    color:white;
}


#homepage .footer-container ul li{
    font-family:Ubuntu-L;
    font-size:16px;
    line-height:2.2
}

#homepage .footer-container ul .footer-address-text{
    font-family: Ubuntu-L;
    font-size: 16px;
    line-height: 1.5;
}

#homepage .footer-bottom {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0099cc !important;
}

#homepage  .footer-bottom p{
    color:white;
    text-align: center;
    font-family: Ubuntu-L;
    font-size: 16px;;
}

#homepage .footer-bottom p span{
    color:#ffcc33;
}


#homepage .footer-container ul .home-footer-email-submit p{
    padding-top:15px;
    font-family: Ubuntu-L;
    font-size: 20px;

}

#homepage .footer-container input {
    padding: 0px 7px;
    background-color: transparent;
    border: none;
    width: 100%;
    border-right: 1px solid white;
}

#homepage .footer-container input::placeholder{
    color:white;
    opacity:0.6
}

#homepage .footer-container .home-footer-submit-button {
    color: white;
    background-color: #dbaa34;
    border: 1px solid #dbaa34;
    border: none;
    text-align: center;
    margin: 0 auto;
    font-family: Ubuntu-R;
    border-radius: 0px 4px 4px 0px;

}

#homepage .footer-container .footer-submit-column{
    display:flex;
    align-items: center;
    border:1px solid white;
    border-radius: 5px;;
    width:85%;
}

.footer-container{
    padding:50px 85px 0px;
    background-color:#f4f4f4
}

.footer-container .footer-info-text {
    font-family: Ubuntu-L;
    font-size: 16px;
    line-height: 1.5;
    color: #666666;
}

 .footer-container h3 {
    font-family: Ubuntu-B;
    font-size: 18px;
    color: #666666;
}

 .footer-container hr{
    border-color: #b4b4b4 !important;
    margin-left: 0px !important;
}
 .footer-container ul {
    list-style: none;
    padding-left: 0px;
    color: #666666;
}


.footer-container ul li {
    font-family: Ubuntu-L;
    font-size: 16px;
    line-height: 2.2;
    cursor: pointer;
}

 .footer-container ul .footer-address-text{
    font-family: Ubuntu-L;
    font-size: 16px;
    line-height: 1.5;
}

 .footer-bottom {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e5e5e5 !important;
}

  .footer-bottom p{
    text-align: center;
    font-family: Ubuntu-L;
    font-size: 16px;
    color: #666666;
}

 .footer-bottom p span{
    color: #666666;
 }


 .footer-container ul .home-footer-email-submit p{
    padding-top:15px;
    font-family: Ubuntu-L;
    font-size: 20px;

}

 .footer-container input {
    padding: 0px 7px;
    background-color: transparent;
    border: none;
    width: 75%;
    border-right: 1px solid #666666;
}

 .footer-container input::placeholder{
    color:#666666;
    opacity:0.6
}

 .footer-container .home-footer-submit-button {
    color: #666666;
    background-color: transparent;
    border: none;
    text-align: center;
    margin: 0 auto;
}

 .footer-container .footer-submit-column{
    display: flex;
    align-items: center;
    border: 1px solid #666666;
    border-radius: 5px;
    width: 85%;
}


.mobile-footer-contact-us-form input{
    all: unset !important;
    padding-left: 3em !important;
    padding-top: 0.5em !important;
}


.mobile-footer-contact-us-form i{
    top: 0.5rem !important;
    font-size: 1.50rem !important;
}

@media(max-width:576px){
     .footer-container{ 
        padding: 0px 45px;
        background-position: center;
    }
     .footer-top{
        padding-top:200px;
    }
}





@media(max-width:576px){
    #homepage .footer-container{ 
        padding: 0px 45px;
    }
    #homepage .footer-top{
        padding-top:200px;
    }
}

@media only screen and (min-width: 320px)   {
    .footer-icon-div{
        height: 45px;
        align-items: center;
    }

    .contact-us-sticky-footer-button button{
        background-color:#1fb1eb !important;
        color:white !important;
         border:2px solid #1fb1eb !important;
         padding: 5px 15px;
         margin-top: 5%;
    
    }

    .x1{
        background-color:#1fb1eb !important;
        color:white !important;
         border:2px solid #1fb1eb !important;
         padding: 5px 15px;
         margin-top: 5%;

    }
    
    
    .mobile-footer-contact-us-form .fa-pencil-alt{
        transform: rotate(-90deg);
    }

    .mobile-footer-contact-us-form .md-form .prefix ~ input, .md-form .prefix ~ textarea {
   
        width: 250px !important;
    }
    
    
    .modal-title{
        width: 100%;
    }

    .Sticky-Footer-REcaptcha{

        overflow-y: hidden;
    }


}
@media only screen and (min-width: 900px)   {
 
}
@media only screen and (min-width: 1100px)   {
  
}


/* Large screens ----------- */
@media only screen  and (min-width : 1800px)  {
 
}

 

@media only screen and (min-width: 480px) 
                   and (max-width: 640px) 
                   and (orientation: landscape) {


}


 