
#subject-list-page{
    width:100%;
    overflow:hidden;
}

#subject-list-page .subject-list-column{
    width:100%;
}

#subject-list-page .pages_table tbody tr td:first-child{
    display: flex;
    flex-direction: column;
    align-items: start;
}

#subject-list-page .pages_table tbody tr td:first-child button{
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-top:30px;
    color:blue
}
.sub_Div{
    padding: 10px;
    width: 80vw;
    margin: 2% 4%;
    background-color: #FAFAFA;
    cursor: grabbing;
    box-shadow: #E5E5E5 0px 0px 0px 1px;
    border: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: unset;
}
.collapse_head{
    margin: 10px 10px;
    width: 98%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    background-color: #0085ba !important;
    font-size: 18px;
    color: white !important;
    font-weight: 500;
    border: none !important;
    height: 7vh;
    text-transform: initial;
    border: none !important;
    box-shadow: none !important
}